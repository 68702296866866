import { useEffect } from "react";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/monthlyPGG";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store/index";
import { storedLocale } from "../../impersonation/util";

const loadComponent = async () => {
  return import("../../Lit/acc-coreplus-incentives/acc-personal-growth-monthly.js");
};

export default function MonthlyPGG() {
  const { t } = useTranslation(["monthlyPGG"]);

  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const ele = document.getElementById("acc_pgg_monthly_component") as HTMLElement;
      ele.setAttribute("learn-more-link", configuration.dashboardLinks?.learnMore?.personalGroupGrowth);
    }
  }, [isPending, configuration]);

  const pggTitle = `${t(`pageTitle`, defaultContent["pageTitle"])}`;

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <h1 className="acc_pgg_monthly_header" id="acc_pgg_monthly_header">
        {pggTitle}
      </h1>
      <acc-personal-growth-monthly
        learn-more-link=""
        id="acc_pgg_monthly_component"
        period={selectedPeriod}
        locale={storedLocale()}
        mode="standalone"
      ></acc-personal-growth-monthly>
    </main>
  );
}
