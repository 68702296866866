import { ReactNode } from "react";
import "./LeafIconButton.scss";

interface LeafIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  Icon: ReactNode;
}

export const LeafIconButton = ({ Icon, ...htmlAttributes }: LeafIconButtonProps) => {
  return (
    <button
      {...htmlAttributes}
      className={`LeafIconButton__button ${htmlAttributes.className ? htmlAttributes.className : ""}`}
    >
      {Icon}
    </button>
  );
};
