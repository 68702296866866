import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

export const addNoteClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "add_note",
  );
};

export const editNoteClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "edit_note",
  );
};

export const readMoreNoteClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "read_more_note",
  );
};
