import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/l10n";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import commonContent from "../../../../content/common";
import defaultContent from "../../../../content/ttci";
import "./EligibilityPin.scss";
import { AwardLevels, TtciData } from "../../../../common/interfaces/ttciData";
import LeafTag, { TagColor } from "../../../../common/leaf/LeafTag/LeafTag";
import { TTCI_STATUS } from "../../../../common/enums/ttci-enums";
import { getAwardLabelKeyFromAwardCode } from "../../../../common/util/awardLabels";
import { getDefaultCurrencyCode } from "../../../../common/util/config";

type Props = {
  ttciData: TtciData;
  locale: string;
  country: string;
  isLoading: boolean;
};

export const EligibilityPin = ({ ttciData, locale, country, isLoading }: Props) => {
  const { t } = useTranslation(["ttci", "common"]);
  const { bonusCurrency } = ttciData;
  const _getLeftLeafTag = (pin: AwardLevels) => {
    let contentString = "";
    let colorVariant: TagColor = "blue";
    if (
      pin.status === TTCI_STATUS.TRACKING ||
      pin.status === TTCI_STATUS.ELIGIBLE ||
      pin.status === TTCI_STATUS.PENDING_ELIGIBLE
    ) {
      contentString = "tracking";
      colorVariant = "blue";
    }
    if (pin.achieved) {
      contentString = "achieved";
      colorVariant = "green";
    }
    if (pin.denied) {
      contentString = "denied";
      colorVariant = "red";
    }
    if (pin.held) {
      contentString = "onHold";
      colorVariant = "blue";
    }

    return contentString !== "" ? (
      <LeafTag variant={colorVariant}>{`${t(contentString, defaultContent[contentString])}`}</LeafTag>
    ) : (
      <></>
    );
  };

  const _getRightLeafTag = (pin: AwardLevels) => {
    const contentString = pin.firstTime ? "firstYear" : "requalification";
    return <LeafTag variant={"gray"}>{`${t(contentString, defaultContent[contentString])}`}</LeafTag>;
  };

  return (
    <>
      {ttciData && ttciData.awardLevels ? (
        ttciData.awardLevels.map((pin, index) => {
          return (
            <div key={index} className="EligibilityPin__pinTile">
              <LeafTile isLoading={isLoading}>
                {" "}
                <div className="EligibilityPin__tagsSection">
                  {_getLeftLeafTag(pin)}
                  {_getRightLeafTag(pin)}
                </div>
                <div className="EligibilityPin__pinLabel">
                  {`${t(
                    `common:awardLabels.${getAwardLabelKeyFromAwardCode(pin.awardCode, pin.awardLevel)}`,
                    commonContent.awardLabels[getAwardLabelKeyFromAwardCode(pin.awardCode, pin.awardLevel)],
                  )}`}
                </div>
                <div className="EligibilityPin__amount">
                  {formatCurrency(pin.bonus, bonusCurrency || getDefaultCurrencyCode(), locale, country, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                </div>
              </LeafTile>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
