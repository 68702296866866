import { formatAboPV } from "@amway-acc/acc-mixins/l10n";
import { storedLocale, marketCode } from "../../../../components/impersonation/util";

type Props = {
  pv: number;
};

export const LeafPV = (props: Props) => {
  const { pv } = props;
  const locale = storedLocale();
  const market = marketCode();

  return <>{formatAboPV(pv, locale, market)}</>;
};
