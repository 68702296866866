import { CustomerListQueryParams, CustomersResponse } from "../reducers/model/customerList";
import {
  updateCustomerList as updateCustomerListPages,
  updateIsError as updateCustomerListPagesError,
  updateIsFetching as updateCustomerListPageFetching,
} from "../reducers/customer/customers";
import {
  updateCustomerList as updateNewCustomer,
  updateIsError as updateNewCustomerError,
  updateIsFetching as updateNewCustomerFetching,
} from "../reducers/customer/newCustomers";
import { accAPI } from ".";

export type CustomerListRTKQueryParams = {
  affAbo: string;
} & CustomerListQueryParams;

const getQueryParamsString = ({
  pageNum,
  pageSize,
  period,
  sortValue,
  sortOrder,
  searchBy,
  locale,
  isNew,
  isVerified,
  customerIds,
  confidentialUserText,
  businessNature,
}: CustomerListQueryParams) => {
  const parsedParams = new URLSearchParams({
    pageNum: `${pageNum}`,
    pageSize: `${pageSize}`,
    period,
    sortValue,
    sortOrder,
  });

  // optional fields
  if (searchBy !== void 0 && searchBy.length > 0) {
    parsedParams.append("searchBy", searchBy);
  }

  if (locale !== void 0) {
    parsedParams.append("locale", `${locale}`);
  }

  if (isNew !== void 0) {
    parsedParams.append("isNew", `${isNew}`);
  }

  if (isVerified !== void 0) {
    parsedParams.append("isVerified", `${isVerified}`);
  }

  if (customerIds !== void 0) {
    parsedParams.append("customerIds", `${customerIds}`);
  }

  if (confidentialUserText !== void 0 && confidentialUserText.length > 0) {
    parsedParams.append("confidentialUserText", confidentialUserText);
  }

  if (businessNature !== void 0) {
    parsedParams.append("businessNature", `${businessNature}`);
  }

  return parsedParams.toString();
};

const getURL = ({ affAbo, ...queryParams }: CustomerListRTKQueryParams) =>
  `/customer/v2/${affAbo}?${getQueryParamsString(queryParams)}`;

export const customerListAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getCustomerList: build.query<CustomersResponse, CustomerListRTKQueryParams>({
      query: (params) => getURL(params),
    }),
    // query with customers reducer side-effect
    getCustomerListPages: build.query<CustomersResponse, CustomerListRTKQueryParams>({
      query: (params) => getURL(params),
      async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
        try {
          dispatch(updateCustomerListPageFetching({ requestId, isFetching: true }));
          const { data } = await queryFulfilled;
          dispatch(updateCustomerListPages({ requestId, data }));
        } catch (error) {
          dispatch(updateCustomerListPagesError({ requestId, isError: true }));
        }
      },
    }),
    // query with newCustomers reducer side-effect
    getNewCustomerList: build.query<CustomersResponse, CustomerListRTKQueryParams>({
      query: (params) => getURL(params),
      async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
        try {
          dispatch(updateNewCustomerFetching({ requestId, isFetching: true }));
          const { data } = await queryFulfilled;
          dispatch(updateNewCustomer({ requestId, data }));
        } catch (error) {
          dispatch(updateNewCustomerError({ requestId, isError: true }));
        }
      },
    }),
  }),
});

export const { useGetCustomerListQuery, useLazyGetCustomerListPagesQuery, useLazyGetNewCustomerListQuery } =
  customerListAPI;
