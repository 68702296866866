import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LeafGroupFilter.scss";
import ReactDOM from "react-dom";
import { IconClose } from "../../icons/IconClose/IconClose";
import defaultContent from "../../../content/leafFilter";
import { FilterGroup } from "../../../reducers/model/filters";
import LeafFilterButton from "./LeafFilterButton/LeafFilterButton";
import LeafFilterBy from "./LeafFilterBy/LeafFilterBy";

type FilterItem = {
  id: string;
  text: string;
  checked: boolean;
  enable: boolean;
  quickFilter: boolean;
  value: boolean | number | string;
  group: string;
};
interface LabeledFilterGroup extends FilterGroup {
  groupLabel: string;
}

type Props = {
  clearAllOnClick: Function;
  closeFilterOnClick: Function;
  applyOnClick: Function;
  checkFilterOnClick: Function;
  textContent: {
    filterTitle: string;
  };
  filters: FilterItem[];
  filterGroups: LabeledFilterGroup[];
  filterCount: number;
  showFilter: boolean;
  id: string;
  displayHeader?: boolean;
};

const LeafGroupFilter = (props: Props) => {
  const { t } = useTranslation(["leafFilter"]);
  const {
    clearAllOnClick,
    closeFilterOnClick,
    applyOnClick,
    checkFilterOnClick,
    textContent,
    filters,
    filterCount,
    showFilter,
    id,
    displayHeader = false,
    filterGroups,
  } = props;

  const { filterTitle } = textContent;
  const [_filters, setFilters] = useState(filters);
  const [_filterCount, setFilterCount] = useState(filterCount);
  const [selectedGroup, setSelectedGroup] = useState(filterGroups.filter((g) => !g.disabled)[0]?.group || "");
  const [modalOpenedByElement, setModalOpenedByElement] = useState<Element>();
  const clearAllButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showFilter) {
      // capture the button that opened the modal so we can refocus it when modal closes
      if (document.activeElement instanceof HTMLButtonElement) {
        setModalOpenedByElement(document.activeElement);
      }

      if (clearAllButtonRef.current) {
        clearAllButtonRef.current.focus();
      }
    }
  }, [showFilter]);

  const refocusFilterBtnOnClose = () => {
    if (modalOpenedByElement) {
      (modalOpenedByElement as HTMLElement).focus();
      setModalOpenedByElement(undefined);
    }
  };

  const uncheckAllFilters = () => {
    filters.forEach((filter) => (filter.checked = false));
    setFilters(filters);
    return filters;
  };

  const updateFilterCount = (updatedFilters: FilterItem[]) => {
    const updatedCount = updatedFilters.filter((filter) => filter.checked).length;
    setFilterCount(updatedCount);
  };

  const filterOptionOnClick = (filterItem: FilterItem) => {
    filterItem.checked = !filterItem.checked;
    const updatedFilter = { ...filterItem };
    const updatedFilters = _filters.map((filter) => (filter.id === filterItem.id ? updatedFilter : filter));
    setFilters(updatedFilters);
    updateFilterCount(updatedFilters);
    checkFilterOnClick(filterItem);
  };

  const _closeFilterOnClick = () => {
    setFilters(filters);
    updateFilterCount(filters);
    closeFilterOnClick();
    refocusFilterBtnOnClose();
  };

  const _clearAllOnClick = () => {
    uncheckAllFilters();
    setFilters(filters);
    updateFilterCount(filters);
    clearAllOnClick();
    refocusFilterBtnOnClose();
  };

  const _applyOnClick = () => {
    const allFilters = { filters: filters };
    updateFilterCount(filters);
    applyOnClick(allFilters);
    refocusFilterBtnOnClose();
  };

  const sliderBackdrop = (
    <div onClick={_closeFilterOnClick} id={`${id}-backdrop`} className="leaf-group-filter-backdrop"></div>
  );

  const applFiltersText = t("applyFilters", defaultContent["applyFilters"], {
    FILTER_COUNT: _filterCount ? ` (${_filterCount})` : "",
  });

  const japaneseChars = applFiltersText.match(
    /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g,
  );
  const containsJapanese = japaneseChars?.length;
  let fButtonsClass = "";
  if (containsJapanese) {
    if (applFiltersText.length > 11) {
      fButtonsClass = "very-long-text";
    } else if (applFiltersText.length > 10) {
      fButtonsClass = "longer-text";
    } else if (applFiltersText.length > 7) {
      fButtonsClass = "long-text";
    }
  } else if (applFiltersText.length > 17) {
    fButtonsClass = "very-long-text";
  } else if (applFiltersText.length > 15) {
    fButtonsClass = "longer-text";
  } else if (applFiltersText.length > 13) {
    fButtonsClass = "long-text";
  }

  return (
    <div className={`leaf-group-filter__mobile-background${showFilter === true ? "--displayed" : "--displayed"}`}>
      <div
        id={id}
        className={`leaf-group-filter leaf-group-filter${showFilter === true ? "--displayed" : "--dismissed"}`}
      >
        <div className="leaf-group-filter__filter-header">
          <div className="leaf-group-filter__filter-tabs" />
          <div className={`leaf-group-filter__header-title${displayHeader === true ? "" : "--hidden"}`}>
            {t("title", defaultContent["title"])} ({_filterCount})
          </div>
          <button
            className="leaf-group-filter__clear-all-section leaf-group-filter__clear-all"
            data-testid="leaf-group-filter__clear-all-section"
            onClick={_clearAllOnClick}
            ref={clearAllButtonRef}
          >
            <>
              {t("clearAll", defaultContent["clearAll"])}
              <IconClose height="0.875rem" width="0.875rem" />
            </>
          </button>
        </div>
        <div className="leaf-group-filter__filter-body">
          <div className="leaf-group-filter__filter-tabs">
            {/** map through the list of groups */}
            {filterGroups.map(({ group, groupLabel, disabled }, index) => {
              return (
                <button
                  id={`filter-group-button-${index}`}
                  key={index}
                  className={
                    selectedGroup === group
                      ? `leaf-group-filter__filter-title-mobile--selected`
                      : `leaf-group-filter__filter-title-mobile`
                  }
                  disabled={disabled || false}
                  onClick={() => setSelectedGroup(group)}
                >
                  {groupLabel}
                </button>
              );
            })}
          </div>
          <div className="leaf-group-filter__filter-options">
            <div className="leaf-group-filter__filter-by">
              <div className="leaf-group-filter__filter-title--hidden">{filterTitle}</div>
              <div className="leaf-group-filter__filter-list">
                <LeafFilterBy
                  filters={filters.filter((filter) => filter.group === selectedGroup)}
                  itemOnClick={filterOptionOnClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="leaf-group-filter__filter-footer">
          <div className="leaf-group-filter__filter-tabs" />
          <div className={"leaf-group-filter__footer-content " + fButtonsClass}>
            <LeafFilterButton text={t("cancel", defaultContent["cancel"])} onButtonClick={_closeFilterOnClick} />
            <LeafFilterButton text={applFiltersText} onButtonClick={_applyOnClick} theme="dark" />
          </div>
        </div>
      </div>
      {showFilter && ReactDOM.createPortal(sliderBackdrop, document.body)}
    </div>
  );
};

export default LeafGroupFilter;
