import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../common/enums/tealium-enums";
import defaultContent from "../../../../content/frontlineGrowth";
import { FggOverviewResponse } from "../../../../common/interfaces/groupGrowthIncentives";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import "../MultiplierDetails.scss";
import { MultiplierDetail } from "../../../../common/components/MultiplierDetail/MultiplierDetail";
import { setModalBtnFocus } from "../MultiplierDetailsUtil";
import { useAppSelector } from "../../../../store";
import { MatrixFQModal } from "../../../../common/components/MatrixFQModal/MatrixFQModal";

type Props = {
  fggOverviewData: FggOverviewResponse;
  currentPerformanceYear: string;
  prevOpenRef: MutableRefObject<boolean>;
};

export const MatrixFQ = ({ fggOverviewData, currentPerformanceYear, prevOpenRef }: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const viewMatrixButton = useRef<HTMLButtonElement>(null);
  const [showMatrixModal, setShowMatrixModal] = useState(false);
  const { MultiplierMatrix, IncentiveData } = fggOverviewData;
  const { PotentialMultiplier, TrackingQ } = IncentiveData;
  const pyShort = currentPerformanceYear.slice(2, 4);
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;

  const _isAnaMarket = () => {
    return ["US", "CA", "DO"].includes(market);
  };

  const multiplierMatrixModalToggle = () => {
    setShowMatrixModal(!showMatrixModal);
    setModalBtnFocus(viewMatrixButton, prevOpenRef, showMatrixModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.FRONTLINE_GROUP_GROWTH,
      `incentive_multiplier_matrix_modal_${!showMatrixModal ? "open" : "close"}`,
      "multiplier_matrix_modal",
    );
  };

  return (
    <>
      <MultiplierDetail
        title={t("multiplierMatrix.title", defaultContent.multiplierMatrix.title, {
          fqs: fggOverviewData.IncentiveData.TrackingQ,
        })}
        subtitle={`${t(
          "multiplierMatrix.potentialMultiplier",
          defaultContent.multiplierMatrix.potentialMultiplier,
        )} = ${fggOverviewData.IncentiveData.PotentialMultiplier}`}
        description={`${t("multiplierMatrix.description", defaultContent.multiplierMatrix.description, {
          performanceYear: pyShort,
        })}`}
        buttonInfo={{
          callback: multiplierMatrixModalToggle,
          ref: viewMatrixButton,
          text: `${t("multiplierDetails.viewMatrix", defaultContent.multiplierDetails.viewMatrix)}`,
          icon: <IconPlainTable />,
        }}
        isMoreInfoAvailable={_isAnaMarket()}
      />
      <MatrixFQModal
        displayModal={showMatrixModal}
        toggleModal={multiplierMatrixModalToggle}
        incentive="frontlineGrowth"
        matrixFQData={{
          matrixFQs: TrackingQ,
          potentialMultiplier: PotentialMultiplier,
          performanceYear: pyShort,
          multiplierMatrixData: MultiplierMatrix,
        }}
      />
    </>
  );
};
