import { useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../icons/IconCheckCircle/IconCheckCircle";
import { AWARD_PROGRESS } from "../../enums/award-progress-codes";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../icons/IconShield/IconShield";
import { IconCancel } from "../../icons/IconCancel/IconCancel";
import defaultContent from "../../../content/awardProgressBadge";
import "./AwardProgressBadge.scss";

type Props = {
  status: AWARD_PROGRESS;
  hideIcon?: boolean;
};

export const AwardProgressBadge = ({ status, hideIcon = false }: Props) => {
  const { t } = useTranslation(["awardProgressBadge"]);

  const statusText = t(status, defaultContent[status]).toString();
  return (
    <>
      <div data-testid="awardProgressBadge">
        <div className={`awardProgressBadge__title awardProgressBadge__title--${status}`}>
          {hideIcon ? (
            <></>
          ) : (
            <>
              {status === AWARD_PROGRESS.TRACKING ? (
                <IconCheckCircle title={statusText} id={status} color="blue" size="sm" solidCheckCircle />
              ) : (
                <>
                  {status === AWARD_PROGRESS.ACHIEVED ? (
                    <IconShield title={statusText} type={BADGE_TYPE.EARNED} size={BADGE_SIZE.EXTRA_SMALL} />
                  ) : (
                    <IconCancel title={statusText} height="1rem" width="1rem" />
                  )}
                </>
              )}
            </>
          )}
          {statusText}
        </div>
      </div>
    </>
  );
};
