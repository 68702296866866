import { useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/analytics";
import defaultContent from "../../../../content/monthlyPGG";
import TEALIUM from "../../../../common/enums/tealium-enums";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import { IconMultiply } from "../../../../common/icons/IconMultiply/IconMultiply";
import LeafTileValue from "../../../../common/leaf/LeafTileValue/LeafTileValue";
import "./MonthlyIncentiveCalculation.scss";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import breakpoints from "../../../../common/scss/breakpoints.module.scss";
import {
  MonthlyPGGIncentiveData,
  MultiplierData,
  PerformanceBonusData,
} from "../../../../common/interfaces/groupGrowthIncentives";
import { MonthlyPGGPerformanceBonusModal } from "./MonthlyPerformanceBonusModal/MonthlyPerformanceBonusModal";

type Props = {
  isLoading: boolean;
  incentiveData: MonthlyPGGIncentiveData;
  performanceBonusData: PerformanceBonusData[];
};

const getIncentiveValues = (multiplierData: MultiplierData[]) => {
  const incentive = multiplierData.find((d) => d.Type === "Incentive");

  return {
    base: incentive?.Base || "",
    multiplier: incentive?.Multiplier || "",
    total: incentive?.Total || "",
  };
};

export const MonthlyIncentiveCalculation = ({ isLoading, performanceBonusData, incentiveData }: Props) => {
  const { t } = useTranslation(["monthlyPGG"]);
  const { width } = useWindowSize();
  const { MultiplierData, YtdTotal } = incentiveData;
  const incentiveValues = getIncentiveValues(MultiplierData);
  const [showPerfBonusModal, setShowPerfBonusModals] = useState<boolean>(false);

  const performanceBonusModalToggle = () => {
    setShowPerfBonusModals(!showPerfBonusModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `monthly_performance_bonus_modal_${!showPerfBonusModal ? "open" : "close"}`,
      "monthly_performance_bonus_modal_",
    );
  };

  const MonthlyCalculation = () => (
    <>
      <LeafTileValue
        id="MonthlyIncentiveCalculation-base"
        isLoading={isLoading}
        title={`${t("performanceBonus", defaultContent["performanceBonus"])}`}
        value={incentiveValues.base || "-"}
        icon={<IconPlainTable />}
        callback={performanceBonusModalToggle}
      />
      <span className="MonthlyIncentiveCalculation__symbol">
        <IconMultiply />
      </span>
      <LeafTileValue
        id="MonthlyIncentiveCalculation-multiplier"
        isLoading={isLoading}
        title={`${t("monthlyIncentiveMultiplier", defaultContent["monthlyIncentiveMultiplier"])}`}
        value={incentiveValues.multiplier || "-"}
        symbolIcon={<IconMultiply />}
      />
      <div className="MonthlyIncentiveCalculation__divider" />
      <span className="MonthlyIncentiveCalculation__symbol">
        <IconEquals />
      </span>
      <LeafTileValue
        id="MonthlyIncentiveCalculation-monthlyTotal"
        isLoading={isLoading}
        title={`${t("monthlyTotal", defaultContent["monthlyTotal"])}`}
        value={incentiveValues.total || "-"}
        symbolIcon={<IconEquals />}
      />
    </>
  );

  const YTDTotal = () => (
    <LeafTileValue
      id="MonthlyIncentiveCalculation-ytdTotal"
      isLoading={isLoading}
      title={`${t("ytdTotal", defaultContent["ytdTotal"])}`}
      value={YtdTotal || "-"}
      symbolIcon={<IconEquals />}
    />
  );

  return (
    <>
      <div className="MonthlyIncentiveCalculation">
        {Number(width) >= Number(breakpoints.desktop) ? (
          <>
            <MonthlyCalculation />
            <YTDTotal />
          </>
        ) : (
          <>
            <section className="MonthlyIncentiveCalculation__monthlyCalc">
              <MonthlyCalculation />
            </section>
            <section className="MonthlyIncentiveCalculation__ytdCalc">
              <YTDTotal />
            </section>
          </>
        )}
      </div>
      <MonthlyPGGPerformanceBonusModal
        performanceBonusData={performanceBonusData}
        toggleModal={performanceBonusModalToggle}
        displayModal={showPerfBonusModal}
      />
    </>
  );
};
