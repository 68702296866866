import { MutableRefObject, RefObject } from "react";
import { PQ } from "../../../../common/interfaces/groupGrowthIncentives";
import { TableRow } from "../../../../common/leaf/LeafTable/LeafTable";
import { IconShield, BADGE_TYPE, BADGE_SIZE } from "../../../../common/icons/IconShield/IconShield";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { getCurrentPeriod, getSelectedPerformanceYearData, getShortMonth } from "../../../../common/util/period";
import "./MultiplierDetails.scss";
import { storedLocale } from "../../../impersonation/util";

export const createMultiplierDetailsTableRows = (pqMonths: PQ[], showBooster: boolean): TableRow[] => {
  const locale = storedLocale();

  const rows = pqMonths.map((pqMonth: PQ): TableRow => {
    const badgeType = getBadgeStatus(pqMonth.Status);

    const firstCell = (
      <span className="MultiplierDetails__first-cell-container">
        <IconShield type={badgeType} size={BADGE_SIZE.SMALL} />
        <span className="MultiplierDetails__first-cell-container--month">{getShortMonth(pqMonth.Month, locale)}</span>
      </span>
    );

    const rubyCell = <span className="MultiplierDetails__mobile-font">{pqMonth.RubyPV}</span>;

    const qualLegs = <span className="MultiplierDetails__mobile-font">{pqMonth.InMarketQualLegs}</span>;

    const rubyNeeded = <span className="MultiplierDetails__mobile-font">{pqMonth.RubyPVNeeded}</span>;
    if (pqMonth.BoosterReqStatus && showBooster) {
      return {
        row: [
          { value: firstCell },
          { value: getKicker(pqMonth.BoosterReqStatus) },
          { value: rubyCell, columnAlign: "center" },
          { value: qualLegs, columnAlign: "center" },
          { value: rubyNeeded, columnAlign: "center" },
        ],
      };
    } else {
      return {
        row: [
          { value: firstCell },
          { value: rubyCell, columnAlign: "center" },
          { value: qualLegs, columnAlign: "center" },
          { value: rubyNeeded, columnAlign: "center" },
        ],
      };
    }
  });

  return rows;
};

const getBadgeStatus = (status: QUALIFICATION_STATUS) => {
  switch (status) {
    case QUALIFICATION_STATUS.QUALIFIED:
      return BADGE_TYPE.EARNED;
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
    case QUALIFICATION_STATUS.NOT_TRACKING:
      return BADGE_TYPE.NOT_EARNED;
    default:
      return BADGE_TYPE.POTENTIAL;
  }
};

const getKicker = (status: QUALIFICATION_STATUS) => {
  const badgeType = getBadgeStatus(status);

  return (
    <span className="MultiplierDetails__booster-cell">
      <IconShield type={badgeType} size={BADGE_SIZE.SMALL} />
    </span>
  );
};

export const renderLoadingOrError = (isLoading: boolean, errorStatus: boolean, refetch: Function) => {
  if (isLoading) {
    return (
      <LeafRectangleLoader isLoading={isLoading} numLines={2} width="100%" height="6rem" margin={"1rem 0 0.5rem 0"} />
    );
  }
  if (errorStatus) {
    return <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />;
  }
  return <></>;
};

/**
 * Sets focus back to modal btn when navigating out of modal with keyboard
 *
 * @param modalBtnRef
 * @param prevOpenRef
 * @param showCurrentModal
 */
export const setModalBtnFocus = (
  modalBtnRef: RefObject<HTMLButtonElement>,
  prevOpenRef: MutableRefObject<boolean>,
  showCurrentModal: boolean,
) => {
  if (modalBtnRef && modalBtnRef.current) modalBtnRef.current.focus();
  prevOpenRef.current = showCurrentModal;
};

export const hideButtonAndSkipRequest = (fqs: string, selectedPeriod: string) => {
  if (!fqs || fqs === "0") return true;

  const currentDatePeriod = getCurrentPeriod();
  const { selectedPYStartPeriod: twoPYsPriorFromCurrentDate } = getSelectedPerformanceYearData(currentDatePeriod, 2);
  const { selectedPYStartPeriod: selectedPyStartPeriod } = getSelectedPerformanceYearData(selectedPeriod, 0);
  if (twoPYsPriorFromCurrentDate === selectedPyStartPeriod) return true;
  return false;
};

export const pyIsTwoPriorThanSelectedPY = (selectedPeriod: string) => {
  const currentDatePeriod = getCurrentPeriod();
  const { selectedPYStartPeriod: twoPYsPriorFromCurrentDate } = getSelectedPerformanceYearData(currentDatePeriod, 2);
  const { selectedPYStartPeriod: selectedPyStartPeriod } = getSelectedPerformanceYearData(selectedPeriod, 0);
  if (twoPYsPriorFromCurrentDate === selectedPyStartPeriod) return true;
  return false;
};
