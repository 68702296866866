import { ReactComponent as CircleISvg } from "./svg/icon_circle_i.svg";

const IconCircleI = () => {
  return (
    <span className="IconCircleI">
      <CircleISvg />
    </span>
  );
};

export default IconCircleI;
