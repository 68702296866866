import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { ROUTE_LABELS } from "../../../../common/enums/routing-enums";
import defaultContent from "../../../../content/annualPGG";
import { SELECTOR_TYPE, updateSelectorType } from "../../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";

const loadComponent = async () => {
  return import("../../../Lit/acc-coreplus-incentives/acc-personal-growth.js");
};

export default function AnnualPGGLit() {
  const { t } = useTranslation(["annualPGG"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { shellConfig, configuration } = useAppSelector(({ boot }) => boot);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState(selectedPeriod);
  const { isPending } = useAsync({ promiseFn: loadComponent });

  // checking if the selectedPeriod changes as it triggering the component.
  // To avoid multiple calls to the component and multiple rendering, added a useffect to check for change in selectedPeriod
  useEffect(() => {
    setPeriod(selectedPeriod);
  }, [selectedPeriod]);

  /**
   * When routing to an internal route from an imported component the React Link is not being used, which
   * causes the location.state to not update. When that happens we need to use this to set the default from
   * session stored menuConfig. Otherwise just use the location.state to set the label.
   * @returns string
   * @TODO Can be removed once we no longer are routing from link inside imported component. (Annual Dashboard)
   */
  const __getDefaultLabel = () => {
    const { menuConfig } = shellConfig;
    if (!menuConfig) {
      return ROUTE_LABELS.ANNUAL_PGG;
    }
    const leaderGrowth = menuConfig.find((d: { key: string }) => d.key === "leaderGrowth");
    if (!leaderGrowth) {
      return ROUTE_LABELS.ANNUAL_PGG;
    }

    const annualGroupGrowthActive = leaderGrowth.items.some((item) => item.code === AWARD_CODES.ANNUAL_PGG);
    const monthlyGroupGrowthActive = leaderGrowth.items.some((item) => item.code === AWARD_CODES.MONTHLY_PGG);
    if (annualGroupGrowthActive && monthlyGroupGrowthActive) {
      return ROUTE_LABELS.ANNUAL_PGG;
    }
    return ROUTE_LABELS.PERSONAL_GROUP_GROWTH;
  };

  const titleKey = __getDefaultLabel();

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const ele = document.getElementById("acc_pgg_component") as HTMLElement;
      ele.setAttribute("learn-more-link", configuration.dashboardLinks?.learnMore?.personalGroupGrowth);
    }
  }, [isPending, configuration]);

  const pggKeys: string[] = [ROUTE_LABELS.ANNUAL_PGG, ROUTE_LABELS.PERSONAL_GROUP_GROWTH];
  const pggTitle = pggKeys.includes(titleKey)
    ? `${t(`pageTitle.${titleKey}`, defaultContent["pageTitle"][titleKey])}`
    : `${t(`${titleKey}`)}`;

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <h1 className="acc_pgg_header" id="acc_pgg_header">
        {pggTitle}
      </h1>
      <acc-personal-growth
        learn-more-link=""
        id="acc_pgg_component"
        period={period}
        locale={storedLocale()}
        mode="standalone"
      ></acc-personal-growth>
    </main>
  );
}
