import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { track } from "@amway-acc/acc-mixins/analytics";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import { IconInfoCircle } from "../../common/icons/IconInfoCircle/IconInfoCircle";
import commonContent from "../../content/common";
import annualContent from "../../content/annualPerformanceDashboard";
import monthlyContent from "../../content/monthlyPerformanceDashboard";
import navigationContent from "../../content/navigation";
import { useAppSelector } from "../../store";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import "./PerformanceDashboard.scss";
import TEALIUM from "../../common/enums/tealium-enums";
import { addParams } from "../../common/util/addParams";
import { AnnualPerformanceDashboard } from "./AnnualPerformanceDash/AnnualPerformanceDash";
import { MonthlyPerformanceDashboard } from "./MonthlyPerformanceDash/MonthlyPerformanceDash";

const PerformanceDashboard = () => {
  const PERFORMANCE_TABS = {
    MONTHLY: "monthly",
    ANNUAL: "annual",
  };
  const { t } = useTranslation(["common", "navigation", "monthlyPerformanceDashboard", "annualPerformanceDashboard"]);
  const { hash } = useLocation();
  const [selectedTab, setSelectedTab] = useState(PERFORMANCE_TABS.MONTHLY);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAnnualInfoModal, setShowAnnualModal] = useState(false);

  const { enableMonthlyAnnualToggle } = useAppSelector((state) => state.boot.configuration);
  const { shouldShowAnnualPerformance } = useAppSelector((state) => state.boot.shellConfig);
  const monthlyDashboard = t("pageTitle", monthlyContent["pageTitle"], { ns: "monthlyPerformanceDashboard" });
  const annualDashboard = t("annualDashboard", annualContent["annualDashboard"], { ns: "annualPerformanceDashboard" });
  const MAX_BTN_CHARS = 25;
  const shouldWrapToggleSwitch = annualDashboard.length >= MAX_BTN_CHARS || monthlyDashboard.length >= MAX_BTN_CHARS;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const annualInfoModalParam = queryParams.get("annualInfoModal") === "true";
  const monthlyInfoModalParam = queryParams.get("monthlyInfoModal") === "true";
  const kpiSelectorModalParam = queryParams.get("kpiSelectorModal") === "true";
  const performanceModalParam = queryParams.get("performanceModal") === "true";

  const Tabs = [
    {
      id: PERFORMANCE_TABS.MONTHLY,
      text: monthlyDashboard,
    },
    {
      id: PERFORMANCE_TABS.ANNUAL,
      text: annualDashboard,
    },
  ];

  const handleShowInfoModal = () => {
    if (selectedTab === PERFORMANCE_TABS.ANNUAL) {
      setShowAnnualModal(true);
      addParams("annualInfoModal", "true", true, false);
    } else {
      setShowInfoModal(true);
      addParams("monthlyInfoModal", "true", true, false);
    }

    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      PERFORMANCE_TABS.ANNUAL ? "annual_dashboard" : "monthly_dashboard",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "info_icon",
    );
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
    setShowAnnualModal(false);
    addParams("annualInfoModal", "false", false, true);
    addParams("monthlyInfoModal", "false", false, true);
  };

  useEffect(() => {
    if ((annualInfoModalParam && !showAnnualInfoModal) || (monthlyInfoModalParam && !showInfoModal)) {
      handleInfoModalClose();
    }
  }, [annualInfoModalParam, monthlyInfoModalParam, showAnnualInfoModal, showInfoModal]);

  const handleTabClick = (tabId: string) => {
    window.location.hash = tabId;
    setSelectedTab(tabId);
  };

  useEffect(() => {
    if (!["#monthly", "#annual"].includes(hash)) window.location.hash = PERFORMANCE_TABS.MONTHLY;
  }, []);

  useMemo(() => {
    if ("#" + selectedTab !== hash) {
      setSelectedTab(hash.replace("#", ""));
    } else if (!["#monthly", "#annual"].includes(hash)) {
      setSelectedTab(PERFORMANCE_TABS.MONTHLY);
      window.location.hash = PERFORMANCE_TABS.MONTHLY;
    }
  }, [hash]);

  return (
    <main className="PerformanceDashboard">
      <div className="PerformanceDashboard__heading">
        <div className="PerformanceDashboard__heading--title">
          <h1 id="PerformanceDashboard__heading-title">
            {t("linkTitle.performanceDashboard", navigationContent.linkTitle.performanceDashboard as string, {
              ns: "navigation",
            })}
          </h1>
          <button
            aria-label={`${t("moreInfo", commonContent["moreInfo"], { ns: "common" })}`}
            className="PerformanceDashboard__heading-info-button"
            onClick={handleShowInfoModal}
            id="infoModalButton"
          >
            <IconInfoCircle />
          </button>
        </div>
        <span className="PerformanceDashboard__heading--timestamp">
          <TimeStamp dateFormat={"long"} />
        </span>
      </div>
      {enableMonthlyAnnualToggle && shouldShowAnnualPerformance && (
        <div className={`PerformanceDashboard__toggle-container${shouldWrapToggleSwitch ? "--column" : ""}`}>
          {Tabs.map((tab) => (
            <button
              className={`PerformanceDashboard__toggleButton${selectedTab === tab.id ? "--active" : ""}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.text}
            </button>
          ))}
        </div>
      )}
      <section>
        {selectedTab === PERFORMANCE_TABS.ANNUAL ? (
          <AnnualPerformanceDashboard showModal={showAnnualInfoModal} modalCloseCallback={handleInfoModalClose} />
        ) : (
          <MonthlyPerformanceDashboard showModal={showInfoModal} modalCloseCallback={handleInfoModalClose} />
        )}
      </section>
      <section className="PerformanceDashboard__footer">
        <BonusInfoFooter />
      </section>
    </main>
  );
};

export default PerformanceDashboard;
