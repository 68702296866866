import { useTranslation } from "react-i18next";
import {
  previousNPeriods,
  periodFromDate,
  lastPeriodOfPerformanceYear,
  performanceYear,
} from "@amway-acc/acc-mixins/dates";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import { LeafVerticalScale, TickMarks } from "../../../common/leaf/LeafVerticalScale/LeafVerticalScale";
import { storedLocale } from "../../impersonation/util";
import defaultContent from "../../../content/ttci";
import "./TargetScheduleModal.scss";
import { getFullMonth } from "../../../common/util/period";

type EligibleProps = {
  currentPPV: number;
  monthlyPPV: number;
  py: string;
  currentMonth?: string;
  onCloseClick: Function;
  show: boolean;
};

export const TargetScheduleModal = ({
  currentPPV,
  monthlyPPV,
  py,
  onCloseClick,
  show,
  currentMonth = periodFromDate(new Date()),
}: EligibleProps) => {
  const { t } = useTranslation(["ttci"]);
  const numMonths = 12;
  const lastPeriod = lastPeriodOfPerformanceYear(py);
  const period = currentMonth > lastPeriod ? lastPeriod : currentMonth;
  const locale = storedLocale();
  const selectedPeriod = getFullMonth(period, locale);

  const periodLabels = previousNPeriods(lastPeriod, numMonths, true)
    .reverse()
    .map((period) => getFullMonth(period, locale));

  const ppvRange: TickMarks[] = [];
  for (let month = 1; month <= numMonths; month++) {
    const ppvIncrement = month * monthlyPPV;
    ppvRange.push({
      value: ppvIncrement,
      label: ppvIncrement.toLocaleString(locale),
    });
  }

  const maxPPV = ppvRange[numMonths - 1];

  const title = `${t("targetSchedule.modalTitle", defaultContent["targetSchedule"]["modalTitle"])}`;
  const description = `${t("targetSchedule.modalDescription", defaultContent["targetSchedule"]["modalDescription"], {
    targetValue: monthlyPPV,
  })}`;

  return (
    <LeafPanel show={show} onCloseClick={onCloseClick} title={title} id={"ttci-target-modal"}>
      <div className="targetSchedule__wrapper">
        <div className="targetSchedule__description">{description}</div>
        <div className="targetSchedule__scaleWrapper">
          <LeafVerticalScale
            tickMarks={ppvRange}
            scaleMax={maxPPV.value}
            scaleMin={0}
            scaleCurrent={currentPPV}
            legendLabels={periodLabels}
            highlightedLegendLabel={selectedPeriod}
            legendTitle={`${t("targetSchedule.legendTitle", defaultContent["targetSchedule"]["legendTitle"])}`}
            scaleTitle={t("targetSchedule.scaleTitle", defaultContent["targetSchedule"]["scaleTitle"], {
              year: performanceYear(py).toString().slice(2, 4),
            })}
          />
        </div>
      </div>
    </LeafPanel>
  );
};
