import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store";
import MonthlyPGGLit from "./MonthlyPGGLit";
import MonthlyPGG from "./MonthlyPGG";

export default function MonthlyPGGSwitcher() {
  const { showOldPgg } = useAppSelector((state) => state.boot.configuration.pgg);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showOldMonthlyPggFromQueryParam = queryParams.get("showOldMonthlyPgg") === "true";

  if (showOldMonthlyPggFromQueryParam) {
    return <MonthlyPGGLit />;
  }

  if (showOldPgg) {
    return <MonthlyPGGLit />;
  }

  return <MonthlyPGG />;
}
