import { FpProgress } from "@amway-acc/acc-es-data/model";
import { useEffect, useState } from "react";
import { useGetFoundersPlatinumProgressQuery } from "../../../services/foundersPlatinumProgressAPI";
import { getAbo, getAff } from "../../impersonation/util";
import { Props as FoundersPlatinumProgressTileData } from "../../ComingSoon/FoundersPlatinumProgressTile/FoundersPlatinumProgressTile";
import { store } from "../../../store";

export const FetchFoundersPlatinumProgressData = (period: string, skip: boolean) => {
  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetFoundersPlatinumProgressQuery(
    {
      baseUrl: `${store.getState().boot.configuration.lambdaUrlBase}`,
      aboNo: `${getAbo()}`,
      affNo: `${getAff()}`,
      fiscalYear: `${period.toString().slice(0, 4)}`,
      period: `${period}`,
    },
    { skip },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<FoundersPlatinumProgressTileData>({});

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching, period]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (!isQueryLoading && !isFetching && !isError && payloadData) {
      const foundersPlatinumProgressTileData = new FpProgress(payloadData);
      const tileData: FoundersPlatinumProgressTileData = {
        minSpMonth1: 11,
        minSpMonth2: 12,
        tileData: {
          gpvTile: {
            isAvailable: foundersPlatinumProgressTileData.isEligibleForGPVorVEPV,
            qualificationMet: foundersPlatinumProgressTileData.isGpvQualificationMet,
            showDisclaimer: true,
            hideToolTip: false,
            gpvSpMonths: foundersPlatinumProgressTileData.neededGpv,
            currentYtdGpv: foundersPlatinumProgressTileData.currentYtdGpv,
            ytdSpCount: foundersPlatinumProgressTileData.ytdSpCount,
            spMonthsNeeded: foundersPlatinumProgressTileData.gpvOrVepvSpMonthsNeeded,
            gpvTarget: foundersPlatinumProgressTileData.gpvTarget,
          },
          spMonthsTile: {
            isAvailable: foundersPlatinumProgressTileData.isEligibleForSP,
            qualificationMet: foundersPlatinumProgressTileData.isSpQualificationMet,
            hideToolTip: false,
            spMonthsNeeded: foundersPlatinumProgressTileData.spMonthsNeeded,
            currentSpMonths: foundersPlatinumProgressTileData.ytdSpCount,
            spMonthsTarget: 12,
          },
          vePvTile: {
            isAvailable: foundersPlatinumProgressTileData.isEligibleForGPVorVEPV,
            qualificationMet: foundersPlatinumProgressTileData.isVepvQualificationMet,
            vePvTarget: foundersPlatinumProgressTileData.vepvTarget,
            currentVePv: foundersPlatinumProgressTileData.ytdVepv,
            vePvSpMonths: foundersPlatinumProgressTileData.neededVepv,
            hideToolTip: false,
            spMonthsNeeded: foundersPlatinumProgressTileData.gpvOrVepvSpMonthsNeeded,
            currentSpMonths: foundersPlatinumProgressTileData.ytdSpCount,
          },
        },
      };
      setData(tileData);
      setIsLoading(false);
      setErrorStatus(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching]);

  return { isLoading, data, errorStatus };
};
