import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { track } from "@amway-acc/acc-mixins/analytics";
import { BannerTile } from "../../../common/components/BannerTile/BannerTile";
import TEALIUM from "../../../common/enums/tealium-enums";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import defaultContent from "../../../content/ssi";
import { useAppSelector } from "../../../store";
import "./SSIBannerTile.scss";
import { IncomeData } from "../../../common/interfaces/strongStartIncentive";
import { getShortMonth, getYear } from "../../../common/util/period";
import { storedLocale } from "../../impersonation/util";

const getBannerTitle = (status: QUALIFICATION_STATUS, selectedPeriod: string, locale: string, translate: TFunction) => {
  let contentKey = "";
  let contentObjects = {};
  switch (status) {
    case QUALIFICATION_STATUS.QUALIFIED:
      contentKey = "earning";
      break;
    case QUALIFICATION_STATUS.TRACKING:
      contentKey = "tracking";
      break;
    case QUALIFICATION_STATUS.NOT_TRACKING:
      contentKey = "notTracking";
      break;
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
      contentKey = "manuallyApproved";
      break;
    case QUALIFICATION_STATUS.HELD:
      contentKey = "held";
      break;
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
    case QUALIFICATION_STATUS.DENIED:
      contentKey = "notEligible";
      contentObjects = {
        month: getShortMonth(selectedPeriod, locale),
        year: getYear(selectedPeriod, locale),
      };
      break;
  }

  return translate(`bannerTile.${contentKey}`, String(defaultContent["bannerTile"][contentKey]), contentObjects);
};

const descriptionOneContent = (incomeData: IncomeData, locale: string, translate: TFunction) => {
  return (
    <section className="SSIBannerTile__payment-desc-one">
      {translate(`bannerTile.descriptionOnePayment`, defaultContent["bannerTile"]["descriptionOnePayment"], {
        numMonths: incomeData.NextPayment.MonthsNeed,
        payment: incomeData.NextPayment.BonusAmount,
      })}
      <span className="SSIBannerTile__bold-text">{/* {numMonths} */}</span>
      <span className="SSIBannerTile__bold-text">{/* {payment} */}</span>
    </section>
  );
};

const descriptionTwoContent = (incomeData: IncomeData, locale: string, translate: TFunction) => {
  const paymentContentKey =
    incomeData.AdditionalPayments.length === 1 ? "descriptionTwoPaymentsOnePaymentRemaining" : "descriptionTwoPayments";
  if (incomeData.RemainingTimes === 0) {
    return (
      <section className="SSIBannerTile__payment-desc-two">
        {translate(
          "youreEligibleToEarnThisIncentiveTimes",
          defaultContent["bannerTile"]["youreEligibleToEarnThisIncentiveTimes"],
          {
            times: incomeData.RemainingTimes,
            month: getShortMonth(incomeData.FinalIncentiveEarnDate, locale),
            year: getYear(incomeData.FinalIncentiveEarnDate, locale),
          },
        )}
        <span className="SSIBannerTile__bold-text">{/* {times} more times */}</span>
      </section>
    );
  }
  return (
    <section className="SSIBannerTile__payment-desc-two">
      {translate(`bannerTile.${paymentContentKey}`, defaultContent["bannerTile"][paymentContentKey], {
        times: incomeData.RemainingTimes,
        month: getShortMonth(incomeData.FinalIncentiveEarnDate, locale),
        year: getYear(incomeData.FinalIncentiveEarnDate, locale),
        paymentOne: incomeData.AdditionalPayments[0]?.BonusAmount ?? 0,
        paymentTwo: incomeData.AdditionalPayments[1]?.BonusAmount ?? 0,
        numMonthsOne: String(incomeData.AdditionalPayments[0]?.RequiredMonths ?? 0),
        numMonthsTwo: String(incomeData.AdditionalPayments[1]?.RequiredMonths ?? 0),
      })}
      <span className="SSIBannerTile__bold-text">{/* {times} more times */}</span>
      <span className="SSIBannerTile__bold-text">{/* paymentOne */}</span>
      <span className="SSIBannerTile__bold-text">{/* paymentTwo */}</span>
    </section>
  );
};

const getBannerDescription = (incomeData: IncomeData, locale: string, translate: TFunction) => {
  switch (incomeData.Status) {
    case QUALIFICATION_STATUS.QUALIFIED:
      return <Trans>{descriptionTwoContent(incomeData, locale, translate)}</Trans>;
    case QUALIFICATION_STATUS.TRACKING:
      return (
        <Trans>
          {descriptionOneContent(incomeData, locale, translate)}
          {descriptionTwoContent(incomeData, locale, translate)}
        </Trans>
      );

    case QUALIFICATION_STATUS.NOT_TRACKING:
      return (
        <Trans>
          {translate(`bannerTile.descriptionStart`, defaultContent["bannerTile"]["descriptionStart"])}
          <span className="SSIBannerTile__bold-text">{/* every */}</span>
        </Trans>
      );
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
      return <Trans>{translate(`bannerTile.moreInformation`, defaultContent["bannerTile"]["moreInformation"])}</Trans>;
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
    case QUALIFICATION_STATUS.HELD:
    case QUALIFICATION_STATUS.DENIED:
      return <>{translate(`bannerTile.moreInformation`, defaultContent["bannerTile"]["moreInformation"])}</>;
    default:
      return <></>;
  }
};

const handleTealiumTrackClickActionLearnMore = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.STRONG_START_INCENTIVE,
    "strong_start_incentive",
    "learn_more",
  );
};

type Props = {
  incomeData: IncomeData;
  selectedPeriod: string;
  isLoading: boolean;
};

export const SSIBannerTile = ({ incomeData, selectedPeriod, isLoading }: Props) => {
  const { t } = useTranslation(["ssi"]);
  const { configuration } = useAppSelector((store) => store.boot);
  const locale = storedLocale();
  const learnMoreLink = configuration.dashboardLinks?.learnMore?.strongStartIncentive || "";

  return (
    <div
      className={`SSIBannerTile ${
        incomeData.Status === QUALIFICATION_STATUS.QUALIFIED ? "" : "SSIBannerTile__not-qualified-banners-only"
      }`}
    >
      <BannerTile
        isLoading={isLoading}
        title={getBannerTitle(incomeData.Status, selectedPeriod, locale, t)}
        description={getBannerDescription(incomeData, locale, t)}
        bonusAmountLabel={t(
          `bannerTile.strongStartIncentiveEarnings`,
          String(defaultContent["bannerTile"]["strongStartIncentiveEarnings"]),
        )}
        shouldDisplayAmount={incomeData.Status === QUALIFICATION_STATUS.QUALIFIED}
        bonusAmount={incomeData.CurrentEarnings}
        learnMoreLink={learnMoreLink}
        learnMoreTealiumFunction={handleTealiumTrackClickActionLearnMore}
      />
    </div>
  );
};
