import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

export const cpvChartClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CML_OVERVIEW,
    "",
    "open_total_cpv_chart",
  );
};

export const cpvChartErrorTrack = (period: string) => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, `overview_total_cpv_error:${period}`, "");
};
