/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import { useTranslation } from "react-i18next";
import { DROPDOWN_KEYS, ROUTE_PATHS } from "../../common/enums/routing-enums";
import { returnRoutesByKey } from "../../routing/Routing";
import { IconArrowLink } from "../../common/icons/IconArrowLink/IconArrowLink";
import TEALIUM from "../../common/enums/tealium-enums";
import { TealiumClickActionMapper } from "../../common/util/tealiumClickActionMapper";
import defaultContent from "../../content/smartNextSteps";
import "./SmartNextSteps.scss";
import { NavDropdownItem, NavDropdown } from "../../common/interfaces/routing";
import { reloadForP2Components } from "../../common/util/checkP2Routes";
import { useAppDispatch, useAppSelector } from "../../store";
import { setLocalStorageItem } from "../../reducers/loginLocalStorage";

type PageViewCounts = Record<string, number>;
type SmartNextStepsLink = Array<string>;

type Props = {
  menuConfig: NavDropdown[];
  clickFunction?: Function;
  restrictSize?: boolean;
};

export const SmartNextSteps = ({ menuConfig, clickFunction = () => {}, restrictSize = false }: Props) => {
  const smartNextStepsRef = useRef<HTMLDivElement>(null);
  const [snsContainWidth, setSnsContainerWidth] = useState<number>(210);

  const pathsToIgnore = ["/", ROUTE_PATHS.PERFORMANCE_DASHBOARD];

  const { configuration } = useAppSelector((state) => state.boot);
  const { pageViewCounts: storedPageViewCounts } = useAppSelector((state) => state.loginLocalStorage);
  const dispatch = useAppDispatch();

  // set defaultPageViews views using configs from coreplus-config
  const getDefaultPageViews = () => {
    const defaultPageViews: any = {};
    const marketDefaultPageViews = configuration?.defaultPageViews || [];
    marketDefaultPageViews.forEach((page: string) => {
      defaultPageViews[ROUTE_PATHS[page as keyof typeof ROUTE_PATHS]] = 0;
    });
    return defaultPageViews;
  };

  const location = useLocation();

  const routingGroupsToTrack = [
    DROPDOWN_KEYS.PERFORMANCE,
    DROPDOWN_KEYS.EARLY_INCENTIVES,
    DROPDOWN_KEYS.MY_GROUP,
    DROPDOWN_KEYS.LEADER_GROWTH,
  ];

  const pages = routingGroupsToTrack
    .flatMap((group) => returnRoutesByKey(group, menuConfig))
    .filter((page) => !pathsToIgnore.includes(page.path));

  const paths = (path: string) => pages.find((page) => page.path === path);

  /**
   * Removed inactive pages from localeStorage pageViewCounts and returns filteredObject
   *
   * This happens when testing multiple ABOs or when an ABO no longer has access to a page
   * but that page route is saved in localStorage
   */
  const returnFilteredLocalStorage = () => {
    const localPageViewCounts = JSON.parse(JSON.stringify(storedPageViewCounts));
    Object.keys(localPageViewCounts).forEach((localPage) => {
      const foundPage = pages.some((activePage) => activePage.path === localPage);
      if (!foundPage) delete localPageViewCounts[localPage];
    });

    dispatch(setLocalStorageItem({ pageViewCounts: localPageViewCounts }));
    return localPageViewCounts;
  };

  /**
   * On initial component or window resize load set current size of smart next steps container
   */
  useEffect(() => {
    if (smartNextStepsRef.current) {
      const snsContainer = smartNextStepsRef.current.clientWidth;
      setSnsContainerWidth(snsContainer);
    }
  });

  /**
   * Checks if localStorage.pageViewCounts has existing saved page views. If so,
   * use the localStorage values to populate the state, otherwise use default values
   * @returns `PageViewCounts` from localStorage or default values
   * */
  const getInitialPageViewCounts = (): PageViewCounts => {
    const defaultPageViews = getDefaultPageViews();
    const storedPageViews = { ...returnFilteredLocalStorage() };
    const pageViewCounts: PageViewCounts = storedPageViews || defaultPageViews;

    // if localStorage.pageViewCounts has less than 3 pages,
    // let's start adding pages from defaultPageViews until we reach 3
    if (storedPageViews && Object.keys(storedPageViews).length < 3) {
      for (const page in defaultPageViews) {
        if (!storedPageViews[page] && Object.keys(storedPageViews).length < 3) {
          storedPageViews[page] = 0;
        }
      }
    }

    return pageViewCounts;
  };

  const getActiveSortedPages = (initialPages: PageViewCounts) => {
    const activeSortedPages = Object.keys(initialPages).filter((key) => !pathsToIgnore.includes(key));
    return activeSortedPages.slice(0, 3);
  };

  /**
   * Set the initial values for the smart next step links to the
   * first 3 pages from getInitialPageViewCounts
   * @returns `SmartNextStepsLinks`
   */
  const getInitialSmartNextSteps = (): SmartNextStepsLink => {
    const initialPages = getInitialPageViewCounts();
    return getActiveSortedPages(initialPages);
  };

  const [currentPath, setCurrentPath] = useState<string>("");
  const [pageViewCounts, setPageViewCounts] = useState<PageViewCounts>(getInitialPageViewCounts);
  const [smartNextSteps, setSmartNextSteps] = useState<SmartNextStepsLink>(getInitialSmartNextSteps);

  /**
   * When pageViewCounts are changed, save the updated values to `localStorage.pageViewCounts`
   */
  useEffect(() => {
    dispatch(setLocalStorageItem({ pageViewCounts: pageViewCounts }));
  }, [pageViewCounts]);

  /**
   * When menuConfig is changed based on latest config, save the updated values to `smartNextSteps`
   */
  useEffect(() => {
    setSmartNextSteps(getInitialSmartNextSteps);
  }, [menuConfig]);

  /**
   * When we navigate to a new page, check if the current page is valid and increment the page
   * view count.
   *
   * `useLayoutEffect` runs before the browser paints. This prevents the links
   * from flickering on component render and additional re-renders from state updates
   */
  useLayoutEffect(() => {
    // if we have navigated to a new page
    // only updates if the previousPath updated is different
    // will not add to page count if page is refreshed (routing to/from P2 pages)
    const previousPath = sessionStorage.getItem("previousPath");
    if (
      (location.pathname !== currentPath && location.pathname !== previousPath) ||
      pathsToIgnore.includes(previousPath || "")
    ) {
      const currentPath = location.pathname;
      sessionStorage.setItem("previousPath", currentPath);

      // if we are on a valid page, let's update view count
      if (paths(currentPath) !== undefined) {
        // increment the view count by 1 for the currentPath. If currentPath does not exist in
        // pageViewCounts, let's add it and set the view count to 1
        const updatedPageViewCounts = {
          ...pageViewCounts,
          [currentPath]: pageViewCounts[currentPath] ? pageViewCounts[currentPath] + 1 : 1,
        };

        // sort each entry (i.e. ['/los',  1 ] ) of `updatedPageViewCounts` in descending order by view count
        const sortedPageViews = Object.entries(updatedPageViewCounts).sort(
          (a: [string, number], b: [string, number]) => b[1] - a[1],
        );

        const topThreePages = getActiveSortedPages(updatedPageViewCounts);

        setCurrentPath(currentPath);
        setPageViewCounts(Object.fromEntries(sortedPageViews));
        setSmartNextSteps(topThreePages);
      }
    }
  }, [location.pathname]);

  const getLinkKey = (pathName: string) => {
    const currentPage = pages.find(
      (page) => page.path.replace(/.html/g, "") === pathName.replace(/.html/g, ""),
    ) as NavDropdownItem;
    return currentPage?.label;
  };

  const handleTealiumTrackClickAction = (link: string) => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.SMART_NEXT_STEPS,
      link,
      TealiumClickActionMapper[link],
    );
  };

  const handleClickEvent = (link: string) => {
    handleTealiumTrackClickAction(link);
    clickFunction();
    reloadForP2Components(location, link);
  };

  /**
   * Determines which links can fit within the parent container and hides the remaining links that do not fit
   */
  const displayLinksForContainerSize = () => {
    if (smartNextStepsRef.current) {
      const snsContainer = smartNextStepsRef.current;

      /** @returns width + margin of link */
      const getChildWidth = (childIndex: number) => snsContainer.children[childIndex].clientWidth + 28;

      let totalLinkWidth = 0;

      for (let index = 0; index < snsContainer?.children?.length; index++) {
        totalLinkWidth += getChildWidth(index);

        // hide current link if there is not enough room to display it
        if (totalLinkWidth > snsContainWidth) {
          snsContainer.children[index].className = "hidden";
        }
      }
    }
  };

  /**
   * if `restrictSize` flag is enabled, check if the links can fit within the current size of the parent container.
   *
   * this useEffect is triggered by these conditions:
   * 1. window is resized
   * 2. parent container size is changed
   * 3. top 3 smart next step links are updated
   */
  useEffect(() => {
    if (restrictSize) {
      displayLinksForContainerSize();
    }
  });

  const SmartNextStepsLinks = () => {
    const { t } = useTranslation(["smartNextSteps"]);
    const links = smartNextSteps.map((link) => {
      const key = getLinkKey(link);
      return (
        key && (
          <Link
            key={link}
            className="smart-next-steps__link"
            to={link}
            onClick={() => handleClickEvent(link)}
            state={{ titleKey: key }}
          >
            <span className="smart-next-steps__link-title">{t(key, defaultContent[key])}</span>
            <span className="smart-next-steps__link-arrow">
              <IconArrowLink />
            </span>
          </Link>
        )
      );
    });

    return <>{links}</>;
  };

  return (
    <div className="smart-next-steps" id="smart-next-steps" ref={smartNextStepsRef}>
      <SmartNextStepsLinks />
    </div>
  );
};
