const content: { [key: string]: string } = {
  pageTitle: "Annual Performance",
  annualDashboard: "Annual Dashboard",
  modalTitle: "What does this mean?",
  spMonthsSubTitle: "SP Months (Silver Producer Months)",
  spMonthsContent: "Number of months Core Plan Qualified within the performance year.",
  pySubTitle: "PY (Performance Year)",
  pyContent:
    "The period of September 1st to August 31st. The PY is the ABO fiscal year. Annual metrics, incentives, and totals use this period. e.g. the start of PY<1>{shortPY}</1> is September 1, <2>{pyStartYear}</2>.",
  pqSubTitle: "PQ (Personal Qualification)",
  pqContent: "Personal Q (PQ) months are earned by reaching the Ruby Volume target in each month.",
  baselineSubTitle: "Baseline",
  baselineContent:
    "Total FQs/PQs earned in the baseline period, typically one Performance Year or the average of two previous Performance Years.",
  fqSubTitle: "FQ (Frontline Qualification)",
  fqContent:
    "Frontline Q (FQ) months are earned for each Core Plan Qualified month achieved by a directly sponsored leg (Frontline). Months considered qualified through VE are included in FQ counts.",
  hatTileTitle: "HAT Legs Tracking",
  hatInMarket: "In-Market",
  hatInternationalLegs: "International",
  hatTotalLegs: "Total",
  pinTrackingQualifiedLegsAward: "Qualified Legs Award Level",
  pinTrackingGlobalAwardRecognition: "Global Award Tracking Level",
  pinTrackingCurrentPin: "Current Pin",
  pinTrackingHighestPin: "Highest Pin",
  pinTrackingFpStatus: "Founders Platinum Status",
  garTileTitle: "GAR Tracking",
  lastMonthProcessed: "Last Month Processed",
  foundersDiamondStatus: "F. Diamond Status",
  foundersPlatinumLegs: "F. Plat Legs",
  qualificationCredits: "Qualification Credits",
  annualIncentiveTracking: "Annual Incentive Tracking",
  foundersAchievementAwardTracking: "Founders Achievement Award Tracking",
  faaTracking: "FAA Tracking",
  dbrStatus: "DBR Status",
  fPlatLegs: "F. Plat Legs",
  faaPoints: "FAA Points",
  faaMultiplier: "FAA Multiplier",
  trackingBonus: "Tracking Bonus",
  trackingPinTitle: "Tracking Pin Information",
  annualIncentiveTrackingTitle: "Annual Incentive Tracking",
  trackingSubtitle: "Tracking",
  trackingBonusSubtitle: "Tracking Bonus",
  pqsLabel: "PQs",
  fqsLabel: "FQs",
};

export default content;
