import { useTranslation } from "react-i18next";
import { storedLocale } from "../../../components/impersonation/util";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../icons/IconShield/IconShield";
import "./LeafBadgeGrid.scss";
import { QUALIFICATION_STATUS } from "../../enums/incentive-enums";
import defaultContent from "../../../content/common";
import { getShortMonth, getYear } from "../../util/period";

export type BonusHist = {
  period: number;
  status: QUALIFICATION_STATUS;
};

export const LeafBadgeGrid = ({ history }: { history: BonusHist[] }) => {
  const { t } = useTranslation(["common"]);
  const locale = storedLocale();

  const splitHistoryByYear: { [key: string]: any } = {};
  for (const month of history) {
    const year = month.period.toString().slice(0, 4);
    splitHistoryByYear[year] = splitHistoryByYear[year] ? [...splitHistoryByYear[year], month] : [month];
  }

  const years = Object.keys(splitHistoryByYear);

  const getBadgeType = ({ status }: BonusHist) => {
    switch (status) {
      case QUALIFICATION_STATUS.QUALIFIED:
      case QUALIFICATION_STATUS.TRACKING:
        return BADGE_TYPE.EARNED;
      case QUALIFICATION_STATUS.NOT_QUALIFIED:
      case QUALIFICATION_STATUS.HELD:
      case QUALIFICATION_STATUS.DENIED:
        return BADGE_TYPE.NOT_EARNED;
      case QUALIFICATION_STATUS.OPEN:
        return BADGE_TYPE.POTENTIAL;
      default:
        return BADGE_TYPE.UNAVAILABLE;
    }
  };

  const BuildIcons = ({ history }: { history: BonusHist[] }) => {
    return (
      <>
        <p className="badge-grid__section-title">{getYear(history[0].period.toString(), locale)}</p>
        {history.map((month) => {
          return (
            <div className="badge-grid__shield" key={month.period} data-testid={`badge-icon-${getBadgeType(month)}`}>
              <IconShield type={getBadgeType(month)} size={BADGE_SIZE.LARGE} />
              <p className="badge-grid__shield-month">{getShortMonth(month.period.toString(), locale)}</p>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div data-testid="badge-grid" className="badge-grid">
      <div className="badge-grid__badge-group">
        {years.map((year) => (
          <BuildIcons history={splitHistoryByYear[year]} key={year} />
        ))}
      </div>
      <div className="badge-legend">
        {[BADGE_TYPE.EARNED, BADGE_TYPE.POTENTIAL, BADGE_TYPE.NOT_EARNED, BADGE_TYPE.UNAVAILABLE].map((type) => {
          return (
            <div key={type}>
              <IconShield type={type} />
              <span className="badge-legend__label">{`${t(type, defaultContent[type])}`}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
