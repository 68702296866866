import "./LeafLineChart.scss";
import {
  Chart,
  ChartAxis,
  ChartBar,
  ChartGroup,
  ChartLine,
  ChartTooltip,
  ChartVoronoiContainer,
  getResizeObserver,
} from "@patternfly/react-charts";
import { useEffect, useRef, useState } from "react";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafLineChartBackground from "./LeafLineChartBackground/LeafLineChartBackground";

type CommonProps<O, P> = {
  dataset?: O[];
  series: keyof O;
  x: keyof P;
  y: keyof P;
  formatX: (t: string | number, isMobile: boolean) => string;
  formatY: (t: string | number, isMobile: boolean) => string;
  isFetching: boolean;
  show?: boolean;
};

type PropsWithTooltip<O, P> = CommonProps<O, P> & {
  showTooltips: true;
  FlyoutComponent: JSX.Element;
};

type PropsWithoutTooltip<O, P> = CommonProps<O, P> & {
  showTooltips: false;
  FlyoutComponent?: JSX.Element;
};

type PropsWithStaticTheme<O, P> = CommonProps<O, P> & {
  staticTheme: string;
  themes?: never;
  colorCode?: never;
};

type PropsWithDynamicTheme<O, P> = CommonProps<O, P> & {
  staticTheme?: never;
  themes: {
    readonly [key: string]: string;
  };
  colorCode: keyof O;
};

type Props<O, P> =
  | (PropsWithTooltip<O, P> & PropsWithStaticTheme<O, P>)
  | (PropsWithoutTooltip<O, P> & PropsWithStaticTheme<O, P>)
  | (PropsWithTooltip<O, P> & PropsWithDynamicTheme<O, P>)
  | (PropsWithoutTooltip<O, P> & PropsWithDynamicTheme<O, P>);

const LeafLineChart = <O, P extends object>(props: Props<O, P>) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);

  const {
    dataset = [],
    series,
    x,
    y,
    colorCode,
    formatX,
    formatY,
    themes,
    staticTheme,
    showTooltips,
    isFetching,
    FlyoutComponent,
    show = true,
  } = props;

  const handleResize = () => {
    if (chartRef.current && chartRef.current.clientWidth) {
      setChartWidth(chartRef.current.clientWidth);
    }
  };

  useEffect(() => {
    if (chartRef.current && chartRef.current.clientWidth) {
      setChartWidth(chartRef.current.clientWidth);
      getResizeObserver(chartRef.current, handleResize);
    }
  }, [chartRef, isFetching]);

  const fromDesktop = 1086;
  const fromTab = 768;
  const isMobile = chartWidth <= fromTab;

  const allY = dataset
    .map((data: O) => data[series] as [])
    .flat()
    .map((data: P) => data[y] as number);

  const maxValue = allY.length > 0 ? Math.max(...allY) : 0;

  const firstSeries = dataset.length > 0 ? (dataset[0][series] as []) : [];
  const barChartData = firstSeries.map((sData: P) => ({
    x: sData[x],
    y: maxValue,
  }));

  const _formatX = (t: string | number) => {
    return formatX(t, isMobile);
  };

  const _formatY = (t: string | number) => {
    return formatY(t, isMobile);
  };

  const getTooltips = () => {
    return (
      <ChartVoronoiContainer
        labelComponent={
          <ChartTooltip
            style={{
              display: "none",
            }}
            flyoutComponent={FlyoutComponent}
          />
        }
        labels={({ datum }) => `${datum.y}`}
        constrainToVisibleArea
        voronoiBlacklist={["leafLineChartBackground"]}
      />
    );
  };

  const getMediaQuery = (element: string): any => {
    const chartPadding = {
      desktop: { right: 200, left: 200, bottom: 50, top: 50 },
      tab: { right: 70, left: 100, bottom: 50, top: 50 },
      mobile: { right: 30, left: 80, bottom: 25, top: 16 },
    };

    const domainPadding = {
      desktop: { y: 30, x: 30 },
      tab: { y: 20, x: 20 },
      mobile: { y: 20, x: 10 },
    };

    switch (element) {
      case "padding":
        return chartWidth > fromDesktop
          ? chartPadding.desktop
          : chartWidth > fromTab
          ? chartPadding.tab
          : chartPadding.mobile;
      case "domainPadding":
        return chartWidth > fromDesktop
          ? domainPadding.desktop
          : chartWidth > fromTab
          ? domainPadding.tab
          : domainPadding.mobile;
      case "axisLabelXStyle":
        return {
          tickLabels: { fill: "#949494", fontSize: chartWidth > fromDesktop ? 16 : chartWidth > fromTab ? 14 : 12 },
          axis: { display: "none" },
        };
      case "axisLabelYStyle":
        return {
          tickLabels: { fill: "#949494", fontSize: chartWidth > fromDesktop ? 16 : chartWidth > fromTab ? 14 : 12 },
          grid: { fill: "#E4E4E4", height: 1 },
        };
      case "height":
        return chartWidth > fromDesktop ? 494 : chartWidth > fromTab ? 334 : 166;
      case "showGrid":
        return chartWidth > fromDesktop ? true : false;
      case "barWidth":
        return chartWidth / 25;
      case "strokeWidth":
        return chartWidth > fromDesktop ? 4 : chartWidth > fromTab ? 3 : 2.5;
      default:
        return {};
    }
  };

  if (show === false) {
    return <></>;
  }

  if (isFetching === true) {
    return (
      <div className="leaf-line-chart leaf-line-chart__container leaf-line-chart__loader">
        <LeafRectangleLoader isLoading={true} width={"100%"} height={"100%"} />
      </div>
    );
  }

  return (
    <div className={"leaf-line-chart"}>
      <div ref={chartRef} className={"leaf-line-chart__container"}>
        <Chart
          minDomain={{ y: 0 }}
          {...(showTooltips ? { containerComponent: getTooltips() } : {})}
          height={getMediaQuery("height")}
          width={chartWidth}
          domainPadding={getMediaQuery("domainPadding")}
          padding={getMediaQuery("padding")}
        >
          <LeafLineChartBackground chartWidth={chartWidth} fromDesktop={fromDesktop} fromTab={fromTab} />
          <ChartBar
            name={"leafLineChartBackground"}
            data={barChartData}
            barWidth={getMediaQuery("barWidth")}
            style={{ data: { fill: "url(#leafLineChartBackground)" } }}
          />
          <ChartAxis style={getMediaQuery("axisLabelYStyle")} tickFormat={_formatX} />
          <ChartAxis
            style={getMediaQuery("axisLabelXStyle")}
            tickFormat={_formatY}
            dependentAxis
            showGrid={getMediaQuery("showGrid")}
          />
          <ChartGroup>
            {dataset.map((data: O, index) => {
              const seriesData = data[series] as [];
              const themeKey = colorCode ? (data[colorCode] as string) : "";
              const colorScale = themes ? themes[themeKey] : staticTheme;

              return (
                <ChartLine
                  key={index}
                  interpolation="catmullRom"
                  data={seriesData.map((sData: P) => ({
                    x: sData[x],
                    y: sData[y],
                    sData: {
                      ...sData,
                      themeKey,
                    },
                  }))}
                  colorScale={[colorScale]}
                  style={{ data: { strokeWidth: getMediaQuery("strokeWidth") } }}
                />
              );
            })}
          </ChartGroup>
        </Chart>
      </div>
    </div>
  );
};

export default LeafLineChart;
