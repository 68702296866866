import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { hideApplicant } from "../../../reducers/profile";
import defaultContent from "../../../content/customerApplicantSlider";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import CustomerApplicantBody from "./CustomerApplicantBody";

const CustomerApplicantSlider = () => {
  const { t } = useTranslation(["customerApplicantSlider", "common"]);

  const dispatch = useAppDispatch();

  const closeApplicant = () => {
    dispatch(hideApplicant());
  };

  const isShowApplicant = useAppSelector((state) => state.profile.isShowApplicant);

  return (
    <>
      <LeafPanel
        title={t("allApplicant", defaultContent["allApplicant"])}
        show={isShowApplicant}
        onCloseClick={closeApplicant}
        id={"applicant-slider"}
        showBackdrop={false}
        lockBackgroundScrolling={false}
      >
        <CustomerApplicantBody isShowApplicant={isShowApplicant} />
      </LeafPanel>
    </>
  );
};

export default CustomerApplicantSlider;
