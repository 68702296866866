import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-async";
import { AWARD_CODES } from "../../common/enums/award-codes";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import defaultContent from "../../content/faa";
import { RegulationInfoBanner } from "../../components/RegulationInfoBanner/RegulationInfoBanner";

const loadComponent = async () => {
  return import("../Lit/acc-wc-hat-faa/acc-faa-summary.js");
};

export default function FAA() {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { eligibleIncentives } = useAppSelector((state) => state.boot.shellConfig);
  const { t } = useTranslation(["faa"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    const faaAccess = eligibleIncentives.some(
      (incentive: { awardNumber: any }) => incentive.awardNumber === AWARD_CODES.FAA,
    );
    if (faaAccess === false) {
      navigate("/404");
    } else {
      dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
    }
  }, [dispatch, eligibleIncentives, navigate]);

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <RegulationInfoBanner />
      <h1 className="faa_header" id="faa_header">
        {t("pageTitle", defaultContent["pageTitle"])}
      </h1>
      <acc-faa-summary
        id="acc_faa_summary"
        mode="standalone"
        period={selectedPeriod}
        locale={storedLocale()}
        moduleId={"CPP"}
      ></acc-faa-summary>
    </main>
  );
}
