import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import rootReducer from "../reducers";
import { corePlusIncentivesApi } from "../services/corePlusIncentivesApi";
import { accAPI } from "../services/index";
import { foundersPlatinumProgressAPI } from "../services/foundersPlatinumProgressAPI";
import { businessSelectorApi } from "../services/businessSelectorAPI";
import { shellConfigApi } from "../services/getShellConfigAPI";
import { monthlyPerformanceDashboardAPI } from "../services/monthyPerformanceDashboardAPI";
import { savedKpiPreferencesAPI } from "../services/savedKpiPreferencesAPI";
import { hatSummaryAPI } from "../services/hatSummaryAPI";
import { frontlineGroupGrowthAPI } from "../services/frontlineGroupGrowthAPI";
import { annualPggAPI } from "../services/annualPggAPI";
import { ssiAPI } from "../services/ssiAPI";
import { personalGroupGrowthAPI } from "../services/personalGroupGrowthAPI";
import { pggGcrAPI } from "../services/personalGroupGrowthGCR";
import { annualPerformanceDashboardAPI } from "./../services/annualPerformanceDashboardAPI";
import { monthlyPGGAPI } from "./../services/monthlyPGGAPI";

// https://stackoverflow.com/questions/66315413/type-definitions-for-redux-toolkit-store-with-preloadedstate
export const setupStore = (preloadedState?: PreloadedState<any>) =>
  configureStore({
    reducer: rootReducer,
    ...preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(corePlusIncentivesApi.middleware)
        .concat(accAPI.middleware)
        .concat(foundersPlatinumProgressAPI.middleware)
        .concat(hatSummaryAPI.middleware)
        .concat(businessSelectorApi.middleware)
        .concat(shellConfigApi.middleware)
        .concat(monthlyPerformanceDashboardAPI.middleware)
        .concat(savedKpiPreferencesAPI.middleware)
        .concat(annualPerformanceDashboardAPI.middleware)
        .concat(frontlineGroupGrowthAPI.middleware)
        .concat(annualPggAPI.middleware)
        .concat(personalGroupGrowthAPI.middleware)
        .concat(pggGcrAPI.middleware)
        .concat(ssiAPI.middleware)
        .concat(monthlyPGGAPI.middleware),
  });

export type Store = ReturnType<typeof setupStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// setup store
export const store = setupStore();

// persist redux store so that the state gets rehydrated on page refresh
export const persistor = persistStore(store);
