import "./IconDelete.scss";
import { ReactComponent as DeleteIcon } from "./svg/icon_delete.svg";
type Props = {
  backgroundClass?: string;
};

export const IconDelete = ({ backgroundClass = "icon-delete" }: Props) => {
  return (
    <span className={`icon-delete ${backgroundClass}`}>
      <DeleteIcon />
    </span>
  );
};
