import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/bfiRequirements";
import { VolumeRequirementTile } from "../../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: number;
  target: number;
  short: number;
  exempt: boolean;
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementPersonalPV = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);

  const { met = false, required = false, actual = 0, target = 0, exempt = false, isFetching } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VolumeRequirementTile
      title={`${t("ppvTitle", defaultContent["ppvTitle"], {
        PPV: target,
      })}`}
      met={met}
      isLoading={isFetching}
      currentAmount={actual}
      targetAmount={target}
      circleCheckmarkId={"volumeRequirementTile__ppv"}
      volumeType={"personal"}
      exempt={exempt}
    />
  );
};

export default BronzeRequirementPersonalPV;
