import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/annualGcrPGG";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";

const loadComponent = async () => {
  import("../../Lit/acc-coreplus-incentives/acc-personal-growth-gcr.js");
};

export default function GCRPGG() {
  const { t } = useTranslation(["annualGcrPGG"]);

  const { selectedPeriod } = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState(selectedPeriod);
  const { isPending } = useAsync({ promiseFn: loadComponent });

  // checking if the selectedPeriod changes as it triggering the component.
  // To avoid multiple calls to the component and multiple rendering, added a useffect to check for change in selectedPeriod
  useEffect(() => {
    setPeriod(selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <h1 className="acc_gcrpgg_header" id="acc_gcrpgg_header">
        {t(
          "pageTitle.personalGroupGrowthIncentive",
          defaultContent["pageTitle"]["personalGroupGrowthIncentive"],
        ).toString()}
      </h1>
      <acc-personal-growth-gcr
        learn-more-link=""
        id="acc_gcrpgg_component"
        period={period}
        locale={storedLocale()}
        mode="standalone"
      ></acc-personal-growth-gcr>
    </main>
  );
}
