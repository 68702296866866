import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

export const recentOrdersViewAllClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CML_OVERVIEW,
    "",
    "recent_orders_view_all",
  );
};

export const recentOrderErrorTrack = (period: string) => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, `overview_recent_orders_error:${period}`, "");
};
