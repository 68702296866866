import {
  FOSTER_SPONSOR,
  INTERNATIONAL_SPONSOR,
  PLATINUM_AND_SPONSOR,
  PLATINUM_FOSTER_SPONSOR,
  PLATINUM_SPONSOR,
  SPONSOR,
} from "../../../../constants/sponsor-constants";
import LeafABOName from "../../LeafABOName/LeafABOName";
import LeafSponsorTypeLabel from "../LeafSponsorTypeLabel/LeafSponsorTypeLabel";
import { useGetSponsorInfoQuery } from "../../../../../services/profileAPI";
import { useAppSelector } from "../../../../../store";
import LeafABOPhoneNumber from "../../LeafABOPhoneNumber/LeafABOPhoneNumber";
import LeafABOEmail from "../../LeafABOEmail/LeafABOEmail";
import LeafABOAddress from "../../LeafABOAddress/LeafABOAddress";
import "./LeafSponsorDetail.scss";
import LeafProfileLoader from "../../LeafProfileLoader/LeafProfileLoader";
import { cardActionClickTrack } from "../../../../tealium/ABOProfile/Profile/ProfileTabViewsTrack/SponsorTabTrack";

type Props = {
  sponsor: {
    aboNo: string;
    affNo: string;
    isValid: boolean;
    isPlatinum?: boolean;
  };
  show: boolean;
  sponsorType:
    | typeof SPONSOR
    | typeof PLATINUM_SPONSOR
    | typeof PLATINUM_AND_SPONSOR
    | typeof INTERNATIONAL_SPONSOR
    | typeof FOSTER_SPONSOR
    | typeof PLATINUM_FOSTER_SPONSOR;
};

const LeafSponsorDetail = (props: Props) => {
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const { sponsor, show, sponsorType } = props;
  const { aboNo, affNo } = sponsor;

  const { isFetching, data } = useGetSponsorInfoQuery({ period: selectedPeriod, affAbo: `${affNo}-${aboNo}` });

  const applicantTrackOnclick = (clickAction: string) => {
    cardActionClickTrack(clickAction, sponsorType);
  };

  if (show === false) {
    return <></>;
  }

  if (isFetching === true) {
    return <LeafProfileLoader row={1} applicant={true} isFetching={isFetching} />;
  }

  if (data?.aboInfo) {
    const { aboInfo } = data;
    const { name, localName, privacyFlag, isoLanguageCode, phoneNumbers, emailAddresses, addresses } = aboInfo;
    const primaryNumber = phoneNumbers[0]?.numbers[0]?.displayNumber || "";
    const email = emailAddresses[0]?.addresses[0]?.address || "";
    const address = addresses[0] || {};

    const sponsorName = name || localName;
    // Sponsor name is possible to be empty or -- due to insufficient permission to access the data hence will take it as confidentail user
    const isSponsorNameEmpty = !sponsorName || sponsorName === "--";
    const isPrivate = privacyFlag === true || isSponsorNameEmpty;

    return (
      <div className="leaf-sponsor-detail">
        <div className="leaf-sponsor-detail__header">
          <div className="leaf-sponsor-detail__header--details">
            <LeafABOName name={name} localName={localName} privacyFlag={isPrivate} languageCode={isoLanguageCode} />
            <div className="leaf-sponsor-detail__header--abo-number">{aboNo}</div>
          </div>
          <div data-testid="leafSponsorDetail-sponsor-type">
            <LeafSponsorTypeLabel sponsorType={sponsorType} />
          </div>
        </div>
        <div className="leaf-sponsor-detail__body">
          <LeafABOPhoneNumber
            primaryNumber={primaryNumber}
            privacyFlag={privacyFlag}
            applicantTrackOnclick={applicantTrackOnclick}
          />
          <LeafABOEmail email={email} privacyFlag={privacyFlag} applicantTrackOnclick={applicantTrackOnclick} />
          <LeafABOAddress
            addresses={{
              addressLines: address.addressLines,
              municipality: address.municipality,
              region: address.region,
              postalCode: address.postalCode,
              country: address.country,
              type: address.type,
            }}
            hideCopyIcon={true}
            privacyFlag={privacyFlag}
            applicantTrackOnclick={applicantTrackOnclick}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default LeafSponsorDetail;
