import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, Suspense, useState } from "react";
import LeafTabNavLink from "../../common/leaf/LeafTabNavLink/LeafTabNavLink";
import defaultContent from "../../content/customers";
import { CUSTOMER_OVERVIEW, CUSTOMER_LIST, CUSTOMER_ORDER } from "../../routing/CustomersRouting";
import "./Customers.scss";
import { tabMenuClickTrack } from "../../common/tealium/Customers/TabMenuTrack";
import { useAppDispatch } from "../../store";
import { updateSelectorType, SELECTOR_TYPE } from "../../reducers/period";
import { fetchContent, storedLocale } from "../impersonation/util";
import { storedIsoCountry } from "../../common/util/config";
import CustomerListExportNotify from "../CustomerExport/CustomerExportNotifyContainer/CustomerListExportNotify/CustomerListExportNotify";
import OrderHistoryExportNotify from "../CustomerExport/CustomerExportNotifyContainer/OrderHistoryExportNotify/OrderHistoryExportNotify";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

const Customers = () => {
  const { t } = useTranslation(["customers"]);
  const dispatch = useAppDispatch();
  const isCountryCode = storedIsoCountry();
  const locale = storedLocale();
  const pageId = "cml";
  const [disclaimer, setDisclaimer] = useState("");

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));

    // Get cml content
    fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
      setDisclaimer(content.footerDisclaimerMD);
    });
  }, [dispatch, locale, isCountryCode]);

  // const Timestamp = (
  //   <LeafDateTime
  //     date={new Date().toISOString()}
  //     options={{
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       timeZoneName: "short",
  //       month: "2-digit",
  //       day: "2-digit",
  //       year: "numeric",
  //     }}
  //   />
  // );

  const navLinkOnClick = (to: string, text: string) => {
    tabMenuClickTrack(to);
  };

  const RouterLoader = () => (
    <div className="router-loader">
      <div className="router-loader-header">
        <LeafRectangleLoader isLoading={true} numLines={1} width="95%" height="3rem" />
      </div>
      <div className="router-loader-top">
        <LeafRectangleLoader isLoading={true} numLines={1} width="95%" height="3rem" />
      </div>
      <div className="router-loader-bottom">
        <LeafRectangleLoader isLoading={true} numLines={1} width="95%" height="8rem" />
      </div>
    </div>
  );

  return (
    <div className="cml">
      <CustomerListExportNotify />
      <OrderHistoryExportNotify />
      <div className="cml__header">
        <h1 className="cml__title">{t("pageTitle", defaultContent["pageTitle"])}</h1>
        <div className="cml__date cml__date--header">
          <span className="cml__date--timestamp">
            <TimeStamp dateFormat="long" />
          </span>
        </div>
      </div>
      <div className="cml__scroll">
        <div className="cml__nav">
          <LeafTabNavLink
            tabs={[
              { id: "overview", to: CUSTOMER_OVERVIEW, text: t("overview", defaultContent["overview"]) },
              { id: "list", to: CUSTOMER_LIST, text: t("customerList", defaultContent["customerList"]) },
              { id: "order", to: CUSTOMER_ORDER, text: t("orderHistory", defaultContent["orderHistory"]) },
            ]}
            navLinkOnClick={navLinkOnClick}
          />
        </div>
      </div>
      <div className="cml__container">
        <Suspense fallback={<RouterLoader />}>
          <Outlet />
        </Suspense>
      </div>
      <div className="cml__date cml__date--footer">
        <span className="cml__date--timestamp">
          <TimeStamp dateFormat="short" />
        </span>
      </div>
      <div>{disclaimer && <div className="footnote" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>}</div>
    </div>
  );
};

export default Customers;
