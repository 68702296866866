import "./IconCarrot.scss";

export const IconCarrot = ({
  orientation = "left",
  disabled = false,
  color = "white",
}: {
  orientation: "left" | "right" | "up" | "down";
  color: "white" | "black";
  disabled?: boolean;
}) => {
  let classname = `icon-carrot__${orientation} icon-carrot__${color}`;
  classname += disabled ? " icon-carrot--disabled" : "";
  return (
    <svg className={classname} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.05469 5.8125C0.808594 6.05859 0.808594 6.46875 1.05469 6.71484L6.35938 12.0469C6.63281 12.293 7.04297 12.293 7.28906 12.0469L7.91797 11.418C8.16406 11.1719 8.16406 10.7617 7.91797 10.4883L3.70703 6.25L7.91797 2.03906C8.16406 1.76562 8.16406 1.35547 7.91797 1.10938L7.28906 0.480469C7.04297 0.234375 6.63281 0.234375 6.35938 0.480469L1.05469 5.8125Z"
        fill="white"
      />
    </svg>
  );
};
