import LeafIncentiveEligibilityBanner from "../../../../common/leaf/LeafIncentiveEligibilityBanner/LeafIncentiveEligibilityBanner";
import { showSlider } from "../../../../reducers/bronzeIncentive";
import { useAppDispatch } from "../../../../store";
import { eligibilityAPIResponse } from "./MockData";

type Props = {
  awardCode: number;
};

const BFIEligibilityBannerTile = (props: Props) => {
  const { awardCode } = props;
  const dispatch = useAppDispatch();

  // todo: API integration - replace with RTK query
  const { eligible, graduated, remainingIncentiveEarnings, endPeriod } = eligibilityAPIResponse;

  const eligibleButtonOnClick = () => {
    dispatch(showSlider(awardCode));
  };

  return (
    <LeafIncentiveEligibilityBanner
      module={"bfi"}
      graduated={graduated}
      eligible={eligible}
      remainingIncentiveEarnings={remainingIncentiveEarnings}
      endPeriod={`${endPeriod}`}
      eligibleButtonOnClick={eligibleButtonOnClick}
    />
  );
};
export default BFIEligibilityBannerTile;
