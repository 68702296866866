import "./LeafRectangleLoader.scss";
import React, { ReactNode } from "react";

type Props = {
  isLoading: boolean;
  width: string;
  height: string;
  margin?: string;
  numLines?: number;
  View?: React.ElementType;
  children?: ReactNode;
};

const LeafRectangleLoader = (props: Props) => {
  const { isLoading, width, height, margin, numLines = 1, View, children } = props;

  const LeafRectangleLoaderView = () => {
    const renderRectangleLoaders = [];
    for (let i = 1; i <= numLines; i++) {
      renderRectangleLoaders.push(
        <div
          className="rectangle-loader"
          key={`rectangle-loader-${i}`}
          style={{ width, height, margin }}
          data-testid={`leafRectangleLoader-${i}`}
        ></div>,
      );
    }
    return <>{renderRectangleLoaders}</>;
  };

  return <>{isLoading ? <LeafRectangleLoaderView /> : (View && <View />) || children}</>;
};

export default LeafRectangleLoader;
