import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";
import { FilterOption } from "../../../reducers/model/filters";

const actionMapper: { [key: string]: string } = {
  all: "filter_all",
  "tags-close": "",
  "tags-new": "",
  "tags-inProgress": "",
  "tags-achieved": "",
  "tags-restarted": "",
  "requirements-ppv": "",
  "requirements-performanceBonus": "",
  "requirements-threeLegs6": "",
  "requirements-vcsPv": "",
  "requirements-twoLegs": "",
  "requirements-vcs": "",
  "eligibility-preWindow": "",
  "eligibility-inWindow": "",
  "incentive-bronzeFoundation": "",
  "incentive-bronzeBuilder": "",
};

const filterTrack = (filterMenuOption: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    filterMenuOption,
  );
};

export const filterMenuClickTrack = (filters: FilterOption[]) => {
  const allUnchecked = filters.every(({ checked }) => checked === false);

  //system behavior if non of the filters is checked, it would be default to All
  if (allUnchecked) {
    return filterTrack(actionMapper.all);
  }

  filters.forEach(({ checked, id }) => {
    if (checked === true) {
      filterTrack(actionMapper[id]);
    }
  });
};

export const applyFilterClickTrack = () => {
  filterTrack("apply_filters");
};

export const cancelFilterClickTrack = () => {
  filterTrack("cancel_filter");
};

export const openFilterClickTrack = () => {
  filterTrack("open_filter_menu");
};

export const clearAllFilterClickTrack = () => {
  filterTrack("clear_all_filter");
};
