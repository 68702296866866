import { FilterGroup, getFilterQueryParams } from "../filters";

export type CustomerListFilterQueryParams = {
  isNew?: boolean;
  isVerified?: boolean;
  businessNature?: string;
};

export type CustomerListQueryParams = {
  pageNum: number | null;
  pageSize: number;
  period: string;
  sortValue: string;
  sortOrder: string;
  searchBy?: string;
  locale?: string;
  customerIds?: string;
  reducerName?: string;
  confidentialUserText?: string;
  businessNature?: string;
} & CustomerListFilterQueryParams;

export type CustomerListHeader = {
  authorization: string;
};

export type CustomerDetail = {
  affNo: string;
  aboNo: string;
  affAbo: string;
  joinedDate: string;
  isNew: boolean;
  isNewInCurrentMonth: boolean;
  isNewMigrated: boolean;
  isCustomer: boolean;
  isMember: boolean;
  name: string;
  localName: string;
  isoLanguageCode: string;
  privacyFlag: boolean;
  businessNatureCode: number;
  verifiedCustomerFlag: boolean;
  businessNature: string;
  lastOrderDate: string;
  lastReturnDate: string;
  totalCustomerPV: number;
  totalCustomerOrder: number;
  expiryDate: string;
  totalVCSPV: number;
  registrationChannelCd: string;
};

export type CustomersResponse = {
  aff: number;
  abo: number;
  period: number;
  pageNum: number;
  nextPageNum: number | null;
  pageSize: number;
  pageLimit: number;
  sumOfTotalOrder: number;
  totalCustomerCount: number;
  newCustomerCount: number;
  sumOfTotalPv: number;
  customerList: CustomerDetail[];
  totalGuestTypeCustomer: number;
  totalMemberTypeCustomer: number;
  totalShareBarTypeCustomer: number;
};

interface CustomerList extends CustomersResponse {
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  refreshFilterTime: number;
  requestId?: string;
}

export const initialState: CustomerList = {
  aff: 0,
  abo: 0,
  period: 0,
  pageNum: 0,
  nextPageNum: 1,
  pageSize: 5,
  pageLimit: 0,
  sumOfTotalOrder: 0,
  totalCustomerCount: 0,
  sumOfTotalPv: 0,
  customerList: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  refreshFilterTime: new Date().getTime(),
  requestId: "",
  totalGuestTypeCustomer: 0,
  totalMemberTypeCustomer: 0,
  totalShareBarTypeCustomer: 0,
  newCustomerCount: 0,
};

export const resetList = (requestId?: string): CustomerList => ({
  ...initialState,
  requestId,
  refreshFilterTime: new Date().getTime(),
});

export const includeGuestCustomerBN = (enableGuestCustomer: boolean) => {
  return enableGuestCustomer === true ? { businessNature: "3,4,17" } : {};
};

export const getDefaultBusinessNatureQueryParam = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): CustomerListFilterQueryParams => {
  const [{ queryParamValue }] = filterGroups.filter(({ group }) => group === "businessNature");
  return queryParamValue === void 0 ? includeGuestCustomerBN(enableGuestCustomer) : {};
};

export const getCustomerFilterQueryParams = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): CustomerListFilterQueryParams => {
  const filterQueryParams = getFilterQueryParams<CustomerListFilterQueryParams>(filterGroups);
  const businessNatureQueryParams = getDefaultBusinessNatureQueryParam(filterGroups, enableGuestCustomer);
  return { ...filterQueryParams, ...businessNatureQueryParams };
};
