import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/l10n";
import defaultContent from "../../../../content/bfiConsistencyPaymentMetric";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconAdd } from "../../../../common/icons/IconAdd/IconAdd";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import "./BFIConsistencyPaymentMetric.scss";
import {
  useGetBronzeConsistencyPaymentQuery,
  useGetBronzeEarningQuery,
} from "../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { IconSchedule } from "../../../../common/icons/IconSchedule/IconSchedule";

type Props = {
  awardCode: number;
  isFixedPayment: boolean;
  fixedPaymentAwardCode: number;
};

const BFIConsistencyPaymentMetric = (props: Props) => {
  const { t } = useTranslation(["bfiIncentiveProgress"]);
  const { awardCode, isFixedPayment, fixedPaymentAwardCode } = props;

  const boot = useAppSelector(({ boot }) => boot);
  const locale = storedLocale();
  const affAbo = getLoginAffAbo({ boot });
  const { user } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const { data: bronzeEarningData, isFetching: isEarningDataFetching } = useGetBronzeEarningQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
  });
  const { data: bronzeConsistencyPaymentData, isFetching: isConsistencyDataFetching } =
    useGetBronzeConsistencyPaymentQuery({
      affAbo: affAbo,
      period: selectedPeriod,
      awardCode: fixedPaymentAwardCode,
      countryCode: user.isoCountryCode,
      locale: locale,
    });

  const { bonus = { amount: 0, isoCurrency: "", amountCurrency: "" } } = bronzeEarningData || {};

  const { bonusAmount = 0, formattedBonusAmount = "", met = false } = bronzeConsistencyPaymentData || {};

  const getTotal = () => {
    const total = bonus.amount + bonusAmount;
    return formatCurrency(total, bonus.isoCurrency, locale, user.isoCountryCode, {
      style: "currency",
    });
  };

  const onCallback = () => {
    // placeholder for callback function implementation
  };

  if (isFixedPayment === true && met === true && bonus.amount > 0)
    return (
      <div className="bfi-consistency-payment-metric">
        <div className="bfi-consistency-payment-metric__content">
          <div className="bfi-consistency-payment-metric__incentive-calculation">
            <IncentiveCalculation
              isLoading={isEarningDataFetching === true && isConsistencyDataFetching === true}
              base={{
                title: t("base", defaultContent["base"]),
                amount: bonus.amountCurrency,
              }}
              multiplier={{
                title: t("multiplier", defaultContent["multiplier"]),
                amount: formattedBonusAmount,
                icon: <IconSchedule />,
                callbackFn: onCallback,
                symbolIcon: <IconAdd />,
              }}
              total={{
                title: t("currentTotal", defaultContent["currentTotal"]),
                amount: getTotal(),
                symbolIcon: <IconEquals />,
              }}
            />
          </div>
        </div>
      </div>
    );

  return <></>;
};
export default BFIConsistencyPaymentMetric;
