import { TimeStamp } from "../TimeStamp/TimeStamp";
import "./PageTitleSection.scss";
type Props = {
  title: string;
};
export const PageTitleSection = ({ title }: Props) => {
  return (
    <div className="PageTitleSection">
      <div className="PageTitleSection--title">
        <h1 className="acc_section_header" id="title-section-header">
          {title}
        </h1>
      </div>
      <div className="PageTitleSection--timestamp">
        <TimeStamp dateFormat={"long"} />
      </div>
    </div>
  );
};
