import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";

export const earningAPIResponse = {
  status: QUALIFICATION_STATUS.TRACKING,
  multiplier: 30.0,
  isoCountry: "US",
  method: "MQ",
  bonusLimit: {
    amount: 34.3311,
    isoCurrency: "USD",
    amountCurrency: "$34.3311",
  },
  bonus: {
    amount: 34.3311,
    isoCurrency: "USD",
    amountCurrency: "$34.3311",
  },
  basis: {
    amount: 55.1211,
    isoCurrency: "USD",
    amountCurrency: "$55.1211",
  },
};
