import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/common";
import { IconArrowBoxRight } from "../../icons/IconArrowBoxRight/IconArrowBoxRight";
import "./LeafLearnMoreLink.scss";

type Props = {
  href: string;
  onClickFn?: Function;
};

export const LeafLearnMoreLink = ({ href, onClickFn }: Props) => {
  const { t } = useTranslation(["common"]);

  return (
    <div>
      <a
        className={"leaf-learn-more-link"}
        onClick={() => (onClickFn ? onClickFn() : null)}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <>{t("learnMore", defaultContent["learnMore"])}</>
        <span className="leaf-learn-more-link__icon">
          <IconArrowBoxRight />
        </span>
      </a>
    </div>
  );
};
