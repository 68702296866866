import { MutableRefObject, RefObject } from "react";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import { getCurrentPeriod, getSelectedPerformanceYearData } from "../../../common/util/period";
import "./MultiplierDetails.scss";

export const renderLoadingOrError = (isLoading: boolean, errorStatus: boolean, refetch: Function) => {
  if (isLoading) {
    return (
      <LeafRectangleLoader isLoading={isLoading} numLines={2} width="100%" height="6rem" margin={"1rem 0 0.5rem 0"} />
    );
  }
  if (errorStatus) {
    return <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />;
  }
  return <></>;
};

/**
 * Sets focus back to modal btn when navigating out of modal with keyboard
 *
 * @param modalBtnRef
 * @param prevOpenRef
 * @param showCurrentModal
 */
export const setModalBtnFocus = (
  modalBtnRef: RefObject<HTMLButtonElement>,
  prevOpenRef: MutableRefObject<boolean>,
  showCurrentModal: boolean,
) => {
  if (modalBtnRef && modalBtnRef.current) modalBtnRef.current.focus();
  prevOpenRef.current = showCurrentModal;
};

export const hideButtonAndSkipRequest = (fqs: string, selectedPeriod: string) => {
  if (!fqs || fqs === "0") return true;

  const currentDatePeriod = getCurrentPeriod();
  const { selectedPYStartPeriod: twoPYsPriorFromCurrentDate } = getSelectedPerformanceYearData(currentDatePeriod, 2);
  const { selectedPYStartPeriod: selectedPyStartPeriod } = getSelectedPerformanceYearData(selectedPeriod, 0);
  if (twoPYsPriorFromCurrentDate === selectedPyStartPeriod) return true;
  return false;
};

export const pyIsTwoPriorThanSelectedPY = (selectedPeriod: string) => {
  const currentDatePeriod = getCurrentPeriod();
  const { selectedPYStartPeriod: twoPYsPriorFromCurrentDate } = getSelectedPerformanceYearData(currentDatePeriod, 2);
  const { selectedPYStartPeriod: selectedPyStartPeriod } = getSelectedPerformanceYearData(selectedPeriod, 0);
  if (twoPYsPriorFromCurrentDate === selectedPyStartPeriod) return true;
  return false;
};
