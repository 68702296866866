import { useTranslation } from "react-i18next";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import defaultContent from "../../../../content/bronzeIncentivesNavigation";
import { BronzeView } from "../BronzeIncentivesViews/BronzeIncentivesViews";
import "./BronzeIncentivesNavigation.scss";

type Props = {
  bronzeViews: BronzeView[];
  navButtonOnClick: (viewId: string) => void;
};

const BronzeIncentivesNavigation = (props: Props) => {
  const { t } = useTranslation(["bronzeIncentivesNavigation"]);

  const { bronzeViews, navButtonOnClick } = props;

  const _navButtonOnClick = (viewId: string) => {
    navButtonOnClick(viewId);
  };

  return (
    <nav aria-label={`${t("pageNavigation", defaultContent["pageNavigation"])}`}>
      <div className="BronzeIncentivesNavigation" role="menubar">
        <div className="BronzeIncentivesNavigation__menu">
          {bronzeViews.map((view) => (
            <LeafFilterButton
              key={view.id}
              text={t(view.id, defaultContent[view.id])}
              onButtonClick={() => _navButtonOnClick(view.id)}
              theme={view.selected === false ? "inactive" : ""}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default BronzeIncentivesNavigation;
