import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReqOktaLambdaParams, UrlParams } from "../../common/interfaces/impersonation";
import "./acc-jwt-impersonation.scss";
import { Config, Pages } from "../../common/interfaces/config";
import { fetchConfig } from "../../common/util/config";
import { SetBootForCurrentBusiness, SetLoginFields } from "../../reducers/model/boot";
import { BootUser } from "../../reducers/boot";
import { LoginFields, setLocalStorageItem } from "../../reducers/loginLocalStorage";
import { store } from "../../store";
import { useLazyGetShellConfigQuery } from "../../services/getShellConfigAPI";
import { useLazyGetBusinessSelectorQuery } from "../../services/businessSelectorAPI";
import {
  getLambdaEnvironment,
  loadAmwayIdAuthConfigs,
  loadOktaAuthConfigs,
  parseJwt,
  redirectAccountFailed,
  redirectAuthFailed,
  redirectBusinessSelector,
  redirectUnavailable,
} from "./impersonation-util";
import {
  checkForAuthError,
  checkWebsiteUnavailable,
  setHomePage,
  setlocalNSessionStorage,
  logRedactedHash,
  getLoginFields,
  deleteCache,
  deleteRequestCache,
  getDisplayModeQueryParams,
} from "./token";
import { sessionLog } from "./util";

type Business = {
  isoCountryCode: string;
  abo: string;
  affiliate: string;
};

export const JwtImpersonation = () => {
  const [progress, setProgress] = useState("0%");
  const navigate = useNavigate();
  const [getShellConfig] = useLazyGetShellConfigQuery();
  const [getBusinessSelector] = useLazyGetBusinessSelectorQuery();

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const sessionToken = store.getState()?.boot?.user?.access_token;
      if (params.has("token") && sessionToken && params.get("token") !== sessionToken) {
        sessionStorage.clear();
        deleteCache();
        deleteRequestCache();
        window.location.replace(window.location.href);
      }

      logRedactedHash();
      setProgress("20%");
      const defaultConfig: any = await fetchConfig();
      const localStorageFields: LoginFields = getLoginFields();
      SetLoginFields({ fields: localStorageFields });
      const local = window.location.href.startsWith("http://localhost");
      const localJwtTokenReceiverUrl = "http://localhost:8000/jwt-receiver.html";

      if (localStorageFields.ssoOrigin) {
        SetLoginFields({ fields: { ssoOrigin: localStorageFields.ssoOrigin! } });
      }

      const redirectAfterSuccessfulAuthentication = (marketConfig: Config, navigate: Function) => {
        let homePage;
        if (localStorageFields.entryPage && marketConfig?.cppModPages![localStorageFields.entryPage as keyof Pages]) {
          homePage = marketConfig?.cppModPages[localStorageFields.entryPage as keyof Pages];
        } else {
          redirectUnavailable(navigate);
          return;
        }
        // Log in!
        sessionLog("Successful login!");
        window.location.replace(window.location.origin + homePage + getDisplayModeQueryParams());
      };

      const initBoot = async (_token: any) => {
        const parsedAccessToken = parseJwt(_token);

        // set the user and tokens objects to be used in init() and setState()
        const user: BootUser = {
          token_type: _token.token_type || parsedAccessToken.token_type,
          access_token: _token,
          expires_in: parsedAccessToken.exp,
          abo: parsedAccessToken.account.abo,
          aff: parsedAccessToken.account.sales_plan_aff,
          partyId: parsedAccessToken.account.lcl_partyid,
          globalPartyId: parsedAccessToken.gbl_partyid,
          idToken: _token.id_token,
          isoCountryCode: parsedAccessToken.account.country,
          cacheDateInMS: new Date().getTime(),
        };

        await SetBootForCurrentBusiness({ user });
        redirectAfterSuccessfulAuthentication(defaultConfig, navigate);
      };

      const handleJwtFromUrlParam = async () => {
        setProgress("50%");
        const token = params.get("token");
        store.dispatch(setLocalStorageItem({ clientId: defaultConfig.apiClientId }));
        await initBoot(token);
        setProgress("100%");
      };

      const tokenDefinedAuthFlow = async () => {
        // if token is defined, we assume it's trying
        // to use a different auth flow
        if (
          localStorageFields.ssoOrigin == null ||
          localStorageFields.entryPage == null ||
          localStorageFields.locale == null
        ) {
          redirectAuthFailed(navigate);
          return;
        }

        await handleJwtFromUrlParam();
        return;
      };

      const generateRequestOktaLambdaUrl = (params: ReqOktaLambdaParams, navigate: Function) => {
        //get the configs for the lambda URL
        const {
          AuthServer: authServer,
          ClientId: clientId,
          IdpDomain: idpDomain,
          UseGluuAuth,
          GluuTokenUrl,
        } = params.config;
        const redirectUri = local ? localJwtTokenReceiverUrl : params.config.RedirectUri;

        // format the requestOktaTokens lambda URL
        const firstPartUrl = (env: string, lambdaEnv: string) =>
          params.environment === "prod"
            ? `https://acc-api-global.gmb-${lambdaEnv}`
            : `https://acc-api-global-${env}.gmb-${lambdaEnv}`;

        const formatFullImpersonationLambdaUrl = (urlVars: UrlParams) => {
          const oktaTokenUrlBase = `${firstPartUrl(
            params.environment,
            params.lambdaEnvironment,
          )}.corp.amway.net/api/requestOktaTokens?clientId=${urlVars.clientId}&code=${
            urlVars.authCode
          }&oktaRedirectUri=${urlVars.redirectUri}`;

          if (urlVars.useGluuAuth) {
            return `${oktaTokenUrlBase}&gluuTokenUrl=${urlVars.gluuTokenUrl}`;
          }

          return `${oktaTokenUrlBase}&oktaAuthServer=${urlVars.authServer}&idpDomain=${urlVars.idpDomain}`;
        };

        const requestOktaTokensLambdaUrl = formatFullImpersonationLambdaUrl({
          clientId,
          authCode: params.authCode!,
          authServer,
          redirectUri,
          idpDomain,
          useGluuAuth: UseGluuAuth || false,
          gluuTokenUrl: GluuTokenUrl || "",
        });

        return requestOktaTokensLambdaUrl;
      };

      if (defaultConfig?.env) {
        checkWebsiteUnavailable(defaultConfig, navigate);
        setProgress("40%");
        checkForAuthError(navigate);
        setlocalNSessionStorage(defaultConfig, localStorageFields, navigate);

        if (params.has("token")) {
          await tokenDefinedAuthFlow();
        }

        const state = params.get("state");
        const decodedState = JSON.parse(atob(state as string));
        let config: any;
        // the AmwayID flow must check the uid from session storage against the returned state from AmwayID
        if (
          window.sessionStorage.getItem("uid") !== null &&
          decodedState.uid !== null &&
          decodedState.uid !== undefined
        ) {
          // if there is a uid in session storage, load the AmwayID auth configs
          config = loadAmwayIdAuthConfigs(defaultConfig);
          if (window.sessionStorage.getItem("uid") !== decodedState.uid) {
            redirectAuthFailed(navigate);
          }
        } else {
          // otherwise, load the Okta auth configs for impersonation
          config = loadOktaAuthConfigs(defaultConfig);
        }
        // generate the requestOktaTokens lambda
        const requestOktaTokensLambdaUrl = generateRequestOktaLambdaUrl(
          {
            environment: defaultConfig.env,
            lambdaEnvironment: getLambdaEnvironment(defaultConfig.env),
            authCode: params.get("code")!,
            config,
          },
          navigate,
        );

        // declare the data fetching function
        const fetchJWTToken = async (navigate: Function) => {
          await fetch(requestOktaTokensLambdaUrl)
            .then((response) => response.json())
            .then(async (data) => {
              setProgress("60%");
              const parsedAccessToken = parseJwt(data.access_token);

              const user: BootUser = {
                token_type: data.token_type,
                access_token: data.access_token,
                expires_in: data.expires_in,
                abo: parsedAccessToken.account.abo,
                aff: parsedAccessToken.account.sales_plan_aff,
                partyId: parsedAccessToken.account.lcl_partyid,
                globalPartyId: parsedAccessToken.gbl_partyid,
                idToken: data.id_token,
                isoCountryCode: parsedAccessToken.account.country,
                cacheDateInMS: new Date().getTime(),
              };

              await SetBootForCurrentBusiness({ user });
            })
            .then(async () => {
              const { impersonating } = store.getState().loginLocalStorage.loginFields;

              // checking if the idtoken has accounts and redirecting based on the status of accounts if there is more then one active account
              const parsedIdToken = parseJwt(await store.getState().boot.user.idToken);
              const { account, accounts: businessAccounts, origin } = parsedIdToken;

              let activeAccounts: any;
              let selectedAccount: any;
              let useGluuAuth = false;
              const configuration = store.getState().boot.configuration;

              if (configuration && configuration.useGluuAuth) {
                useGluuAuth = configuration.useGluuAuth;
              }

              if (useGluuAuth && Array.isArray(account)) {
                activeAccounts = account.filter((account: any) => account.status.toUpperCase() === "ACTIVE");
                if (activeAccounts[0]) {
                  selectedAccount = activeAccounts[0];
                }
              } else {
                activeAccounts = businessAccounts.filter((account: any) => account.status.toUpperCase() === "ACTIVE");
                // account is an object
                if (account && !Array.isArray(account) && account.status.toUpperCase() === "ACTIVE") {
                  selectedAccount = account;
                }
              }

              const businessList: Business[] = [];
              const scopeAff = decodedState.sales_plan_aff || decodedState.salesPlanAff;
              const scopeAbo = decodedState.abo || decodedState.aboNumber;
              if (
                origin &&
                origin.toUpperCase() === "IMPORTED" &&
                scopeAff &&
                scopeAbo &&
                !impersonating &&
                (selectedAccount === undefined ||
                  scopeAff !== selectedAccount.sales_plan_aff ||
                  scopeAbo !== selectedAccount.abo)
              ) {
                redirectAccountFailed(navigate);
                return;
              }
              if (!defaultConfig.hideAccountSelector) {
                if (
                  (scopeAff === selectedAccount.sales_plan_aff && scopeAbo === selectedAccount.abo) ||
                  activeAccounts.length === 1 ||
                  (!activeAccounts.length && selectedAccount.status.toUpperCase() === "ACTIVE") ||
                  impersonating
                ) {
                  await setHomePage(navigate, getShellConfig, getBusinessSelector);
                } else if (activeAccounts.length > 1) {
                  activeAccounts.forEach((account: any) =>
                    businessList.push({
                      abo: account.abo,
                      isoCountryCode: account.country,
                      affiliate: account.sales_plan_aff,
                    }),
                  );

                  await redirectBusinessSelector(businessList, navigate);
                  return;
                } else {
                  sessionLog(`There are no active accounts.`);
                  redirectAuthFailed(navigate);
                  return;
                }
              } else {
                await setHomePage(navigate, getShellConfig, getBusinessSelector);
              }
            })
            .catch((error) => {
              console.error(error);
              redirectAuthFailed(navigate);
              return;
            });
        };
        // call the function
        fetchJWTToken(navigate);
      }
    })();
  }, [getBusinessSelector, getShellConfig, navigate]);

  return (
    <div className="JwtImpersonation__progress">
      <div className="JwtImpersonation__bar" style={{ width: progress }}></div>
    </div>
  );
};
