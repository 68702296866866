import React from "react";

type Props = {
  fromDesktop: number;
  fromTab: number;
  chartWidth: number;
};

const LeafLineChartBackground = (props: Props) => {
  const { chartWidth, fromDesktop, fromTab } = props;

  const getMediaQuery = (element: string): any => {
    switch (element) {
      case "x":
        return chartWidth > fromDesktop ? 40.5 : chartWidth > fromTab ? 22.7275 : 11;
      case "y":
        return chartWidth > fromDesktop ? 401 : chartWidth > fromTab ? 308 : 148;
    }
  };

  return (
    <defs>
      <linearGradient
        id="leafLineChartBackground"
        x1={getMediaQuery("x")}
        y1="0"
        x2={getMediaQuery("x")}
        y2={getMediaQuery("y")}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={"white"} />
        <stop offset="1" stopColor="#F5F5F5" />
      </linearGradient>
    </defs>
  );
};

export default LeafLineChartBackground;
