import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { useGetBonusBreakdownQuery } from "../../../../services/monthyPerformanceDashboardAPI";
import { BonusBreakdownData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { storedLocale } from "../../../impersonation/util";

export const defaultBonusBreakdownData = (): BonusBreakdownData => {
  return {
    BonusAmountSubTotal: "",
    BonusAmount: [],
    CallResults: [],
  };
};

export const FetchBonusBreakdownData = (period: string) => {
  const { configuration, user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const { persona, hasAchieved15PercentOrHigher } = shellConfig;
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetBonusBreakdownQuery({
    baseUrl: configuration.monthlyDashboardApiUrl,
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
    persona: persona,
    hasQualified: hasAchieved15PercentOrHigher,
    bonusPeriodCount: 1,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bonusBreakdownData, setBonusBreakdownData] = useState<BonusBreakdownData>(defaultBonusBreakdownData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError) {
      setBonusBreakdownData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.errors);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, bonusBreakdownData, errorStatus, refetch };
};
