import { Trans, useTranslation } from "react-i18next";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../content/leadershipSeminar";
import "./LeadershipSeminar.scss";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";

export default function LeadershipSeminar() {
  const { t } = useTranslation(["leadershipSeminar"]);

  return (
    <main className="LeadershipSeminar">
      <div className="LeadershipSeminar__bannerTile">Banner goes here</div>
      <div className="LeadershipSeminar__pageTitle">
        <div className="LeadershipSeminar__pageTitle--title">
          <Trans>{`${t("pageTitle.title", defaultContent["pageTitle"]["title"])}`}</Trans>
        </div>
        <div className="LeadershipSeminar__pageTitle--timestamp">
          <TimeStamp dateFormat={"long"} />
        </div>
      </div>
      <div className="LeadershipSeminar__requirementsSection">
        <div className="LeadershipSeminar__requirementsSection--title">
          {`${t("requirements.sectionHeader", defaultContent["requirements"]["sectionHeader"])}`}
        </div>
        <div className="LeadershipSeminar__requirementsSection--description">
          <Trans>
            {t("requirements.description", defaultContent.requirements["description"], {
              current: "4",
              needed: "4",
            })}
            <span />
          </Trans>
        </div>
        <div className="LeadershipSeminar__requirementsSection--tileSection">
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 1</LeafTile>
          </div>
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 2</LeafTile>
          </div>
          <div className="LeadershipSeminar__requirementsSection--tile">
            <LeafTile>Tile 2</LeafTile>
          </div>
        </div>
        <div className="LeadershipSeminar__requirementsSection--pqTile">
          <LeafTile>Really long tile goes here</LeafTile>
        </div>
      </div>
      <div className="LeadershipSeminar__footer">
        <BonusInfoFooter />
      </div>
    </main>
  );
}
