// todo: remove it after integrated with live API
import { useTranslation } from "react-i18next";
import { periodToDate } from "@amway-acc/acc-mixins/dates";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { getBronzeStatus } from "../../BronzeCommon/BronzeStatus/BronzeStatus";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../../content/bfiBannerTile";
import { getBronzeBonusAmount } from "../../BronzeCommon/BronzeAmount/BronzeAmount";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import { earningAPIResponse, eligibilityAPIResponse, consistencyAPIResponse } from "./MockData";

type Props = {
  awardCode: number;
  isFixedPayment: boolean;
  isCompactView?: boolean;
};

const BFIBannerTile = (props: Props) => {
  const { t } = useTranslation(["bfiBannerTile"]);

  const { isFixedPayment, isCompactView } = props;

  const learnMoreLink = useAppSelector(
    (store) => store.boot?.configuration?.dashboardLinks?.learnMore?.bronzeFoundation,
  );

  const { selectedPeriod } = useAppSelector((state) => state.period);

  // todo: API integration - replace with RTK query
  const { status, method, isoCountry, bonus } = earningAPIResponse;
  const { eligible, graduated } = eligibilityAPIResponse;
  const consistencyPayment = consistencyAPIResponse;

  const overridedStatus = getBronzeStatus({
    status,
    method,
    eligible,
    graduated,
  });

  const bonusAmount = getBronzeBonusAmount({
    isoCountry,
    isFixedPayment,
    bonus,
    consistencyPayment,
  });

  const bannerMessages = [
    {
      status: [
        QUALIFICATION_STATUS.NOT_QUALIFIED,
        QUALIFICATION_STATUS.ELIGIBLE,
        QUALIFICATION_STATUS.PENDING_ELIGIBLE,
      ],
      title: `${t("keepGoing", defaultContent["keepGoing"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.NOT_ELIGIBLE],
      title: `${t("notEligible", defaultContent["notEligible"], { dateText: getBronzeDisplayDate(selectedPeriod) })}`,
      subTitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.QUALIFIED, QUALIFICATION_STATUS.TRACKING],
      title: `${t("greatJob", defaultContent["greatJob"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
      title: `${t("manual", defaultContent["manual"])}`,
      subTitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
    },
    {
      status: [QUALIFICATION_STATUS.HELD],
      title: `${t("onHold", defaultContent["onHold"])}`,
      subTitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
    },
  ];

  const statusToHideIncentive = [
    QUALIFICATION_STATUS.DENIED,
    QUALIFICATION_STATUS.NOT_ELIGIBLE,
    QUALIFICATION_STATUS.HELD,
  ];

  return (
    <div className="BFIBannerTile">
      <LeafIncentiveBannerTile
        bannerMessages={bannerMessages}
        incentive={{
          title: `${t("incentiveLabel", defaultContent["incentiveLabel"])}`,
          status: overridedStatus,
          amount: bonusAmount,
          statusToHideIncentive,
        }}
        learnMoreLink={learnMoreLink}
        isLoading={false} // todo: replace with rtk query state
        isCompactView={isCompactView}
      />
    </div>
  );
};

export default BFIBannerTile;
