export const eligibilityAPIResponse = {
  reason: "STARTED",
  eligible: true,
  startPeriod: 202312,
  endPeriod: 202505,
  graduated: false,
  notStarted: false,
  mostRecentAcheivedPeriod: 202404,
  remainingIncentiveEarnings: 4,
  inWindows: false,
  months: [
    {
      payment: 1515.3288,
      paymentCurrency: "MY",
      formattedPayment: "RM 1515.32", // format payment with paymentCurrency
      period: 202312,
      qualified: true,
      status: "QUALIFIED",
    },
  ],
};
