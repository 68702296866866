import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../common/enums/tealium-enums";
import defaultContent from "../../../../content/frontlineGrowth";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import { FetchMultiplerBaselineData } from "../MultiplierDetailsDataLayer";
import { MultiplierDetail } from "../../../../common/components/MultiplierDetail/MultiplierDetail";
import { hideButtonAndSkipRequest, setModalBtnFocus } from "../MultiplierDetailsUtil";
import { BaselineFQModal } from "./BaselineFQModal/BaselineFQModal";

type Props = {
  baselineFQs: string;
  prevOpenRef: MutableRefObject<boolean>;
  selectedPeriod: string;
};

export const BaselineFQ = ({ baselineFQs, prevOpenRef, selectedPeriod }: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const [showBaselineFQModal, setShowBaselineFQModal] = useState(false);
  const baselineFQModalButton = useRef<HTMLButtonElement>(null);
  const skipRequest = hideButtonAndSkipRequest(baselineFQs, selectedPeriod);
  const { baselineMultiplierData, isLoading, errorStatus, refetch } = FetchMultiplerBaselineData(
    selectedPeriod,
    skipRequest,
  );

  const baselineFqModalToggle = () => {
    setShowBaselineFQModal(!showBaselineFQModal);
    setModalBtnFocus(baselineFQModalButton, prevOpenRef, showBaselineFQModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.FRONTLINE_GROUP_GROWTH,
      `baseline_FQ_${!showBaselineFQModal ? "open" : "close"}`,
      "view_details_modal",
    );
  };

  const getButtonInfo = () => {
    if (skipRequest) return null;

    return {
      callback: baselineFqModalToggle,
      ref: baselineFQModalButton,
      text: `${t("multiplierDetails.viewDetails", defaultContent.multiplierDetails.viewDetails)}`,
      icon: <IconPlainTable />,
    };
  };

  return (
    <>
      <MultiplierDetail
        title={t("currentFqs", defaultContent.currentFqs, { fqs: baselineFQs })}
        subtitle={`${t("multiplierDetails.baseline", defaultContent.multiplierDetails.baseline)}`}
        description={""}
        buttonInfo={getButtonInfo()}
      />
      <BaselineFQModal
        baselineFQLegs={baselineMultiplierData.BaselineFqLeg}
        baselineFQs={baselineFQs}
        selectedPeriod={selectedPeriod}
        isLoading={isLoading}
        errorStatus={errorStatus}
        refetch={refetch}
        toggleModal={baselineFqModalToggle}
        displayModal={showBaselineFQModal}
      />
    </>
  );
};
