import { useTranslation } from "react-i18next";
import { formatReqVolume } from "@amway-acc/acc-mixins/l10n";
import { VolumeRequirementTile } from "../../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import defaultContent from "../../../../../content/bfiRequirements";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";

type Requirement = {
  met: boolean;
  required: boolean;
  actual: string;
  target: string;
  requiredGPV: number;
  currentGPV: number;
  shortGPV: number;
};

type Props = {
  isFetching: boolean;
} & Partial<Requirement>;

const BronzeRequirementPerfBonusLV = (props: Props) => {
  const { t } = useTranslation(["bfiRequirements"]);
  const { user } = useAppSelector((state) => state.boot);
  const locale = storedLocale();

  const {
    met = false,
    required = false,
    target = 0,
    requiredGPV = 0,
    currentGPV = 0,
    shortGPV = 0,
    isFetching,
  } = props;

  if (isFetching === false && required === false) {
    return <></>;
  }

  return (
    <VolumeRequirementTile
      title={`${t("perfBonusLevelTitle", defaultContent["perfBonusLevelTitle"], {
        perfBonus: target,
      })}`}
      volumeNeededLabel={`${t("perfBonusLevelTag", defaultContent["perfBonusLevelTag"], {
        neededGPV: formatReqVolume(shortGPV, locale, user.isoCountryCode),
        volumeType: "GPV",
      })}`}
      met={met}
      isLoading={isFetching}
      currentAmount={currentGPV}
      targetAmount={requiredGPV}
      circleCheckmarkId={"volumeRequirementTile__gpv"}
      volumeType="group"
    />
  );
};

export default BronzeRequirementPerfBonusLV;
