import { useTranslation } from "react-i18next";
import FocusTrap from "../../../common/components/FocusTrap/FocusTrap";
import defaultContent from "../../../content/aboProfileSlider";
import { useAppDispatch, useAppSelector } from "../../../store";
import { hideProfile } from "../../../reducers/profile";
import { closeProfileClickTrack } from "../../../common/tealium/ABOProfile/Profile/IndexTrack";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import ABOProfileBody from "./ABOProfileBody/ABOProfileBody";

export type Props = {
  previousElement?: HTMLElement;
};

const ABOProfileSlider = (props: Props) => {
  const { previousElement } = props;
  const { t } = useTranslation(["aboProfileSlider", "common"]);
  const isShowProfile = useAppSelector((state) => state.profile.isShowProfile);
  const dispatch = useAppDispatch();

  const closeProfile = () => {
    dispatch(hideProfile());
    closeProfileClickTrack();
  };

  return (
    <LeafPanel
      title={t("aboProfile", defaultContent["aboProfile"])}
      show={isShowProfile}
      onCloseClick={closeProfile}
      id={"abo-profile-slider"}
      previousElement={previousElement}
    >
      <ABOProfileBody isShowProfile={isShowProfile} />
    </LeafPanel>
  );
};

export default ABOProfileSlider;
