import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import { updateSelectorType, SELECTOR_TYPE } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store";
import "./MonthlyPerformanceDash.scss";
import TEALIUM from "../../../common/enums/tealium-enums";
import { MonthlyPerformanceTile } from "./MonthlyPerformanceTile/MonthlyPerformanceTile";
import { FetchMonthlyPerformanceDashData } from "./MonthlyPerformanceDashDataLayer";
import { MonthlyKeyMetricsTile } from "./MonthlyKeyMetricsTile/MonthlyKeyMetricsTile";
import { MonthlyBonusBreakdown } from "./MonthlyBonusBreakdown/MonthlyBonusBreakdown";
import { InternationalBusinesses } from "./InternationalBusinesses/InternationalBusinesses";

export const MonthlyPerformanceDashboard = ({
  showModal,
  modalCloseCallback,
}: {
  showModal: boolean;
  modalCloseCallback: Function;
}) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard", "common", "annualPerformanceDashboard"]);
  const dispatch = useAppDispatch();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { businessSelectorData } = useAppSelector((state) => state.businessSelectorData);

  const {
    monthlyPerformanceDashData,
    isLoading,
    errorStatus,
    refetch: refetchMonthlyPerformanceData,
  } = FetchMonthlyPerformanceDashData(selectedPeriod);

  const trackMonthlyDashboardPageView = (period: string) => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "monthly",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      `persona1:monthly:${period}`,
      "",
    );
  };

  useEffect(() => {
    trackMonthlyDashboardPageView(selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const trackDashboardToggleClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "annualDashboard",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "dashboard_toggle",
    );
  };

  return (
    <main className="Mpd">
      <div className="Mpd__container">
        <div className="Mpd__container-split">
          <div className="Mpd__container">
            <section className="Mpd__section">
              <MonthlyPerformanceTile
                showInfoModal={showModal}
                handleModalClose={modalCloseCallback}
                monthlyPerformanceData={monthlyPerformanceDashData}
                isLoading={isLoading}
                errorStatus={errorStatus}
                clickFunction={refetchMonthlyPerformanceData}
              />
            </section>
            <section className="Mpd__section">
              <MonthlyKeyMetricsTile />
            </section>
          </div>
          <section className="Mpd__section">
            <MonthlyBonusBreakdown />
          </section>
        </div>
        <section className="Mpd__section">
          {businessSelectorData && businessSelectorData.length > 1 && <InternationalBusinesses />}
        </section>
      </div>
    </main>
  );
};
