import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/l10n";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/trackingRequirementTile";
import "./TrackingRequirementTile.scss";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import { IconPersonPlus } from "../../../../common/icons/IconPersonPlus/IconPersonPlus";
import { storedLocale } from "../../../impersonation/util";
import { useAppSelector } from "../../../../store";
import { LegVolumeReq } from "../../../../common/interfaces/pathToBronze";
import { formatKpiValue } from "../../../../common/util/format";
import { GetVolumeTypeLabel, TrackingReqData } from "../pathToBronzeUtils";

type Props = {
  isLoading: boolean;
  circleCheckmarkId?: string;
  trackingRequirementData: TrackingReqData;
  legReqVolData: LegVolumeReq;
  bonusCurrency: string;
};

export default function TrackingRequirementTile({
  isLoading,
  trackingRequirementData,
  legReqVolData,
  bonusCurrency,
  circleCheckmarkId,
}: Props) {
  const { t } = useTranslation(["trackingRequirementTile"]);
  const { user } = useAppSelector((state) => state.boot);
  const locale = storedLocale();
  const market = user.isoCountryCode;
  const volumeType = GetVolumeTypeLabel(legReqVolData.requirement);

  // hide checkmark icon if requirement status is false
  const getVisibilityClass = (visible: boolean) => {
    return visible === false ? "--hidden" : "";
  };

  const renderCurrentBonusIcon = (trackingRequirementData: TrackingReqData) => {
    if (trackingRequirementData.totalLegRequirements < 3) {
      return (
        <IconPersonPlus
          id="tracking-icon-four"
          color={trackingRequirementData.extraBonusesMet > 0 ? "green" : "gray"}
          size="lg"
        />
      );
    }

    return (
      <>
        <IconPersonPlus
          id="tracking-icon-four"
          color={trackingRequirementData.extraBonusesMet > 0 ? "green" : "gray"}
          size="lg"
        />
        <IconPersonPlus
          id="tracking-icon-four"
          color={trackingRequirementData.extraBonusesMet > 1 ? "green" : "gray"}
          size="lg"
        />
      </>
    );
  };

  const createTrackingIconGroup = () => {
    return (
      <div className="tracking-req-tile__icon-container">
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[0])}>
          <IconCheckCircle
            id="tracking-icon-one"
            color={trackingRequirementData.firstLegsMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[1])}>
          <IconCheckCircle
            id="tracking-icon-two"
            color={trackingRequirementData.perfBonusMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[2])}>
          <IconCheckCircle id="tracking-icon-two" color={trackingRequirementData.vcsMet ? "green" : "gray"} size="lg" />
        </span>
        <span className={"tracking-req-tile" + getVisibilityClass(trackingRequirementData.requiredTrackingReq[3])}>
          <IconCheckCircle
            id="tracking-icon-three"
            color={trackingRequirementData.ppvMet ? "green" : "gray"}
            size="lg"
          />
        </span>
        <span className="tracking-req-tile__vertical-line"></span>
        {renderCurrentBonusIcon(trackingRequirementData)}
      </div>
    );
  };

  const renderLegContent = () => {
    const { extraBonusesMet } = trackingRequirementData;
    switch (extraBonusesMet) {
      case 1:
        return (
          <span>{`${t("inProgressLabelEndAdditionalLeg", defaultContent["inProgressLabelEndAdditionalLeg"], {
            volumeRequirement: formatKpiValue(legReqVolData.requirement, legReqVolData.volumeRequired, market, locale),
            volumeType: volumeType,
          })}`}</span>
        );
      case 2:
        return (
          <span>{`${t("inProgressLabelEndAdditionalLegs", defaultContent["inProgressLabelEndAdditionalLegs"], {
            volumeRequirement: formatKpiValue(legReqVolData.requirement, legReqVolData.volumeRequired, market, locale),
            volumeType: volumeType,
          })}`}</span>
        );
      case 0:
      default:
        return <span>{`${t("inProgressLabelEnd", defaultContent["inProgressLabelEnd"])}`}</span>;
    }
  };

  const renderContentForCurrentBonus = () => {
    const { metEverything } = trackingRequirementData;
    const totalRequirementsMet = trackingRequirementData.legsMetCount - trackingRequirementData.totalLegsCount;

    if (metEverything) {
      return (
        <div className="tracking-req-tile__desc">
          <span>{`${t("metlabel", defaultContent["metlabel"])}`}</span>
        </div>
      );
    }

    if (totalRequirementsMet !== 0) {
      return (
        <div className="tracking-req-tile__desc">
          <span>{`${t("requirementLabelStart", defaultContent["requirementLabelStart"])}`}</span>
          <span className="tracking-req-tile__new-legs">{`${t(
            "requirementLabelMiddle",
            defaultContent["requirementLabelMiddle"],
          )}`}</span>
          <span>{`${t("requirementLabelEnd", defaultContent["requirementLabelEnd"])}`}</span>
        </div>
      );
    }

    return (
      <div className="tracking-req-tile__desc">
        <span>{`${t(
          "requirementLabelStartAdditionalLeg",
          defaultContent["requirementLabelStartAdditionalLeg"],
        )}`}</span>
        <span className="tracking-req-tile__new-legs">{`${t(
          "requirementLabelMiddleAdditionalLeg",
          defaultContent["requirementLabelMiddleAdditionalLeg"],
          {
            bonus: formatCurrency(trackingRequirementData.nextBonusAmount, bonusCurrency, storedLocale(), market, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
              style: "currency",
            }),
          },
        )}`}</span>
        <span>{`${t("requirementLabelEndAdditionalLeg", defaultContent["requirementLabelEndAdditionalLeg"], {
          volumeRequirement: formatKpiValue(legReqVolData.requirement, legReqVolData.volumeRequired, market, locale),
          volumeType: volumeType,
        })}`}</span>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <section className="tracking-req-tile__desc-container">
        <div className="tracking-req-tile__desc">
          <span>{`${t("inProgressLabelStart", defaultContent["inProgressLabelStart"])}`}</span>
          <span className="tracking-req-tile__new-legs">
            {`${t("inProgressLabelMiddle", defaultContent["inProgressLabelMiddle"], {
              x: trackingRequirementData.legsMetCount,
              y: trackingRequirementData.totalLegsCount,
            })}`}
          </span>
          {renderLegContent()}
        </div>
        {renderContentForCurrentBonus()}
      </section>
    );
  };

  return (
    <div id="leafTrackingReqTile">
      <LeafTile title={`${t("title", defaultContent["title"])}`} isLoading={isLoading} circleCheckmarkId="">
        <section>{createTrackingIconGroup()}</section>
        {renderContent()}
      </LeafTile>
    </div>
  );
}
