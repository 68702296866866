import { Link } from "react-router-dom";
import { track } from "@amway-acc/acc-mixins/analytics";
import { IconSubsection } from "../../../common/icons/IconSubsection/IconSubsection";
import TEALIUM from "../../../common/enums/tealium-enums";

type Props = {
  id: string;
  givenClass?: string;
  title: string;
  link?: string;
  tag: string;
  amount: string;
  subsection?: boolean;
};

export const BonusBreakdownTileLine = ({
  id,
  givenClass = "",
  title,
  link = "",
  tag,
  amount,
  subsection = false,
}: Props) => {
  const trackAnnualBonusBreakdownClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      tag,
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "annual_bonus_breakdown",
    );
  };
  return (
    <div className={givenClass + " Bbt__row"} key={id}>
      <div>
        {subsection ? <IconSubsection /> : <></>}
        {link !== "" ? (
          <Link to={link} onClick={trackAnnualBonusBreakdownClick}>
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <div>{amount}</div>
    </div>
  );
};
