import "./IconFrown.scss";
import { ReactComponent as FrownIcon } from "./svg/icon_frown.svg";

type Props = {
  backgroundClass?: string;
};

export const IconFrown = ({ backgroundClass = "" }: Props) => {
  return (
    <span className="icon-frown">
      <FrownIcon />
    </span>
  );
};
