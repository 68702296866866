const content: { [key: string]: any } = {
  pageTitle: {
    personalGrowthAnnual: "Annual Personal Group Growth+",
    personalGroupGrowth: "Personal Group Growth+",
  },
  bannerTile: {
    earned: "Great Job! You are earning the Personal Group Growth+.",
    tracking: "Keep going to earn the Personal Group Growth+!",
    notTracking: "You are not currently tracking to achieve the Personal Group Growth+ for PY{performanceYear}.",
    noLongerEligible: "It is no longer possible for you to achieve the Personal Group Growth+ for PY{performanceYear}.",
    description:
      "Earn extra on the way to Founders and beyond. At the end of the Performance Year, the Personal Group Growth+ rewards you with a Performance Bonus multiplier based on the months you qualified.",
    manuallyApproved: "The Personal Group Growth+ has been manually approved.",
    potentialEarnings: "Potential PY{performanceYear} Earnings",
    incentiveCap: "Incentive is capped at {bonusCap}",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    held: "Your award is on hold.",
    denied: "Your PY{performanceYear} business is not eligible for the Personal Group Growth+.",
    newPY: "The year ahead belongs to you",
    newPYDesc:
      "Happy new Performance Year! Watch this space: information for the new performance year is coming soon. Updated information will become available when last performance year’s baselines have been confirmed.",
  },
  progressTile: {
    title: "Track your progress",
    monthlyProgress: "<1>{current} of {needed}</1> Monthly",
    annualProgress: "<1>{current} of {needed}</1> Annual",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
  },
  incentiveCalc: {
    sectionHeader: "How is this incentive calculated?",
    incentive: {
      base: "Performance Bonus (Qualified Months)",
      multiplier: "Current Incentive Multiplier",
      total: "Potential PY{performanceYear} Personal Group Growth+",
    },
    leg: {
      base: "Additional Platinum Leg Bonus",
      multiplier: "Platinum Legs Over Previous",
      total: "Current Total",
    },
  },
  legend: {
    earned: "Earned",
    potential: "Potential",
    notEarned: "Not Earned",
    unavailable: "Unavailable",
  },
  multiplierDetails: {
    titleExpand: "View Multiplier Details",
    titleCollapse: "Hide Multiplier Details",
    trackingPqs: "Tracking for {pqs} PQs",
    potentialMult: "Potential Multiplier = {pqs}%",
    pqMonths: "{pq} PQ Months",
    pqs: "{pq} PQs",
    previousPy: "Previous Performance Year",
    currentYtd: "Current YTD",
    viewMatrix: "View Multiplier Matrix",
    viewDetails: "View Details",
    footnote: "",
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of requirements.",
    monthlyRequirements: "Monthly Requirements",
    annualRequirements: "Annual Requirements",
    rubyPVBoosterLabel: "Additional <1>{volumeNeeded} {volumeType} needed for Booster</1>",
    personal: {
      title: "{volume} Annual PPV",
      description: "",
    },
    ruby: {
      title: "{volumeRuby} Ruby PV or {volumeLegs} with 1 or more qualified legs",
      description: "",
    },
    basisCheckForFoundersPlatinumWithEq: {
      title: "Qualified Founders Platinum Pin or Above",
      description: "Must qualify Founders Platinum to earn this incentive.",
    },
    basisCheckForFoundersPlatinumWithEqAna: {
      title: "Qualified Founders Platinum Pin with 6 Q Legs",
      description: "Must qualify Founders Platinum with 6 Q Legs to earn this incentive.",
    },
    basisCheckForPlatinumWithEq: {
      title: "Qualified Platinum Pin or Above",
      description: "Must qualify Platinum to get this incentive",
    },
    ytdVcsPercentageCheckForPersonalGroupGrowth: {
      title: "60% Cumulative VCS",
      description: "",
    },
    growthOrMaintainCheckForplatinum: {
      title: "Maintain Pin",
      description: "Must achieve the previous year's pin or higher. In Market or GAR pin level can be used.",
    },
    leadershipAndFoster: {
      title: "Minimum Annual Leadership Bonus of {bonusAmount}",
      description: "Must achieve this Leadership Bonus to get this incentive.",
    },
    annualPggTracking: {
      title: "Tracking for Annual Personal group Growth+",
      description:
        "Must be tracking to earn the Annual Personal Group Growth+ during the month to earn this incentive.",
    },
    annualPggiTracking: {
      title: "Tracking for Annual Personal Group Growth+",
      description:
        "Must be tracking to earn the Annual Personal Group Growth+ during the month to earn this incentive.",
    },
  },
  multiplierBase: {
    title: "PY{performanceYear} Multiplier Base",
    foundersPlatinumAbove: "Founders Platinum and above",
    platinumAbove: "Platinum and above",
    diamondAbove: "Diamond and above",
    bonus: "Bonus",
    amount: "Amount",
    leadershipBonus: "PY{performanceYear} Leadership Bonus",
    fosterBonus: "Foster Bonus",
    performanceBonus: "Performance Bonus",
    rubyBonus: "Ruby Bonus",
    depthBonus: "Depth Bonus",
    totalMultiplierBase: "Total Multiplier Base",
  },
  qualifiedMonths: {
    title: "Performance Bonus (Qualified Months)",
    earned: "Earned",
    notEarned: "Not Earned",
    potential: "Potential",
    unavailable: "Unavailable",
  },
  multiplierMatrix: {
    title: "Tracking for {fqs} PQs",
    potentialMultiplier: "Potential Multiplier = {multiplier}",
    description: "(Based on legs with 1 or more in market qualified months in PY{performanceYear})",
    pqMonthsOverPreviousYear: "PQ Months over Previous Year",
    multiplier: "Multiplier",
    multiplierBelowPlat: "Multiplier (Below Platinum)",
    multplierPlatPlus: "Multiplier (Platinum +)",
    growRange: "Grow {start}-{end}",
    growMaxOrReachPQs: "Grow {max}+ OR Reach 12 PQs",
    maintain: "Maintain",
    growMax: "Grow {max}+",
    maintainWithBooster: "Maintain with Booster",
  },
  pqModal: {
    previousYtd: "Previous Performance Year",
    currentYtd: "Current YTD",
    performanceYear: "PY{performanceYear}",
    qualified: "Qualified",
    rubyPv: "Ruby PV",
    qualifiedLegs: "# of In-Market Qualified Legs",
    rubyPvNeeded: "Ruby PV Needed",
    boosterRequirements: "Booster Requirements",
  },
  gcrProgressTile: {
    title: "Year to Date Totals",
    spMonths: "SP Months",
    pqStatus: "PY{perfYear} PQ Status",
    rubyPV: "Ruby PV",
    requirements: "View Requirements",
  },
  gcrIncentiveCalculation: {
    sectionHeader: "How is this incentive calculated?",
    paymentBase: "Payment Base Total",
    multiplier: "Current Income Multiplier",
    potential: "Potential PY{perfYear} Personal Group Growth+",
    showDetails: "Show Incentive Calculation Details",
    hideDetails: "Hide Incentive Calculation Details",
    pqBase: "PQ Base",
    pqStatus: "PY{perfYear} PQ Status",
    spMonths: "{spMonths} SP Months",
    spMonthsStatus: "PY{perfYear} SP Months Status",
  },
  gcrRequirementsModal: {
    title: "Requirements",
    spMonths: "SP Months",
    rubyPv: "Min. Annual Ruby PV",
    pqMonth: "PQ Month",
  },
  gcrYtdTotals: {
    title: "YTD Totals",
    performanceYear: "PY{perfYear}",
    spMonths: "SP Months",
    rubyPv: "",
  },
  additionalVolumeTag: "Additional {volume} Needed",
  onTrackTag: "On Track",
  achievedTag: "Achieved",
  notTracking: "Not Tracking",
  currentFqs: "{fqs} FQs",
  previousFqs: "{fqs} FQs",
  pyLabel: "PY{performanceYear}",
  average: "Average",
  abo: "ABO",
  target: "Target: {target}",
  annualPGGI: "Annual PGGI",
} as const;

export default content;
