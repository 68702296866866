import "./MonthlyBonusBreakdown.scss";
import { useTranslation } from "react-i18next";
import { BonusBreakdownTile } from "../../../BonusBreakdownTile/BonusBreakdownTile";
import defaultContent from "../../../../content/bonusBreakdown";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import {
  BonusBreakdownLineData,
  BonusBreakdownSimpleLineData,
} from "../../../../common/interfaces/monthlyPerformanceDash";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { getRoutePathForAwardCode } from "../../../../common/util/navigationUtil";
import { monthlyBonusBreakdownMap } from "./MonthlyBonusBreakdownMap";
import { FetchBonusBreakdownData } from "./MonthlyBonusBreakdownDataLayer";

export const MonthlyBonusBreakdown = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { t } = useTranslation(["bonusBreakdown"]);
  const { menuConfig } = useAppSelector((state) => state.boot.shellConfig);

  const renderBonusBreakdown = () => {
    const {
      bonusBreakdownData,
      isLoading,
      errorStatus,
      refetch: refetchBousBreakdownData,
    } = FetchBonusBreakdownData(selectedPeriod);

    if (isLoading) {
      return (
        <div className="bbt-loader-container">
          <LeafRectangleLoader isLoading={isLoading} numLines={1} width="100%" height="25rem" margin={"0 0 0.5rem 0"} />
          <div className="bbt-loader-container__bottom-line">
            <LeafRectangleLoader
              isLoading={isLoading}
              numLines={1}
              width="12rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
            <LeafRectangleLoader
              isLoading={isLoading}
              numLines={1}
              width="7rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
          </div>
        </div>
      );
    }

    if (errorStatus) {
      return <ErrorTile clickFunction={refetchBousBreakdownData} errorStatus={errorStatus} isLoading={isLoading} />;
    }

    const responseData = bonusBreakdownData.BonusAmount;
    const subGroup = [AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL, AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL];
    const order = monthlyBonusBreakdownMap.map(({ awardCode }) => awardCode);
    const group: BonusBreakdownSimpleLineData[] = [];

    const renderData = responseData
      .map((item: { awardCode: number; bonusAmount: string }) => {
        const mapItem = monthlyBonusBreakdownMap.find((element) => element.awardCode === item.awardCode);
        const title = mapItem ? mapItem.name : "";
        const result: BonusBreakdownLineData = {
          awardCode: item.awardCode,
          incentiveTitle: t(title, defaultContent[title]),
          tealiumTag: defaultContent[title].replace("+", ""),
          amount: item.bonusAmount,
          link: getRoutePathForAwardCode(menuConfig, item.awardCode),
        };
        if (item.awardCode === AWARD_CODES.PERFORMANCE_BONUS) {
          result.group = group;
        }
        if (subGroup.includes(item.awardCode)) {
          group.push(result);
        }
        return result;
      })
      .filter((item) => !subGroup.includes(item.awardCode))
      .sort((a, b) => order.indexOf(a.awardCode) - order.indexOf(b.awardCode));

    return (
      <BonusBreakdownTile
        title={t("bonusBreakdown", defaultContent["bonusBreakdown"])}
        subTitle={t("monthlyBonus", defaultContent["monthlyBonus"])}
        bonusSubTotalTitle={t("monthlyBonusSubtotal", defaultContent["monthlyBonusSubtotal"])}
        bonusSubTotalValue={bonusBreakdownData.BonusAmountSubTotal}
        bonusBreakdown={renderData}
      />
    );
  };

  return <LeafTile>{renderBonusBreakdown()}</LeafTile>;
};
