import "./BannerTile.scss";
import { ReactNode } from "react";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { LeafLearnMoreLink } from "../../../common/leaf/LeafLearnMoreLink/LeafLearnMoreLink";
import { LeafColorBlock } from "../../../common/leaf/LeafColorBlock/LeafColorBlock";
import LeafRectangleLoader from "../../leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = {
  isLoading: boolean;
  title: string | ReactNode;
  description?: string | ReactNode;
  shouldDisplayAmount: boolean;
  bonusAmount: string;
  bonusAmountLabel: string;
  incentiveCapLabel?: string | ReactNode;
  learnMoreLink?: string;
  learnMoreTealiumFunction?: Function;
};

export const BannerTile = ({
  isLoading,
  title,
  description,
  bonusAmountLabel,
  bonusAmount,
  incentiveCapLabel,
  learnMoreLink,
  learnMoreTealiumFunction = () => {},
  shouldDisplayAmount,
}: Props) => {
  return (
    <div id="BannerTile" className="BannerTile">
      <LeafTile isLoading={false}>
        <section className="BannerTile__bannerContents">
          <section className="BannerTile__bonusInfo">
            <LeafRectangleLoader
              isLoading={isLoading}
              height="3rem"
              width="auto"
              children={<div className="BannerTile__title">{title}</div>}
            />
            {description && (
              <LeafRectangleLoader
                isLoading={isLoading}
                height="1rem"
                width="auto"
                numLines={3}
                margin=".25rem"
                children={<div className="BannerTile__description">{description}</div>}
              />
            )}
          </section>
          <section className="BannerTile__bonusDetail">
            {shouldDisplayAmount && (
              <LeafRectangleLoader
                isLoading={isLoading}
                height="6rem"
                width="auto"
                children={
                  <div>
                    <LeafColorBlock>
                      <span>{bonusAmountLabel}</span>
                      <div className="lower-content">{bonusAmount}</div>
                    </LeafColorBlock>
                    {incentiveCapLabel && <div className="BannerTile__incentiveCap">{incentiveCapLabel}</div>}
                  </div>
                }
              />
            )}
            {learnMoreLink && !isLoading && (
              <div className="BannerTile__learnMoreButton">
                <LeafLearnMoreLink href={learnMoreLink} onClickFn={learnMoreTealiumFunction} />
              </div>
            )}
          </section>
        </section>
      </LeafTile>
    </div>
  );
};
