import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";

const sectionNameActionMapper: { [key: string]: string } = {
  clostToBronze: "close_to_bronze",
  newToBronze: "new_to_bronze",
  allTracking: "all_tracking",
  graduated: "graduated",
};

export const bgtViewAllClickActionTrack = (name: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    TEALIUM.CATEGORY.OVERVIEW,
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    `view_all_${sectionNameActionMapper[name]}`,
  );
};
