import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-async";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import defaultContent from "../../content/hat";
import { RegulationInfoBanner } from "../../components/RegulationInfoBanner/RegulationInfoBanner";

const loadComponent = async () => {
  import("../Lit/acc-wc-hat-faa/acc-hat-landing.js");
};

const HAT = () => {
  const { shouldShowHAT } = useAppSelector((state) => state.boot.shellConfig);
  const { hat } = useAppSelector((state) => state.boot.configuration);
  const { showGarPeriodSelector } = hat;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["hat"]);
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    if (shouldShowHAT === false) {
      navigate("/404");
    } else {
      dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
    }
  }, [dispatch, shouldShowHAT, navigate]);

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <RegulationInfoBanner />
      <h1 className="hat_header" id="hat_header">
        {t("pageTitle", defaultContent["pageTitle"])}
      </h1>
      <acc-hat-landing
        id="acc-hat-landing_component"
        mode="standalone"
        locale={storedLocale()}
        shouldShowHAT={shouldShowHAT}
        awardLabelContentKey="AWARD_LABEL"
        moduleId={"CPP"}
        {...(showGarPeriodSelector ? hat : {})}
      ></acc-hat-landing>
    </main>
  );
};

export default HAT;
