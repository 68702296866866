import { createApi } from "@reduxjs/toolkit/query/react";
import { FggOverviewResponse } from "../common/interfaces/groupGrowthIncentives";
import {
  FGGMultiplierBaseResponse,
  FGGMultiplierDetailsBaselineResponse,
  FGGMultiplierDetailsResponse,
} from "../common/interfaces/frontlineGroupGrowth";
import { dynamicBaseQuery } from ".";

interface FGGBaseRequestParams {
  abo: string;
  aff: string;
  locale: string;
  period: string;
}

interface FGGExtendedRequestParams extends FGGBaseRequestParams {
  isoCountryCode: string;
  isoCurrencyCode: string;
}

interface FGGOverviewRequestParams extends FGGExtendedRequestParams {
  baselineStart: number;
}

export const frontlineGroupGrowthAPI = createApi({
  reducerPath: "frontlineGroupGrowthAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getFggOverview: builder.query<FggOverviewResponse, FGGOverviewRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode, baselineStart } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
          BaselineStart: baselineStart.toString(),
        });

        return `/incentives-v2/api/fgg/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getFggMultiplierBase: builder.query<FGGMultiplierBaseResponse, FGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/fgg/multiplierbase?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getFGGMultiplierDetails: builder.query<FGGMultiplierDetailsResponse, FGGBaseRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
        });

        return `/incentives-v2/api/fgg/multiplier-details?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getFGGBaselineMultiplierDetails: builder.query<FGGMultiplierDetailsBaselineResponse, FGGBaseRequestParams>({
      query: (params) => {
        const { abo, aff, period, locale } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          Locale: locale,
        });

        return `/incentives-v2/api/fgg/baseline-multiplier-details?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const {
  useGetFggOverviewQuery,
  useGetFggMultiplierBaseQuery,
  useGetFGGMultiplierDetailsQuery,
  useGetFGGBaselineMultiplierDetailsQuery,
} = frontlineGroupGrowthAPI;
