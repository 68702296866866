export const AFF_MARKET_MAP: { [key: string]: string } = {
  "380": "Argentina - AR - 380",
  "030": "Australia - AU - 030",
  "210": "Austria - AT - 210",
  "120": "Belgium - BE - 120",
  "750": "Botswana - BW - 750",
  "310": "Brazil - BR - 310",
  "780": "Brunei Darussalam - BN - 780",
  "620": "Bulgaria - BG - 620",
  "020": "Canada - CA - 020",
  "400": "Chile - CL - 400",
  "360": "China - CN - 360",
  "190": "Colombia - CO - 190",
  "290": "Costa Rica - CR - 290",
  "650": "Croatia - HR - 650",
  "390": "Czech Republic - CZ - 390",
  "470": "Denmark - DK - 470 ",
  "580": "Dominican Republic - DO - 580",
  "510": "El Salvador - SV - 510",
  "800": "Estonia - EE - 800",
  "460": "Finland - FI - 460",
  "080": "France - FR - 080",
  "060": "Germany - DE - 060",
  "480": "Greece - GR - 480",
  "260": "Guatemala - GT - 260",
  "700": "Haiti - HT - 700",
  "520": "Honduras - HN - 520",
  "050": "Hong Kong - HK - 050",
  "340": "Hungary - HU - 340",
  "430": "India - IN - 430",
  "220": "Indonesia - ID - 220",
  "300": "Ireland - IE - 300",
  "160": "Italy - IT - 160",
  "070": "Japan - JP - 070",
  "830": "Kazakhstan - KZ - 830",
  "180": "Korea, Republic of - KR -180",
  "870": "Kyrgyzstan - KG - 870",
  "820": "Latvia - LV - 820",
  "810": "Lithuania - LT - 810",
  "100": "Malaysia - MY - 100",
  "170": "Mexico - MX - 170",
  "740": "Namibia - NA - 740",
  "090": "Netherlands - NL - 090",
  "240": "New Zealand - NZ - 240",
  "280": "Norway - NO - 280",
  "230": "Panama - PA - 230",
  "350": "Philippines - PH - 350",
  "150": "Poland - PL - 150",
  "270": "Portugal - PT - 270",
  "590": "Romania - RO - 590",
  "420": "Russian Federation - RU - 420",
  "530": "Singapore - SG - 530",
  "490": "Slovakia - SK - 490",
  "140": "Slovenia - SI - 140",
  "570": "South Africa - ZA - 570",
  "250": "Spain - ES - 250",
  "370": "Sweden - SE - 370",
  "110": "Switzerland - CH - 110",
  "130": "Taiwan - TW - 130",
  "200": "Thailand - TH - 200",
  "450": "Turkey - TR - 450",
  "660": "Ukraine - UA - 660",
  "040": "United Kingdom - UK/GB - 040",
  "010": "United States US - 010",
  "440": "Uruguay - UY - 440",
  "600": "Venezuela - VE - 600",
  "500": "Vietnam - VN - 500",
};
