import { useTranslation } from "react-i18next";
import { listDisplayName } from "@amway-acc/acc-mixins/src/modules/formatters/";
import { storedLocale } from "../../../../../components/impersonation/util";
import defaultContent from "../../../../../content/leafApplicantAboName";
import "./LeafApplicantABOName.scss";

type Name = {
  givenName: string;
  familyName: string;
};
type Props = {
  displayName: string;
  localeDisplayName: string;
  localeName: Name;
  latinName: Name;
  nameFormat: string;
  privacyFlag: boolean;
  preferredLanguageCode: string;
  fontSize?: "small" | "normal" | "medium" | "big" | "huge" | "large";
  fontWeight?: "thin" | "regular" | "medium" | "semibold" | "bold";
};

const LeafApplicantABOName = (props: Props) => {
  const { t } = useTranslation(["leafApplicantAboName"]);
  const portalLocale = storedLocale().split("-")[0];

  const {
    displayName,
    localeDisplayName,
    localeName,
    latinName,
    nameFormat,
    privacyFlag,
    preferredLanguageCode,
    fontSize,
    fontWeight,
  } = props;

  const renderConfidentialUser = () => {
    return t("ConfidentialUser", defaultContent["ConfidentialUser"]);
  };

  const renderABOName = () => {
    const confidentialUserText = t("ConfidentialUser", defaultContent["ConfidentialUser"]);
    const _nameFormat = nameFormat
      .split(" ")
      .map((format: string) => format.replace(/[A-Z]/g, (name) => `_${name.toLocaleLowerCase()}`))
      .join(" ");

    const languageCode = {
      preferredLanguageCode,
    };

    //construct object based on legacy format
    const aboNames = {
      name: displayName,
      local_name: localeDisplayName,
      name_details: {
        local_name: {
          given_name: localeName.givenName,
          family_name: localeName.familyName,
        },
        latin_name: {
          given_name: latinName.givenName,
          family_name: latinName.familyName,
        },
      },
    };

    return listDisplayName(aboNames, portalLocale, confidentialUserText, languageCode, _nameFormat);
  };

  return (
    <div
      className={`${fontSize ? `leaf-abo-name--${fontSize} ` : ""}${fontWeight ? `leaf-abo-name--${fontWeight}` : ""}`}
    >
      {privacyFlag === true ? renderConfidentialUser() : renderABOName()}
    </div>
  );
};

export default LeafApplicantABOName;
