import "./ABOProfileBody.scss";
import { useAppSelector } from "../../../../store";
import LeafApplicantCarousel from "../../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantCarousel/LeafApplicantCarousel";
import { useGetABOProfileQuery } from "../../../../services/profileAPI";
import { TimeStamp } from "../../../../common/components/TimeStamp/TimeStamp";
import ABOProfileHeader from "./ABOProfileHeader/ABOProfileHeader";
import ABOProfilePrimaryApplicantCard from "./ABOProfilePrimaryApplicantCard/ABOProfilePrimaryApplicantCard";
import ABOProfileTabViews from "./ABOProfileTabViews/ABOProfileTabViews";
import ABOProfileApplicantCarousel from "./ABOProfileApplicantCarousel/ABOProfileApplicantCarousel";

type Props = {
  isShowProfile: boolean;
};

const ABOProfileBody = (props: Props) => {
  const { isShowProfile } = props;

  if (isShowProfile === false) {
    return <></>;
  }

  return (
    <>
      <div className="abo-profile-body">
        <div className="abo-profile-body__abo-info">
          <div className="abo-profile-body__abo-info__header">
            <ABOProfileHeader />
          </div>

          <div className="abo-profile-body__abo-info__primary-applicant">
            <ABOProfilePrimaryApplicantCard />
          </div>

          <div className="abo-profile-body__abo-info__applicant-carousel">
            <ABOProfileApplicantCarousel />
          </div>
        </div>

        <div className="abo-profile-body__tabs">
          <ABOProfileTabViews />
          <div className="abo-profile-body__timestamp abo-profile-body__timestamp--desktop">
            <TimeStamp dateFormat={"long"} />
          </div>
          <div className="abo-profile-body__timestamp abo-profile-body__timestamp--mobile">
            <TimeStamp dateFormat={"short"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ABOProfileBody;
