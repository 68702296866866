import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Trans, useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import defaultContent from "../../../content/keyMetricsTile";
import "./KpiSelector.scss";
import { FocusTrap } from "../../components/FocusTrap/FocusTrap";
import LeafPanel from "../../leaf/LeafPanel/LeafPanel";
import { lockBodyScrolling } from "../../util/cssUtils";
import { IconClose } from "../../icons/IconClose/IconClose";
import TEALIUM from "../../../common/enums/tealium-enums";
import LeafCheckBox from "../../leaf/LeafCheckBox/LeafCheckBox";

type Props = {
  show: boolean;
  availableKpis: string[];
  selectedKpis: string[];
  minSelectionsAllowed: number;
  maxSelectionsAllowed: number;
  onSubmitFn: Function;
  onCloseCallbackFn: Function;
};

export const KpiSelector = ({
  show,
  availableKpis,
  selectedKpis,
  minSelectionsAllowed,
  maxSelectionsAllowed,
  onSubmitFn,
  onCloseCallbackFn,
}: Props) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(["keyMetricsTile"]);
  const [selectedKpiList, setSelectedKpiList] = useState(selectedKpis);
  const isMobile = useMediaQuery({ query: "(max-width: 620px)" });
  const [showInMobile, setshowInMobile] = useState(false);

  const isKpiSelected = (kpi: string) => selectedKpiList.includes(kpi);
  const title = t("kpiSelectorTitle", defaultContent["kpiSelectorTitle"], {
    totalSelected: `${selectedKpiList.length}`,
    maxSelection: `${maxSelectionsAllowed}`,
  });

  const handleCheckboxChange = (kpi: string) => {
    const updatedSelection = isKpiSelected(kpi)
      ? selectedKpiList.filter((selectedKpi) => selectedKpi !== kpi)
      : [...selectedKpiList, kpi];
    setSelectedKpiList(updatedSelection);

    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "customize_kpi_menu",
      "",
      `${isKpiSelected(kpi) ? "unselected" : "selected"}_kpi_${kpi}`,
    );
  };

  const isCheckboxDisabled = (kpi: string) => selectedKpiList.length >= maxSelectionsAllowed && !isKpiSelected(kpi);

  const renderHeader = () => {
    return (
      <div className="kpi-selector__header">
        <h3 id="modal-header" className="kpi-selector__title">
          {title}
        </h3>
        <button
          ref={closeButtonRef}
          className="leaf-panel__closeBtn"
          aria-label={`${t("kpiSelectorCloseArialLabel", defaultContent["kpiSelectorCloseArialLabel"])}`}
          onClick={() => onCloseCallbackFn()}
        >
          <IconClose width={"0.8125rem"} height={"1.25rem"} />
        </button>
      </div>
    );
  };

  const renderCheckboxList = () => (
    <ul className="kpi-selector__list">
      {availableKpis.map((kpi: string, i: number) => (
        <LeafCheckBox
          id={i.toString()}
          key={i}
          label={`${t(`${kpi}`, defaultContent[kpi])}`}
          checked={isKpiSelected(kpi)}
          disabled={isCheckboxDisabled(kpi)}
          onChange={() => handleCheckboxChange(kpi)}
        />
      ))}
    </ul>
  );

  const renderFooter = () => {
    return (
      <div className="kpi-selector__buttons">
        <button
          data-testid={"kpi-selector__cancel"}
          className="kpi-selector__cancel"
          onClick={() => onCloseCallbackFn()}
        >
          <Trans>{t("kpiSelectorModalCancel", defaultContent["kpiSelectorModalCancel"])}</Trans>
        </button>
        <button
          data-testid={"kpi-selector__apply"}
          className="kpi-selector__apply"
          onClick={() => onSubmitFn(selectedKpiList)}
          disabled={isApplyButtonDisabled}
        >
          <Trans>{t("kpiSelectorModalApply", defaultContent["kpiSelectorModalApply"])}</Trans>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (closeButtonRef.current && show && !isMobile) {
      closeButtonRef.current.focus();
    }
    const showInMobileDev = isMobile && show;
    setshowInMobile(showInMobileDev);
    lockBodyScrolling(showInMobileDev);
  }, [show, isMobile]);

  const isApplyButtonDisabled = selectedKpiList.length < minSelectionsAllowed;

  return show ? (
    <div className="kpi-selector">
      {showInMobile ? (
        <LeafPanel
          id="BGT-tracking-filter-modal"
          {...{ show: showInMobile, onCloseClick: onCloseCallbackFn, title, footer: renderFooter() }}
        >
          {renderCheckboxList()}
        </LeafPanel>
      ) : (
        <FocusTrap
          aria-modal={true}
          tabIndex={0}
          role="dialog"
          aria-labelledby="modal-header"
          handleOnClose={onCloseCallbackFn}
        >
          {renderHeader()}
          {renderCheckboxList()}
          {renderFooter()}
        </FocusTrap>
      )}
    </div>
  ) : (
    <></>
  );
};
