import "./IconUser.scss";
import { ReactComponent as UserIcon } from "./svg/iconUser.svg";
import { ReactComponent as UserIconFilled } from "./svg/iconUserFilled.svg";

export type IconColor = "orange" | "green" | "gray" | "white";
export type IconStyle = "outline" | "filled";
interface Props {
  color?: IconColor;
  style?: IconStyle;
}

export const IconUser = ({ color = "gray", style = "outline" }: Props) => {
  return <span className={`icon-user--${color}`}>{style === "outline" ? <UserIcon /> : <UserIconFilled />}</span>;
};
