import { AWARD_CODES } from "../../../common/enums/award-codes";
import BFIBannerTile from "./BFIBannerTile/BFIBannerTile";
import BFIIncentiveProgress from "./BFIIncentiveProgress/BFIIncentiveProgress";
import BFIEligibilityBannerTile from "./BFIEligibilityBannerTile/BFIEligibilityBannerTile";
import "./BronzeFoundation.scss";
import BFIRequirements from "./BFIRequirements/BFIRequirements";
import BFIMetric from "./BFIMetric/BFIMetric";
import BFIConsistencyPaymentMetric from "./BFIConsistencyPaymentMetric/BFIConsistencyPaymentMetric";

const BronzeFoundation = () => {
  const awardCode = 930;
  const restart1AwardCode = 948;
  const restart2AwardCode = 952;

  return (
    <div className="BronzeFoundation">
      <div className="BronzeFoundation__overview">
        <div className="BronzeFoundation__overview__banner">
          {/** todo: replace with program status api value */}
          {/** todo: when only display banner, set compact view to false */}
          <BFIBannerTile awardCode={awardCode} isFixedPayment={false} isCompactView={true} />
        </div>
        <div className="BronzeFoundation__overview__tracking">
          <BFIIncentiveProgress awardCode={awardCode} />
        </div>
        <div className="BronzeFoundation__overview__eligible">
          {/** todo: replace with eligible component */}
          <BFIEligibilityBannerTile awardCode={awardCode} />
        </div>
      </div>
      <div className="BronzeFoundation__incentive-calculation">
        <BFIMetric awardCode={awardCode} />
        <BFIConsistencyPaymentMetric
          awardCode={awardCode}
          isFixedPayment={true} /** todo: replace with program status api value */
          fixedPaymentAwardCode={AWARD_CODES.BRONZE_FOUNDATION_FIXED_PAYMENT}
        />
      </div>
      <div className="BronzeFoundation__requirements">
        <BFIRequirements awardCode={awardCode} />
      </div>
    </div>
  );
};

export default BronzeFoundation;
