import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/l10n";
import defaultContent from "../../../../content/legRequirementTile";
import "./LegIconGroup.scss";
import { LegRequirement } from "../../../../common/interfaces/pathToBronze";
import { IconUser, IconColor, IconStyle } from "../../../../common/icons/IconUser/IconUser";
import { IconTwoUsers } from "../../../../common/icons/IconTwoUsers/IconTwoUsers";
import { IconMultipleUsers } from "../../../../common/icons/IconMultipleUsers/IconMultipleUsers";
import { storedLocale } from "../../../impersonation/util";

const findCurrentLevel = (legRequirements: LegRequirement[]) => {
  const metLegRequirements = legRequirements.filter((leg) => leg.met).sort((a, b) => b.target - a.target);
  return metLegRequirements[0]?.target || 0;
};

const formatLegRequirements = (legRequirements: LegRequirement[]) => {
  // if the first requirement has a target of 2, we need to create a
  // pseudo leg requirement with a target of 1 so we can show the icon
  if (legRequirements.length === 2 && legRequirements[0].target === 2) {
    const { count } = legRequirements[0];
    const firstLegRequirement = { count, target: 1, met: count >= 1, bonusAmount: 0, deficit: 0, scheduleRank: 3 };
    return [firstLegRequirement, ...legRequirements];
  }

  return legRequirements;
};

export const CreateLegIconGroup = ({
  legRequirements,
  bonusCurrency,
  country,
  hasThreeLegReqs,
}: {
  legRequirements: LegRequirement[];
  bonusCurrency: string;
  country: string;
  hasThreeLegReqs: boolean;
}) => {
  const { t } = useTranslation(["legRequirementTile"]);

  const formatBonusCurrency = (bonus: number) =>
    formatCurrency(bonus, bonusCurrency, storedLocale(), country, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "currency",
    });

  const formattedLegRequirements = formatLegRequirements(legRequirements);
  const currentLevel = findCurrentLevel(formattedLegRequirements);

  const getIconColorandStyle = (leg: LegRequirement) => {
    const shouldHighlight = leg.target < 3 ? leg.target === leg.count : leg.met;

    let color: IconColor = shouldHighlight ? "green" : "gray";
    let style: IconStyle = shouldHighlight ? "filled" : "outline";

    if (leg.target === 1 && shouldHighlight && !hasThreeLegReqs) {
      color = "orange";
      style = "outline";
    }

    return { color, style };
  };

  const getLegIcon = (leg: LegRequirement) => {
    const { color, style } = getIconColorandStyle(leg);

    switch (leg.target) {
      case 1:
        return <IconUser color={color} style={style} />;
      case 2:
        return <IconTwoUsers color={color} style={style} />;
      case 3:
        return <IconMultipleUsers color={color} style={style} />;
      default:
        return <></>;
    }
  };

  const getLegRequirementLabel = (leg: LegRequirement) => {
    let labelKey = "earnedLegReqPlural";
    if (leg.target === 1) {
      labelKey = hasThreeLegReqs ? `earnedLegReqSingular` : "additionalLegNeeded";
    }

    return t(labelKey, defaultContent[labelKey], {
      bonusAmount: formatBonusCurrency(leg.bonusAmount),
      legTarget: leg.target,
    });
  };

  return (
    <section className="leg-requirements">
      <div className="leg-requirements__leg-row-group">
        {formattedLegRequirements.map((leg, index) => (
          <div
            className={`leg-requirements__leg-row ${
              leg.target === currentLevel ? "leg-requirements__leg-row--current" : ""
            }`}
            key={index}
          >
            <span className="leg-requirements__leg-row-icon">{getLegIcon(leg)}</span>
            <span className="leg-requirements__leg-row-label">{getLegRequirementLabel(leg)}</span>
          </div>
        ))}
      </div>
    </section>
  );
};
