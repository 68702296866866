import { NavDropdown } from "../../common/interfaces/routing";
import { Config } from "../../common/interfaces/config";
import { DROPDOWN_KEYS, DROPDOWN_LABELS } from "../../common/enums/routing-enums";

export const createAcademyMenu = (configuration: Config): NavDropdown => {
  const showAcademy = configuration?.navigation?.showAcademy;
  const navigationlink = configuration?.externalIntegration?.academy?.navigationlink;
  if (showAcademy && navigationlink !== "") {
    return {
      key: DROPDOWN_KEYS.ACADEMY,
      label: DROPDOWN_LABELS.ACADEMY,
      path: navigationlink,
      items: [],
      skip: false,
    };
  }
  return {
    key: DROPDOWN_KEYS.ACADEMY,
    label: DROPDOWN_LABELS.ACADEMY,
    items: [],
    skip: true,
  };
};
