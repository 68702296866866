import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./LeafTableTwoCols.scss";

export type Props<T> = {
  title?: string;
  formatTitle?: () => JSX.Element | string;
  list?: T[];
  col1: keyof T;
  col2: keyof T;
  formatCol1?: (data: T) => JSX.Element | number | string;
  formatCol2?: (data: T) => JSX.Element | number | string;
  isFetching: boolean;
  show?: boolean;
};

const LeafTableTwoCols = <T extends object>(props: Props<T>) => {
  const {
    list = [],
    title = "",
    formatTitle,
    formatCol1,
    col1,
    formatCol2,
    col2,
    isFetching = false,
    show = true,
  } = props;

  if (show === false || !list) {
    return <></>;
  }

  if (isFetching === true) {
    return (
      <div className="leaf-2-cols-table__loader">
        <LeafRectangleLoader isLoading={true} width={"100%"} height={"1.875rem"} numLines={4} />
      </div>
    );
  }

  return (
    <div className="leaf-2-cols-table">
      <div className="leaf-2-cols-table__container">
        <table className="leaf-2-cols-table__container__table">
          <thead>
            <tr>
              <td>
                {formatTitle ? (
                  formatTitle()
                ) : (
                  <p className="leaf-2-cols-table__container__table__default-title">{title}</p>
                )}
              </td>
            </tr>
          </thead>
          <tbody>
            {list.map((data, index) => (
              <tr
                key={index}
                className="leaf-2-cols-table__container__table_content leaf-2-cols-table__container__table__row"
              >
                <td>{formatCol1 ? formatCol1(data) : (data[col1] as string | number)}</td>
                <td>{formatCol2 ? formatCol2(data) : (data[col2] as string | number)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeafTableTwoCols;
