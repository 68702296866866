import { useTranslation } from "react-i18next";
import { hideProfile } from "../../../reducers/profile";
import { useAppDispatch, useAppSelector } from "../../../store";
import defaultContent from "../../../content/customerProfileSlider";
import { closeProfileClickTrack } from "../../../common/tealium/CustomerProfile/Profile/IndexTrack";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import CustomerProfileBody from "./CustomerProfileBody/CustomerProfileBody";

const CustomerProfileSlider = () => {
  const { t } = useTranslation(["customerProfileSlider", "common"]);

  const dispatch = useAppDispatch();

  const isShowProfile = useAppSelector((state) => state.profile.isShowProfile);

  const closeProfile = () => {
    dispatch(hideProfile());
    closeProfileClickTrack();
  };

  return (
    <>
      <LeafPanel
        title={t("customerProfile", defaultContent["customerProfile"])}
        show={isShowProfile}
        onCloseClick={closeProfile}
        id={"profile-slider"}
      >
        <CustomerProfileBody isShowProfile={isShowProfile} />
      </LeafPanel>
    </>
  );
};

export default CustomerProfileSlider;
