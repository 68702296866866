import { useState } from "react";
import { performanceYear } from "@amway-acc/acc-mixins/src/modules/amway-period";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../content/ttci";
import "./EligibilityPinTile.scss";
import { TtciData } from "../../../common/interfaces/ttciData";
import { TtciEligibilityModal } from "../TtciEligibilityModal/TtciEligibilityModal";
import LeafButton from "../../../common/leaf/LeafButton/LeafButton";
import TEALIUM from "../../../common/enums/tealium-enums";
import { EligibilityPin } from "./EligibilityPin/EligibilityPin";

type Props = {
  ttciData: TtciData;
  period: string;
  locale: string;
  country: string;
  isLoading: boolean;
  showTtciEligButton: boolean;
};

export default function EligibilityPinTile({
  ttciData,
  period,
  locale,
  country,
  isLoading,
  showTtciEligButton,
}: Props) {
  const { t } = useTranslation(["ttci"]);

  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  const handleViewAllTtciEligibility = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.TWO_TIME_CASH_INCENTIVE,
      "",
      "view_eligibility",
    );
    setShowModal(true);
  };

  const renderViewEligibilityButton = () => {
    return (
      <div className={`EligibilityPinTile__viewEligibilityButton mobile-desktop-only`}>
        <LeafButton id="EligibilityPinTile__button" clickAction={handleViewAllTtciEligibility}>
          {`${t("viewAllTTCIEligibility", defaultContent["viewAllTTCIEligibility"])}`}
        </LeafButton>
      </div>
    );
  };
  const renderViewEligibilityButtonTablet = () => {
    return (
      <div className={`EligibilityPinTile__viewEligibilityButton tablet-only`}>
        <LeafButton id="EligibilityPinTile__button" clickAction={handleViewAllTtciEligibility}>
          {`${t("viewAllTTCIEligibility", defaultContent["viewAllTTCIEligibility"])}`}
        </LeafButton>
      </div>
    );
  };

  return (
    <div className="EligibilityPinTile">
      <LeafTile isLoading={isLoading}>
        <div className="EligibilityPinTile__children">
          <section className="EligibilityPinTile__section-one">
            <h2 className="EligibilityPinTile__title">
              {`${t("achieveEligiblePin", defaultContent["achieveEligiblePin"])}`}
            </h2>
            <div className="EligibilityPinTile__desc">
              {t("eligiblePinsPY", defaultContent["eligiblePinsPY"], {
                PY: `PY${performanceYear(period).toString().slice(2, 4)}`,
              })}
            </div>
            {showTtciEligButton ? renderViewEligibilityButtonTablet() : ``}
          </section>
          <section className="EligibilityPinTile__section-two">
            <div className="EligibilityPinTile__pins-container">
              <div className="EligibilityPinTile__eligibilityPin">
                <EligibilityPin
                  ttciData={ttciData}
                  locale={locale}
                  country={country}
                  isLoading={isLoading}
                ></EligibilityPin>
              </div>
            </div>
            {showTtciEligButton ? renderViewEligibilityButton() : ``}
          </section>
        </div>
        <TtciEligibilityModal data={ttciData} onCloseClick={hideModal} show={showModal}></TtciEligibilityModal>
      </LeafTile>
    </div>
  );
}
