const content: { [key: string]: string } = {
  keyMetrics: "Key Metrics",
  personalPV: "Personal PV",
  personalBV: "Personal BV",
  rubyPV: "Ruby PV",
  rubyBV: "Ruby BV",
  groupOrdersCount: "Total Orders",
  qLegs: "Q Legs",
  qualifiedLegs: "Qualified Legs",
  vcsPV: "VCS PV",
  personalVcsPercent: "VCS %",
  totalCustomerCount: "Total Customers",
  groupPV: "GPV",
  totalABOCount: "Group Size",
  leadershipPercent: "Leadership %",
  leadershipPV: "Leadership PV",
  keyMetricsMenuArialLabel: "Configure Key Metrics",
  kpiSelectorTitle: "Key Metrics ({totalSelected}/{maxSelection})",
  kpiSelectorCloseArialLabel: "Close",
  kpiSelectorModalApply: "Apply",
  kpiSelectorModalCancel: "Cancel",
  superLegs: "Super Legs",
  spMonths: "SP Months",
  spMonthsLabelSingular: "{value} Month",
  spMonthsLabelPlural: "{value} Months",
  annualGroupPV: "Annual GPV",
  annualRubyPV: "Annual RPV",
  annualVePV: "Total Business Volume",
  annualPersonalPV: "Annual PPV",
  annualVcsPercent: "Annual VCS %",
};

export default content;
