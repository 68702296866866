import { ReactNode, RefObject } from "react";
import LeafTile from "../LeafTile/LeafTile";
import "./LeafTileValue.scss";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  id: string;
  title: string;
  value: string;
  isLoading: boolean;
  icon?: ReactNode;
  symbolIcon?: ReactNode;
  callback?: Function;
  buttonRef?: RefObject<HTMLButtonElement>;
}

const LeafTileValue = ({ id, title, value, isLoading, icon, symbolIcon, callback, buttonRef }: Props) => {
  const renderButton = () => {
    if (icon && callback && !isLoading) {
      return (
        <button onClick={() => callback()} className="LeafTileValue__button" ref={buttonRef}>
          <span className="LeafTileValue__button-icon">{icon ? icon : <></>}</span>
        </button>
      );
    }
    return <></>;
  };
  return (
    <LeafTile id={id} isLoading={false}>
      <div className="LeafTileValue">
        <span className="LeafTileValue__symbol">{symbolIcon ? symbolIcon : <></>}</span>
        <span className="LeafTileValue__content-container">
          <LeafRectangleLoader isLoading={isLoading} height="3.375rem" width="100%">
            <p className="LeafTileValue__title">{title}</p>
            <p className="LeafTileValue__value">{value}</p>
          </LeafRectangleLoader>
        </span>
        {renderButton()}
      </div>
    </LeafTile>
  );
};

export default LeafTileValue;
