import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/anaAcademyBanner";
import "./AnaAcademyBanner.scss";

const AnaAcademyBanner = () => {
  const { t } = useTranslation(["anaAcademyBanner"]);
  return (
    <div className="ana-academy-banner">
      <div className="banner-overlay">
        <div className="banner-text">
          <div className="banner-text_ana-academy-education"></div>
          <div className="banner-text_wrapper">
            <p className="banner-text_headline">{t("headline", defaultContent["headline"])}</p>
            <p className="banner-text_sub-headline">{t("subheadline", defaultContent["subheadline"])}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaAcademyBanner;
