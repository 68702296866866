import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "@amway-acc/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { storedLocale } from "../impersonation/util";
import { mapAttributes } from "../../common/util/config";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import ABOProfile from "../ABOProfile/ABOProfile";
import defaultContent from "../../content/edlos";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ed/acc-wc-los-ed.js");
};

export default function EdLos() {
  const { t } = useTranslation(["edlos"]);
  const { configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;
  const marketConfig = configuration?.edLos?.marketConfiguration;
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    if (!isPending) {
      const edlos = document.getElementById("acc_wc_los_ed_component") as HTMLElement;
      mapAttributes(edlos, configuration.edLos, {});
      if (enableABOProfileSlider === true) {
        edlos.setAttribute("enableABOProfileSlider", "true");
      }
    }
  }, [isPending, configuration]);

  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfileDownline);
    window.addEventListener("acc-wc-los-ed-indented-action-profile", showABOProfileEd);

    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfileDownline);
      window.removeEventListener("acc-wc-los-ed-indented-action-profile", showABOProfileEd);
    };
  }, []);
  //controlling the new abo profile event on main edlos
  const showABOProfileEd = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };
  //controlling the new abo profile event for downline los
  const showABOProfileDownline = (event: CustomEventInit) => {
    // market config == 1 is full los view and market config == 2 is restricted view, only appicable on downline los
    if (enableABOProfileSlider === true && marketConfig === 1) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };

  if (isPending) {
    return <></>;
  }

  return (
    <main>
      <h1 className="edlos_header" id="edlos_header">
        {t("pageTitle", defaultContent["pageTitle"])}
      </h1>
      <acc-wc-los-ed id="acc_wc_los_ed_component" mode="standalone" locale={storedLocale()}></acc-wc-los-ed>
      <ABOProfile previousElement={modalOpenedByElement} />
    </main>
  );
}
