import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { performanceYear } from "@amway-acc/acc-mixins/dates";
import defaultContent from "../../content/comingSoonModal";
import "./ComingSoon.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import { LeafModal } from "../../common/leaf/LeafModal/LeafModal";
import { Error, ERROR_TYPE } from "../Error/Error";
import { FetchFoundersPlatinumProgressData } from "../PerformanceDashboard/AnnualPerformanceDash/FoundersPlatinumProgressDataLayer";
import { setShowComingSoonModal } from "../../reducers/boot";
import { NavDropdownItemMin, NavDropdownMin } from "../../common/interfaces/routing";
import { errorRoutes } from "../../routing/ErrorRouting";
import { authRoutes } from "../../routing/AuthRouting";
import { ANNUAL_INCENTIVES_PATHNAMES, ROUTE_PATHS } from "../../common/enums/routing-enums";
import { setLatestActivePyPeriod } from "../../reducers/period";
import FoundersPlatinumProgressTile from "./FoundersPlatinumProgressTile/FoundersPlatinumProgressTile";

const isUrlIncludesRoutes = (routes: NavDropdownMin[]) => {
  return (
    routes.filter((NavDropdown: NavDropdownMin) => {
      return NavDropdown.items.find((item: NavDropdownItemMin) => window.location.href.includes(item.path));
    }).length > 0
  );
};

export const ComingSoon = () => {
  const { selectedPeriod, isNextPeriod } = useAppSelector((state) => state.period);
  const { showComingSoonModal, shellConfig } = useAppSelector((state) => state.boot);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [showFoundersPlatProgress, setShowFoundersPlatProgress] = useState(false);

  const handleModalClose = () => {
    setShowFoundersPlatProgress(false);
    dispatch(setShowComingSoonModal(false));
  };

  useMemo(() => {
    if (
      !isUrlIncludesRoutes([...errorRoutes, ...authRoutes]) &&
      ANNUAL_INCENTIVES_PATHNAMES.includes(pathname) &&
      shellConfig
    ) {
      if (
        shellConfig.latestFiscalPeriod &&
        performanceYear(selectedPeriod) > shellConfig.latestFiscalPeriod &&
        pathname === ROUTE_PATHS.ANNUAL_PERFORMANCE
      ) {
        dispatch(setLatestActivePyPeriod({ period: `${shellConfig.latestFiscalPeriod}08` }));
        dispatch(setShowComingSoonModal(true));
        if (isNextPeriod) {
          setShowFoundersPlatProgress(true);
        }
      } else if (shellConfig.isComingSoon && isNextPeriod && shellConfig.latestActivePeriod) {
        dispatch(setLatestActivePyPeriod({ period: shellConfig.latestActivePeriod }));
        dispatch(setShowComingSoonModal(true));
      }
    }
  }, [pathname, shellConfig, selectedPeriod, isNextPeriod, dispatch]);

  return (
    <ComingSoonModal
      handleOnClose={handleModalClose}
      showModal={showComingSoonModal}
      showFoundersPlatProgress={showFoundersPlatProgress}
      period={selectedPeriod}
    />
  );
};

const ComingSoonModal = ({
  handleOnClose,
  showModal,
  showFoundersPlatProgress,
  period,
}: {
  handleOnClose: Function;
  showModal: boolean;
  showFoundersPlatProgress: boolean;
  period: string;
}) => {
  const { t } = useTranslation(["comingSoonModal"]);

  const {
    data: fppData,
    isLoading: fppIsLoading = false,
    errorStatus: fppErrorStatus = false,
  } = FetchFoundersPlatinumProgressData(period, !showFoundersPlatProgress || !period);

  const FoundersPlatProgressTile = () => {
    if (fppErrorStatus) {
      return (
        <div className="ComingSoon__fppTile">
          <Error errorType={ERROR_TYPE.COMPONENT_ERROR} />
        </div>
      );
    }
    return (
      <div className="ComingSoon__fppTile">
        <FoundersPlatinumProgressTile isLoading={fppIsLoading} tileData={fppData.tileData} />
      </div>
    );
  };

  return showModal ? (
    <LeafModal
      open={true}
      onCloseCallbackFn={handleOnClose}
      title={`${t("header", defaultContent["header"])}`}
      description={`${t("body", defaultContent["body"])}`}
    >
      {showFoundersPlatProgress ? <FoundersPlatProgressTile /> : <></>}
    </LeafModal>
  ) : (
    <></>
  );
};
