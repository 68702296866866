import { Trans, useTranslation } from "react-i18next";
import { formatAboPV } from "@amway-acc/acc-mixins/l10n";
import { QUALIFICATION_STATUS } from "../../../../../common/enums/incentive-enums";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../../../common/icons/IconShield/IconShield";
import defaultContent from "../../../../../content/foundersPlatinumProgressTile";
import "../FppReqTile.scss";
import { storedLocale } from "../../../../impersonation/util";
import { useAppSelector } from "../../../../../store";
import { GpvRequirement } from "../../../../../common/interfaces/annualPerformanceDashboard";

export const getBadgeType = (status: QUALIFICATION_STATUS) => {
  if (status === QUALIFICATION_STATUS.QUALIFIED) return BADGE_TYPE.EARNED;
  if (status === QUALIFICATION_STATUS.UN_AVAILABLE) return BADGE_TYPE.NOT_EARNED;
  return BADGE_TYPE.OPEN;
};

type Props = {
  gpvRequirementData: GpvRequirement;
};

export const GpvRequirementTile = ({ gpvRequirementData }: Props) => {
  const {
    MonthsRequired,
    MonthsAchieved,
    MonthsNeeded,
    AnnualGpvEarned,
    AnnualGpvNeeded,
    MonthsQualificationStatus,
    GpvQualificationStatus,
    RequirementQualificationStatus,
  } = gpvRequirementData;
  const { t } = useTranslation(["foundersPlatinumProgressTile"]);
  const title = t(
    "tooltips.gpvMonths.tooltipTitle",
    defaultContent["tooltips"]["gpvMonths"]["tooltipTitle"],
  ).toString();
  const isoCountryCode = useAppSelector((state) => state.boot.user.isoCountryCode);
  const locale = storedLocale();
  const spMonthsShield = getBadgeType(MonthsQualificationStatus);
  const gpvQualStatusShield = getBadgeType(GpvQualificationStatus);
  const displayLeafBorderColor =
    RequirementQualificationStatus === QUALIFICATION_STATUS.QUALIFIED ||
    (GpvQualificationStatus === QUALIFICATION_STATUS.QUALIFIED &&
      MonthsQualificationStatus === QUALIFICATION_STATUS.QUALIFIED);
  const AnnualGpvNeededText = t("tooltips.gpvMonths.gpvNeeded", defaultContent.tooltips.gpvMonths.gpvNeeded, {
    annualGpvNeeded: formatAboPV(AnnualGpvNeeded, locale, isoCountryCode),
  }).toString();
  let AnnualGpvNeededClass = AnnualGpvNeeded <= 0 ? "FppReqTile__closed-text" : "FppReqTile__open-text";
  if (AnnualGpvNeededText.length > 24) {
    AnnualGpvNeededClass += " FppReqTile__reduced-font";
  }

  return (
    <LeafTile borderColor={displayLeafBorderColor ? "#107F47" : undefined}>
      <div className="FppReqTile">
        <div className="FppReqTile__top-section">
          <div className="FppReqTile__title">{title}</div>
          <div className="FppReqTile__shield-section">
            <div className="FppReqTile__shield-text">
              <IconShield size={BADGE_SIZE.LARGE} type={gpvQualStatusShield} />
              <Trans>{t("tooltips.gpvMonths.gpvQual", defaultContent.tooltips.gpvMonths.gpvQual)}</Trans>
            </div>
            <div className="FppReqTile__shield-text">
              <IconShield size={BADGE_SIZE.LARGE} type={spMonthsShield} />
              <Trans>
                {t("tooltips.gpvMonths.spMonthsQual", defaultContent.tooltips.gpvMonths.spMonthsQual, {
                  monthsRequired: MonthsRequired,
                })}
              </Trans>
            </div>
          </div>
          <div className="FppReqTile__needed-section">
            {RequirementQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE ||
            (GpvQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE &&
              MonthsQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE) ? (
              <div className="FppReqTile__not-available">
                <Trans>{t("tooltips.gpvMonths.unavailable", defaultContent.tooltips.gpvMonths.unavailable)}</Trans>
              </div>
            ) : (
              <>
                <div className={AnnualGpvNeededClass}>{AnnualGpvNeededText}</div>
                <div className={MonthsNeeded <= 0 ? "FppReqTile__closed-text" : "FppReqTile__open-text"}>
                  <Trans>
                    {t("tooltips.gpvMonths.spNeeded", defaultContent.tooltips.gpvMonths.spNeeded, {
                      monthsNeeded: MonthsNeeded,
                    })}
                  </Trans>
                </div>
              </>
            )}
          </div>
        </div>

        <LeafTileDivider />

        <div className="FppReqTile__bottom-section">
          <div className="FppReqTile__current-title">
            <Trans>{t("currentlyHaveLabel", defaultContent.currentlyHaveLabel).toString() + ":"}</Trans>
          </div>
          <div className="FppReqTile__current-requirements">
            <Trans>
              {t("tooltips.gpvMonths.currentSection", defaultContent.tooltips.gpvMonths.currentSection, {
                annualGpvEarned: formatAboPV(AnnualGpvEarned, locale, isoCountryCode),
                monthsAchieved: MonthsAchieved,
              })}
            </Trans>
          </div>
        </div>
      </div>
    </LeafTile>
  );
};
