import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";
import { AWARD_CODES } from "../../enums/award-codes";

const actionMapper: { [key: string]: string } = {
  overview_tab: "overview",
  tracking_tab: "tracking",
  graduated_tab: "graduated",
};

const awardCodeMapper: { [key: string]: string } = {
  bronze_foundation_tab: AWARD_CODES.BRONZE_FOUNDATION.toString(),
  bronze_builder_tab: AWARD_CODES.BRONZE_BUILDER.toString(),
};

export const bgtMiniProfilePageActionTrack = ({ period, tab }: { period: string; tab: string }) => {
  track(
    TEALIUM.U_TAG_METHOD.VIEW,
    TEALIUM.EVENT_NAME.CLICK_CATEGORY,
    `${actionMapper[tab]}_mini_profile`,
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    `${TEALIUM.CATEGORY.BRONZE_TRACKING}:${period}`,
  );
};

export const bgtViewLOSClickActionTrack = (tab: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    "",
    `${actionMapper[tab]}_viewLOS`,
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    "view_in_los",
  );
};

export const bgtProfileTabSwitchingActionTrack = ({ tab, profileTab }: { tab: string; profileTab: string }) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    "",
    `${actionMapper[tab]}_mini_profile`,
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    awardCodeMapper[profileTab],
  );
};
