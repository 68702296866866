import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../content/ssi";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { TrackProgressTile } from "../../../common/components/TrackProgressTile/TrackProgressTile";
import LeafButton from "../../../common/leaf/LeafButton/LeafButton";
import "./TrackingProgressTile.scss";

type Props = {
  totalRequirements: number;
  totalRequirementsMet: number;
  id?: string;
  isLoading: boolean;
  requirementMetStatuses: boolean[];
  clickAction: Function;
};

export default function TrackingProgressTile({
  isLoading,
  totalRequirements,
  totalRequirementsMet,
  id,
  requirementMetStatuses,
  clickAction,
}: Props) {
  const { t } = useTranslation(["ssi"]);
  const title = t("progressTile.title", defaultContent.progressTile.title).toString();

  const subtext = (
    <div className="TrackingProgressTile__subtext">
      <Trans>
        {t("progressTile.requirementLabel", defaultContent.progressTile["requirementLabel"], {
          current: totalRequirementsMet,
          needed: totalRequirements,
        })}
        <span
          className={totalRequirements === totalRequirementsMet ? "Tracking__bold Tracking__met" : "Tracking__bold"}
        ></span>
        <LeafButton clickAction={clickAction} id={"Eligibility Window Details"}>
          <Trans>
            {t("progressTile.eligibilityWindowDetails", defaultContent.progressTile["eligibilityWindowDetails"])}
          </Trans>
        </LeafButton>
      </Trans>
    </div>
  );

  const circlesSection = () => {
    return (
      <div className="TrackingProgressTile__icons">
        {requirementMetStatuses &&
          requirementMetStatuses.map((isReqMet, index) => (
            <div
              key={index}
              tabIndex={0}
              aria-label={isReqMet ? `FGG Requirement ${index + 1} Met` : `Tracking FGG Requirement ${index + 1}`}
            >
              <IconCheckCircle key={index} id={index.toString()} color={isReqMet ? "green" : "gray"} size="lg" />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="TrackingProgressTile">
      <TrackProgressTile
        isLoading={isLoading}
        id={id}
        title={title}
        circlesSection={circlesSection()}
        subtext={subtext}
      />
    </div>
  );
}
