import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import { useEffect } from "react";
import TEALIUM from "../../../enums/tealium-enums";

export const useOverviewPageLoadTrack = ({ period }: { period: string }) => {
  useEffect(() => {
    track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, `overview:${period}`, "");
  }, [period]);
};

export const overviewPageErrorTrack = (period: string) => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, `overview_stats_error:${period}`, "");
};
