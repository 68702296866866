export const eligibilityAPIResponse = {
  months: [
    {
      period: 202210,
      qualified: true,
      payment: 1627.89,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202211,
      qualified: true,
      payment: 565.842,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202212,
      qualified: true,
      payment: 1891.362,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202301,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202302,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202303,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202304,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202305,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202306,
      qualified: true,
      payment: 578.394,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202307,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202308,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202309,
      qualified: true,
      payment: 1771.578,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202310,
      qualified: true,
      payment: 631.254,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202311,
      qualified: true,
      payment: 1653.588,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202312,
      qualified: true,
      payment: 1579.92,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202401,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
    {
      period: 202402,
      qualified: true,
      payment: 891.816,
      paymentCurrency: "TH",
      status: "QUALIFIED",
    },
    {
      period: 202403,
      qualified: false,
      payment: 0,
      paymentCurrency: "TH",
      status: "NOT_QUALIFIED",
    },
  ],
};
