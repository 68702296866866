import { AWARD_CODES } from "../../../../../common/enums/award-codes";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import { useAppSelector } from "../../../../../store";
import { AnnualTrackingGGTile } from "../../../../../common/interfaces/annualPerformanceDashboard";
import "./PggTrackingTile.scss";
import { EligibleIncentive } from "../../../../../reducers/model/shellConfig";
import { GroupGrowthTrackingTile } from "../../GroupGrowthTrackingTile/GroupGrowthTrackingTile";

interface PggTrackingTileProps {
  tileData: AnnualTrackingGGTile;
  isLoading: boolean;
}

export const PggTrackingTile = ({ tileData, isLoading }: PggTrackingTileProps) => {
  const { shellConfig } = useAppSelector((state) => state.boot);
  const pggIncentives = shellConfig.eligibleIncentives.filter(
    (incentive) => incentive.awardNumber === AWARD_CODES.ANNUAL_PGG || AWARD_CODES.PGG_GCR,
  );
  const isComingSoon = (incentiveCheck: EligibleIncentive[]) =>
    incentiveCheck.some((incentive) => incentive.comingSoon);
  const pggGCR = shellConfig.eligibleIncentives.filter((incentive) => incentive.awardNumber === AWARD_CODES.PGG_GCR);
  const isGCR = pggGCR.length > 0 && pggGCR[0].programActive;
  const routerPath = isGCR ? ROUTE_PATHS.PGG_GCR : ROUTE_PATHS.ANNUAL_PGG;

  return (
    <GroupGrowthTrackingTile
      isLoading={isLoading}
      baselineMonths={tileData.BaselineMonths}
      trackingMonths={tileData.TrackingMonths}
      trackingBonusAmount={tileData.TrackingBonusAmount}
      isComingSoon={isComingSoon(pggIncentives)}
      title="personalGroupGrowth"
      titleRoutePath={routerPath}
      countLabel="pqsLabel"
      isDisabled={tileData.DisableTile}
    />
  );
};
