import { BronzeRequirementsParam, BronzeRequirementsResponse } from "../reducers/model/bronze/requirements";
import { BronzeEligibilityParam, BronzeEligibilityResponse } from "../reducers/model/bronze/eligibility";
import { BronzeEarningParam, BronzeEarningResponse } from "../reducers/model/bronze/earning";
import {
  BronzeConsistencyPaymentParam,
  BronzeConsistencyPaymentResponse,
} from "../reducers/model/bronze/consistencyPaymentMetric";
import { accAPI } from ".";

export const bronzeAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getBronzeRequirements: build.query<BronzeRequirementsResponse, BronzeRequirementsParam>({
      query: (params) => {
        const { affAbo, period, awardCode, locale, countryCode, isStandarRetailEnhancementCountry } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
          countryCode,
          isStandarRetailEnhancementCountry: `${isStandarRetailEnhancementCountry}`,
        });
        return `/incentives-v2/api/bronze/requirements?${queryParams}`;
      },
    }),
    getBronzeEligibility: build.query<BronzeEligibilityResponse, BronzeEligibilityParam>({
      query: (params) => {
        const { affAbo, period, awardCode, locale } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
        });
        return `/incentives-v2/api/bronze/eligibility?${queryParams}`;
      },
    }),
    getBronzeEarning: build.query<BronzeEarningResponse, BronzeEarningParam>({
      query: (params) => {
        const { affAbo, period, awardCode, locale } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
        });
        return `/incentives-v2/api/bronze/earning?${queryParams}`;
      },
    }),
    getBronzeConsistencyPayment: build.query<BronzeConsistencyPaymentResponse, BronzeConsistencyPaymentParam>({
      query: (params) => {
        const { affAbo, period, awardCode, countryCode, locale } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          countryCode,
          locale,
        });
        return `/incentives-v2/api/bronze/consistency-payment?${queryParams}`;
      },
    }),
  }),
});

export const {
  useGetBronzeRequirementsQuery,
  useGetBronzeEligibilityQuery,
  useGetBronzeEarningQuery,
  useGetBronzeConsistencyPaymentQuery,
} = bronzeAPI;
