import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BronzeIncentive = {
  isShowSlider: boolean;
  awardCode: number;
};

const initialState: BronzeIncentive = {
  isShowSlider: false,
  awardCode: 0,
};

const { actions, reducer } = createSlice({
  name: "bronzeIncentive",
  initialState,
  reducers: {
    showSlider: (state, action: PayloadAction<number>) => ({
      ...state,
      isShowSlider: true,
      awardCode: action.payload,
    }),
    hideSlider: (state) => ({
      ...state,
      isShowSlider: false,
    }),
  },
});

export const { showSlider, hideSlider } = actions;

export default reducer;
