export const earningAPIResponse = {
  status: "NOT_QUALIFIED",
  multiplier: 30.0,
  isoCountry: "US",
  method: "",
  bonusLimit: {
    amount: 34.3311,
    isoCurrency: "USD",
    amountCurrency: "$ 34.3311",
  },
  bonus: {
    amount: 34.3311,
    isoCurrency: "USD",
    amountCurrency: "$ 34.3311",
  },
  basis: {
    amount: 55.1211,
    isoCurrency: "USD",
    amountCurrency: "$ 55.1211",
  },
};

export const eligibilityAPIResponse = {
  reason: "STARTED",
  eligible: true,
  startPeriod: 202312,
  endPeriod: 202505,
  graduated: false,
  notStarted: false,
  mostRecentAcheivedPeriod: 202404,
  remainingIncentiveEarnings: 4,
  months: [],
};

export const consistencyAPIResponse = {
  met: true,
  target: 12,
  actual: 12,
  bonusAmount: 1200,
};
