import { getFormattedAddress } from "@amway-acc/acc-mixins/src/modules/formatters";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/leafAboAddress";
import { useAppSelector } from "../../../../store";
import { IconAddress } from "../../../icons/IconAddress/IconAddress";
import "./LeafABOAddress.scss";
import { getCountryNameFromCode } from "../../../util/formatCountryName";
import { storedLocale } from "../../../../components/impersonation/util";

type Props = {
  addresses: {
    addressLines: string[];
    municipality: string;
    region: string;
    postalCode: string;
    country: string;
    type: string;
  };
  privacyFlag: boolean;
  applicantTrackOnclick: Function;
  hideLabel?: boolean;
  hideCopyIcon?: boolean;
};

const LeafABOAddress = (props: Props) => {
  const { t } = useTranslation(["leafAboAddress"]);
  const { addresses, privacyFlag, applicantTrackOnclick, hideLabel = false, hideCopyIcon = false } = props;
  const { addressLines: address_lines, municipality, region, postalCode: postal_code, country } = addresses;

  const { address, addressLinesDivider } = useAppSelector((store) => store?.boot?.configuration?.profile?.format);

  const locale = storedLocale();

  // object format expected by mixins
  const preFormattedAddress = {
    address_lines,
    municipality,
    region,
    postal_code,
    country: getCountryNameFromCode(country, locale),
  };

  // formatting expected bt mixins
  const addressFormat = address
    .split(" ")
    .map((format: string) => format.replace(/[A-Z]/g, (address) => `_${address.toLocaleLowerCase()}`))
    .join(" ");

  const formattedAddress = getFormattedAddress(preFormattedAddress, {
    string: addressFormat,
    divider: addressLinesDivider,
  });

  const showValidAddress = formattedAddress && formattedAddress !== "--" && privacyFlag !== true;
  const displayAddress = showValidAddress ? formattedAddress : "-";

  const PrimaryAddress = () => (
    <div className="leaf-abo-address__address">
      <div>{displayAddress}</div>
    </div>
  );

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(displayAddress);
    applicantTrackOnclick("address");
  };

  return (
    <div className="leaf-abo-address">
      <div className="leaf-abo-address__content">
        {hideLabel === false && (
          <div className="leaf-abo-address__label">{t("PrimaryAddress", defaultContent["PrimaryAddress"])}</div>
        )}
        <PrimaryAddress />
      </div>
      {privacyFlag === false && showValidAddress && hideCopyIcon === false && (
        <button
          aria-label={`${t("copyAddress", defaultContent["copyAddress"])}`}
          className="leaf-abo-address__icon--copy"
          onClick={copyTextToClipboard}
        >
          <span aria-hidden="true">
            <IconAddress />
          </span>
        </button>
      )}
    </div>
  );
};

export default LeafABOAddress;
