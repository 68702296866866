import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PGGTabSwitcher from "../PGGTabSwitcher";
import defaultContent from "../../../content/monthlyPGG";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import TrackingProgressTile from "../../FrontlineGroupGrowth/TrackingProgressTile/TrackingProgressTile";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store";
import { PageTitleSection } from "../../../common/components/PageTitleSection/PageTitleSection";
import { BonusInfoFooter } from "../../../common/components/BonusInfoFooter/BonusInfoFooter";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { BadgeRequirementTile } from "../../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { MonthlyRubyRequirements } from "../AnnualPGG/MonthlyRequirements/MonthlyRubyRequirements";
import { FetchMonthlyPGGOverviewData } from "./MonthlyPGGDataLayer";
import { MonthlyIncentiveCalculation } from "./MonthlyIncentiveCalculation/MonthlyIncentiveCalculation";
import MonthlyBannerTile from "./MonthlyBannerTile/MonthlyBannerTile";
import "./MonthlyPGG.scss";

export default function MonthlyPGG() {
  const { t } = useTranslation(["monthlyPGG"]);

  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { shouldShowPGGToggle } = useAppSelector((state) => state.boot.shellConfig);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const {
    monthlyPGGOverviewData,
    isLoading,
    errorStatus,
    refetch: refetchFggOverviewData,
  } = FetchMonthlyPGGOverviewData(selectedPeriod);

  const { IncentiveData, PerformanceBonusData, IncomeData, MonthlyRequirements } = monthlyPGGOverviewData;

  const showTrackingTiles = !(
    monthlyPGGOverviewData.IncomeData.Status === QUALIFICATION_STATUS.MANUALLY_APPROVED ||
    monthlyPGGOverviewData.IncomeData.Status === QUALIFICATION_STATUS.DENIED ||
    monthlyPGGOverviewData.IncomeData.Status === QUALIFICATION_STATUS.HELD
  );

  const showRequirements = MonthlyRequirements.TotalRequirements > 0;

  let classAddition = "";
  if (MonthlyRequirements.TotalRequirements) {
    classAddition = "-shortline";
  } else if (MonthlyRequirements.TotalRequirements === 1) {
    classAddition = "-line";
  } else if (MonthlyRequirements.TotalRequirements === 2) {
    classAddition = "-backline";
  }

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile clickFunction={refetchFggOverviewData} errorStatus={errorStatus} isLoading={isLoading} />
      </LeafTile>
    );
  }

  return (
    <main>
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
      <div className="MonthlyPgg">
        <div className="MonthlyPgg__container">
          {shouldShowPGGToggle && <PGGTabSwitcher />}
          <div className="MonthlyPgg__container-split">
            <div className="MonthlyPgg__section-padding MonthlyPgg__container-row">
              <section className="MonthlyPgg__banner">
                <MonthlyBannerTile isLoading={isLoading} incomeData={IncomeData} />
              </section>
              {showTrackingTiles && showRequirements && (
                <section className="MonthlyPgg__tracking">
                  <TrackingProgressTile
                    isLoading={isLoading}
                    totalRequirements={MonthlyRequirements.TotalRequirements}
                    totalRequirementsMet={MonthlyRequirements.RequirementsMet}
                    requirementMetStatuses={MonthlyRequirements.RequirementMetStatuses}
                    id="Tracking Progress Tile"
                  />
                </section>
              )}
            </div>
            {showTrackingTiles && (
              <div className="MonthlyPgg__section-padding MonthlyPgg__calculation">
                <div>
                  <h2 className="MonthlyPgg__sub-header">{t("sectionHeader", String(defaultContent.sectionHeader))}</h2>
                </div>
                <div className="MonthlyPgg__multiplication">
                  <MonthlyIncentiveCalculation
                    isLoading={isLoading}
                    performanceBonusData={PerformanceBonusData}
                    incentiveData={IncentiveData}
                  />
                </div>
              </div>
            )}
            {showTrackingTiles && showRequirements && (
              <div className="MonthlyPgg__section-padding MonthlyPgg__requirements">
                <div>
                  <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>
                </div>
                <div className="MonthlyPgg__requirements-content">
                  {MonthlyRequirements.TotalRequirements > 0 && (
                    <>
                      <div className={"MonthlyPgg__requirements-sections" + classAddition + "-section-content"}>
                        <MonthlyRubyRequirements isLoading={isLoading} requirements={MonthlyRequirements} />
                      </div>
                      <div className={"MonthlyPgg__requirements-sections" + classAddition + "-section-content"}>
                        {MonthlyRequirements.BadgeRequirements.map((requirement) => {
                          return (
                            <section
                              className="Growth-requirement__tile"
                              key={requirement.Name}
                              data-testid={origin + "Tile-" + requirement.Name}
                            >
                              <BadgeRequirementTile
                                title={t(
                                  `requirements.${requirement.Name}.title`,
                                  defaultContent["requirements"]["annualPggTracking"]["title"] as string,
                                  {
                                    bonusAmount: requirement.Target,
                                  },
                                )}
                                description={t(
                                  `requirements.${requirement.Name}.description`,
                                  defaultContent["requirements"]["annualPggTracking"]["description"] as string,
                                )}
                                status={requirement.Status}
                                showLabel={requirement.ShowLabel}
                              />
                            </section>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="MonthlyPgg__footer">
        <BonusInfoFooter />
      </section>
    </main>
  );
}
