import "./PerformanceBonusScheduleModal.scss";
import { Trans, useTranslation } from "react-i18next";
import { formatReqVolume } from "@amway-acc/acc-mixins/l10n";
import defaultContent from "../../../../../content/monthlyPerformanceDashboard";
import { storedLocale } from "../../../../impersonation/util";
import { useAppSelector } from "../../../../../store";
import LeafTable from "../../../../../common/leaf/LeafTable/LeafTable";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import { BonusDetail } from "../../../../../common/interfaces/monthlyPerformanceDash";

type Props = {
  showPerformanceModal: boolean;
  handleModalClose?: Function;
  bonusDetails: BonusDetail[];
};

const PerformanceBonusScheduleModal = ({ showPerformanceModal, handleModalClose, bonusDetails }: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const headers = [
    { value: t("performanceBonusTotalMonthlyPv", defaultContent["performanceBonusTotalMonthlyPv"]), width: "50%" },
    { value: t("performanceBonus", defaultContent["performanceBonus"]), width: "50%" },
  ];

  const createRows = (bonusDetails: BonusDetail[]) => {
    const rows = new Array(bonusDetails.length);

    bonusDetails.forEach((row, index) => {
      let volumeValue, percentValue;
      const volume = formatReqVolume(row.Volume, locale, market);
      const percent = formatReqVolume(row.Percent, locale, market);

      switch (index) {
        case 0:
          volumeValue = (
            <Trans components={{ 1: <strong /> }}>
              {t("bonusScheduleTableVolume", defaultContent["bonusScheduleTableVolume"], {
                volume: volume,
              })}
            </Trans>
          );

          percentValue = (
            <Trans components={{ 1: <strong /> }}>
              {t("bonusScheduleTableFirstPercent", defaultContent["bonusScheduleTableFirstPercent"], {
                percent: percent,
              })}
            </Trans>
          );
          break;
        default:
          volumeValue = <strong>{volume}</strong>;
          percentValue = (
            <Trans components={{ 1: <strong /> }}>
              {t("bonusScheduleTablePercent", defaultContent["bonusScheduleTablePercent"], {
                percent: percent,
              })}
            </Trans>
          );
      }

      rows[index] = {
        row: [{ value: volumeValue }, { value: percentValue }],
      };
    });

    return rows;
  };

  const rows = createRows(bonusDetails);

  const title = t("performanceBonusSchedule", defaultContent["performanceBonusSchedule"]);

  return (
    <div className={"perfBonusSchedule-container"}>
      <LeafModal open={showPerformanceModal} onCloseCallbackFn={handleModalClose} title={title}>
        <div className="perfBonusSchedule-container__table">
          <LeafTable header={headers} rows={rows} columnAlign={"center"} headerStyle={"default"} hideHeader={false} />
        </div>
      </LeafModal>
    </div>
  );
};

export default PerformanceBonusScheduleModal;
