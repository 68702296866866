import { useState } from "react";
import { useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { IntlBusiness } from "../../../../common/interfaces/monthlyPerformanceDash";
import { IconArrowUp } from "../../../../common/icons/IconArrowUp/IconArrowUp";
import defaultContent from "../../../../content/monthlyPerformanceDashboard";
import { BusinessTile } from "./BusinessTile/BusinessTile";
import "./InternationalBusinesses.scss";
import { FetchInternationalBusinessesData } from "./InternationalBusinessesDataLayer";

export const InternationalBusinesses = () => {
  const [showBusinessInMobile, setShowBusinessInMobile] = useState(true);
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);

  const toggleShowIntlBusinesses = () => {
    setShowBusinessInMobile(!showBusinessInMobile);
  };

  const { isLoading, intlBusinessesData, errorStatus, refetch } = FetchInternationalBusinessesData();

  return (
    <section className="InternationalBusinesses">
      <section className="InternationalBusinesses__heading">
        <h2>{t("internationalBusinesses", defaultContent["internationalBusinesses"])}</h2>
        <button
          onClick={toggleShowIntlBusinesses}
          className={`InternationalBusinesses__arrow-icon${showBusinessInMobile ? "--up" : "--down"}`}
        >
          <IconArrowUp backgroundClass="black" size="md" />
        </button>
      </section>

      {errorStatus ? (
        <LeafTile hideBorder={true}>
          <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />
        </LeafTile>
      ) : (
        <section
          className={`InternationalBusinesses__container InternationalBusinesses__drawer${
            showBusinessInMobile ? "--show" : ""
          }`}
        >
          {intlBusinessesData.Businesses.map((business, index) => {
            return <BusinessTile key={`${business.abo}-${index}`} business={business} isLoading={isLoading} />;
          })}
        </section>
      )}
    </section>
  );
};
