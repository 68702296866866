import { useTranslation } from "react-i18next";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import "./BusinessTile.scss";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import { getCountryFlag } from "../../../../../common/util/getCountryFlag";
import { IconStar } from "../../../../../common/icons/IconStar/IconStar";
import { IntlBusiness } from "../../../../../common/interfaces/monthlyPerformanceDash";
import defaultContent from "../../../../../content/monthlyPerformanceDashboard";
import leafAboContent from "../../../../../content/leafAboName";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import boot from "../../../../../reducers/boot";
import { useAppSelector } from "../../../../../store";

type Props = {
  business: IntlBusiness;
  isLoading: boolean;
};

export const BusinessTile = ({ business, isLoading }: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard", "leafAboName"]);
  const boot = useAppSelector(({ boot }) => boot);
  const { user } = boot;

  const getBusinessName = () => {
    if (business.isoCountry === user.isoCountryCode) {
      return business.aboName || business.aboLocalName;
    }
    return business.aboLocalName || business.aboName;
  };

  const PrimaryBusinessLabel = () => {
    return business.isPrimary ? (
      <div className="BusinessTile__primary">
        <IconStar size="sm" />
        <span className="BusinessTile__bus-info-primary">{t("primary", defaultContent["primary"])}</span>
      </div>
    ) : null;
  };

  const BusinessDetailsRow = ({ label, value }: { label: string; value: number | string }) => {
    return (
      <>
        <LeafTileDivider />
        <div className="BusinessTile__bus-values">
          <span className="BusinessTile__info-label">{label}</span>
          <span className="BusinessTile__value">{value}</span>
        </div>
      </>
    );
  };

  return (
    <LeafTile isLoading={false}>
      <div className="BusinessTile__bus-info">
        <LeafRectangleLoader width="30%" height="1.5rem" margin="0 0 0.3125rem 0" isLoading={isLoading}>
          <div className="BusinessTile__bus-abo-no">{business.abo}</div>
        </LeafRectangleLoader>
        <LeafRectangleLoader width="50%" height="1.5rem" margin="0 0 0.3125rem 0" isLoading={isLoading}>
          <div className="BusinessTile__bus-name">
            {business.privacyFlag
              ? t("ConfidentialUser", leafAboContent["ConfidentialUser"], { ns: "leafAboName" })
              : getBusinessName()}
          </div>
        </LeafRectangleLoader>
      </div>

      <LeafRectangleLoader width="40%" height="1.5rem" margin="0 0 0.3125rem 0" isLoading={isLoading}>
        <div className="BusinessTile__bus-info">
          <div className="BusinessTile__bus-country-info">
            {getCountryFlag(business.isoCountry)}
            <span className="BusinessTile__bus-country-name">{business.countryName}</span>
          </div>
          <PrimaryBusinessLabel />
        </div>
      </LeafRectangleLoader>

      <LeafRectangleLoader width="100%" height="8rem" margin="0.5625rem 0 0 0" isLoading={isLoading}>
        <BusinessDetailsRow label={t("gpv", defaultContent["gpv"])} value={business.groupPV} />
        <BusinessDetailsRow
          label={t("qualifiedLegs", defaultContent["qualifiedLegs"])}
          value={business.qualifiedLegs}
        />
        <BusinessDetailsRow label={t("bonus", defaultContent["bonus"])} value={business.bonusPercent} />
      </LeafRectangleLoader>
    </LeafTile>
  );
};
