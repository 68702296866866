import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";

const sectionMapper: { [key: string]: string } = {
  overview_tab: "overview",
  tracking_tab: "tracking",
  graduated_tab: "graduated",
};

const actionMapper: { [key: string]: string } = {
  search: "search",
  clearSearch: "clear_search",
};

export const bgtSearchClickActionTrack = ({ action = "clearSearch", tab }: { action?: string; tab: string }) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[tab],
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    actionMapper[action],
  );
};

export const bgtOpenMiniProfileClickActionTrack = (tab: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[tab],
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    "open_mini_profile",
  );
};

export const bgtSortByClickActionTrack = (tab: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[tab],
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    "sort",
  );
};

export const bgtTabMenuClickActionTrack = (view: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[view],
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    `tab_selection_${sectionMapper[view]}`,
  );
};

export const bgtInfoIconClickActionTrack = (view: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[view],
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    "info_icon",
  );
};

export const bgtSortSelectedActionTrack = ({ sortValue, tab }: { sortValue: string; tab: string }) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    `${sectionMapper[tab]}_sort`,
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    "",
    `sort_${sortValue}`,
  );
};
