import { useEffect, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "@amway-acc/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes, regionalConfigPath, storedIsoCountry } from "../../common/util/config";
import { fetchContent, storedLocale } from "../impersonation/util";
import { useAppDispatch, useAppSelector } from "../../store";
import ABOProfile from "../ABOProfile/ABOProfile";
import { showProfile } from "../../reducers/profile";

const loadComponent = async () => {
  import("@amway-acc/acc-wc-los/acc-wc-los-ar.js");
};

export default function Watchlist() {
  const { configuration } = useAppSelector((state) => state.boot);
  const locale = storedLocale();
  window.accConfigurationPath = regionalConfigPath();
  const isCountryCode = storedIsoCountry();
  const pageId = "watchlist";
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const dispatch = useAppDispatch();
  const enableABOProfileSlider = configuration?.profile?.enableABOProfileSlider;
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  // Fall back value so that page doesn't fail to load in case of absence of configs, to be cleaned up later
  const watchListUiGrid = configuration.watchlist.uiGrid
    ? configuration.watchlist.uiGrid
    : "uiMenuShowProfile,uiMenuShowPerfHistory,uiMenuShowWatchlist";
  const updatedWatchlist = {
    ...configuration.watchlist,
    uiGrid: watchListUiGrid
      .split(",")
      .filter((item) => (enableABOProfileSlider === true ? item !== "uiMenuShowPerfHistory" : true))
      .join(","),
  };

  useEffect(() => {
    if (!isPending) {
      const watchlist = document.createElement("acc-wc-los-ar");
      watchlist.setAttribute("locale", locale.toLowerCase());
      watchlist.setAttribute("id", "acc_wc_view_watchlist_component");

      if (enableABOProfileSlider === true) {
        watchlist.setAttribute("enableABOProfileSlider", "true");
      }

      // Get market config for market-specific properties and content
      fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
        mapAttributes(watchlist, { ...updatedWatchlist, ...{ disclaimer: content.footerDisclaimerMD || "" } }, {});

        // After all properties are added, render!
        const wrapper = document.getElementById("watchlist-wrapper") as HTMLDivElement;
        const watchlistElements = document.getElementsByTagName("acc-wc-los-ar");
        // prevent multiple watchlist instances being appended to the DOM
        if (wrapper && watchlist && !watchlistElements.length) {
          wrapper.append(watchlist);
        }
      });
    }
  }, [isPending, configuration, locale, isCountryCode]);

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, []);

  const showABOProfile = (event: CustomEventInit) => {
    if (enableABOProfileSlider === true) {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    }
  };

  if (isPending) {
    return <></>;
  }

  return (
    <main>
      <div id="watchlist-wrapper"></div>
      <ABOProfile previousElement={modalOpenedByElement} useCurrentMonthData={true} />
    </main>
  );
}
