import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LeafButton, { Variant } from "../../../common/leaf/LeafButton/LeafButton";
import LeafPanel, { ModalProps } from "../../../common/leaf/LeafPanel/LeafPanel";
import LeafTabViews from "../../../common/leaf/LeafTabViews/LeafTabViews";
import defaultContent from "../../../content/bronzeGroupTracking";
import "./MiniProfile.scss";
import { useAppSelector, useAppDispatch } from "../../../store";
import { BRONZE_TAGS } from "../../../reducers/model/bgtFilters";
import { storedLocale } from "../../impersonation/util";
import {
  GetABODisplayName,
  getLatestGradDate,
  resetEligMonths,
  returnDefaultBgtMiniProfileData,
  useBronzeIncentiveStatus,
} from "../bronzeGroupTrackingUtils";
import {
  bgtMiniProfilePageActionTrack,
  bgtProfileTabSwitchingActionTrack,
  bgtViewLOSClickActionTrack,
} from "../../../common/tealium/BronzeGroupTracking/MiniProfile";
import { BgtMiniProfileApi, BgtMiniProfileData, EligibilityGroup } from "../../../common/interfaces/bgtMiniProfile";
import { PROFILE_TABS } from "../../../common/enums/bronze-group-tracking-enums";
import { closeProfileModal } from "../../../reducers/BronzeGroupTracking/bronzeGroupTracking";
import { ROUTE_PATHS } from "../../../common/enums/routing-enums";
import { useGetContactInfoQuery } from "../../../services/profileAPI";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { getLongPeriodFormat } from "../../../common/util/period";
import { BronzeProfileTab } from "./BronzeProfileTab/BronzeProfileTab";
import { FetchBGTMiniProfileData } from "./MiniProfileDataLayer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileModalProps extends ModalProps {}

interface PhoneNumbers {
  displayNumber: string;
}

interface EmailAddresses {
  email: string;
}

interface ContactInfo {
  email: string;
  phone: string;
  privacyFlag: boolean;
  sponsorName: string;
  sponsorAboNo: string;
}

const getPhoneNumber = (phoneNumbers: PhoneNumbers[]) => phoneNumbers?.[0]?.displayNumber || "";

const getEmail = (emailAddresses: EmailAddresses[]) => emailAddresses?.[0]?.email || "";

const formatContactInfoData = (contactInfoAPIData: any): ContactInfo => {
  const { partyInfo = [], aboInfo = {} } = contactInfoAPIData || {};
  const privacyFlag = aboInfo.privacyFlag ? Boolean(aboInfo.privacyFlag) : false;
  const sponsorPrivacyFlag = aboInfo.sponsor?.aboNo === "--" ? true : false;
  const email = privacyFlag ? "" : getEmail(partyInfo.flatMap((d: any) => d.emailAddresses));
  const phone = privacyFlag ? "" : getPhoneNumber(partyInfo.flatMap((d: any) => d.phoneNumbers));

  return {
    email,
    phone,
    sponsorName: sponsorPrivacyFlag ? "" : aboInfo.sponsor?.name || "",
    sponsorAboNo: sponsorPrivacyFlag ? "" : aboInfo.sponsor?.aboNo || "",
    privacyFlag: privacyFlag,
  };
};

const getEligGroup = (eligGroup: EligibilityGroup, eligGroupName: string) => {
  return {
    ...eligGroup,
    [eligGroupName]: resetEligMonths(eligGroup),
  };
};

const getEligibilityData = (selectedIncentive: BgtMiniProfileData, eligibilityTag: string): BgtMiniProfileData => {
  const { firstTime, restartOne, restartTwo } = selectedIncentive;
  if (eligibilityTag === BRONZE_TAGS.PRE_WINDOW && (firstTime || restartOne || restartTwo) && eligibilityTag) {
    let updatedEligData;
    if (restartOne) {
      updatedEligData = getEligGroup(selectedIncentive.restartOne!, "restartOne");
    } else if (restartTwo) {
      updatedEligData = getEligGroup(selectedIncentive.restartTwo!, "restartTwo");
    } else {
      updatedEligData = getEligGroup(selectedIncentive.firstTime!, "firstTime");
    }

    return {
      ...selectedIncentive,
      ...updatedEligData,
    };
  }
  return selectedIncentive;
};

export const MiniProfile = (props: ProfileModalProps) => {
  const { currentProfileAboData, showProfile } = useAppSelector((state) => state.bronzeGroupTracking);
  const { isBronzeFoundationActive, isBronzeBuilderActive } = useBronzeIncentiveStatus();
  const defaultData = returnDefaultBgtMiniProfileData();

  const [activeView, setActiveView] = useState<PROFILE_TABS>(PROFILE_TABS.BRONZE_FOUNDATION_TAB);
  const [activeTabs, setActiveTabs] = useState<PROFILE_TABS[]>([]);
  const [miniProfileData, setMiniProfileData] = useState<BgtMiniProfileApi>(defaultData);
  const [contactInfoData, setContactInfoData] = useState<ContactInfo>({
    email: "",
    phone: "",
    sponsorName: "",
    sponsorAboNo: "",
    privacyFlag: true,
  });
  const [graduationDate, setGraduationDate] = useState<string>("");
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedTab = useAppSelector((state) => state.bronzeGroupTrackingTab)?.activeView;
  const { configuration } = useAppSelector((state) => state.boot);
  const { showViewInLosButton = false } = configuration.bronzeGroupTracking;

  const { aboName, aboLocalName, isoLanguage, affNumber, aboNumber, bronzeIncentive, bronzeBuilder, bronzeFoundation } =
    currentProfileAboData;
  if (props.show) bgtMiniProfilePageActionTrack({ tab: selectedTab, period: selectedPeriod });

  const { data, isLoading } = FetchBGTMiniProfileData(selectedPeriod, `${affNumber}-${aboNumber}`);
  const {
    isFetching: contactInfoAPIisFetching,
    isError: contactInfoAPIisError,
    data: profileApiData,
  } = useGetContactInfoQuery({ period: selectedPeriod, affAbo: `${affNumber}-${aboNumber}` });

  useEffect(() => {
    const data = formatContactInfoData(profileApiData);
    setContactInfoData(data);
  }, [profileApiData]);

  useEffect(() => {
    setActiveView(
      bronzeIncentive === BRONZE_TAGS.BRONZE_FOUNDATION
        ? PROFILE_TABS.BRONZE_FOUNDATION_TAB
        : PROFILE_TABS.BRONZE_BUILDER_TAB,
    );
  }, [bronzeIncentive, currentProfileAboData]);

  useEffect(() => {
    if (showProfile && data) {
      setMiniProfileData(data);
    }
  }, [data, showProfile]);

  useEffect(() => {
    const selectedIncentive =
      activeView === PROFILE_TABS.BRONZE_FOUNDATION_TAB
        ? miniProfileData.bronzeFoundation
        : miniProfileData.bronzeBuilder;
    const { firstTime, restartOne, restartTwo } = selectedIncentive;
    const dataSets = [];
    if (firstTime) dataSets.push(firstTime);
    if (restartOne) dataSets.push(restartOne);
    if (restartTwo) dataSets.push(restartTwo);

    setGraduationDate(getLatestGradDate(dataSets, selectedTab));
  }, [activeView, showProfile, miniProfileData, selectedTab]);

  useEffect(() => {
    const tabs = [];
    if (isBronzeFoundationActive) tabs.push(PROFILE_TABS.BRONZE_FOUNDATION_TAB);
    if (isBronzeBuilderActive) tabs.push(PROFILE_TABS.BRONZE_BUILDER_TAB);
    setActiveTabs(tabs);
  }, [isBronzeFoundationActive, isBronzeBuilderActive]);

  const { t } = useTranslation(["bronzeGroupTracking"]);

  const handleViewInLosClick = () => {
    bgtViewLOSClickActionTrack(selectedTab);
    navigate(ROUTE_PATHS.LOS, { state: { rwlosSearch: aboNumber } });
    dispatch(closeProfileModal());
  };

  const modalTabsView = [
    {
      id: PROFILE_TABS.BRONZE_FOUNDATION_TAB,
      title: `${t("bronzeFoundation", defaultContent["bronzeFoundation"])}`,
      View: (
        <BronzeProfileTab
          tabData={bronzeFoundation[0]}
          eligibilityData={getEligibilityData(miniProfileData.bronzeFoundation, bronzeFoundation[0]?.eligibilityTag)}
          eligibilityIsLoading={isLoading}
        />
      ),
      disabled: bronzeFoundation.length === 0,
    },
    {
      id: PROFILE_TABS.BRONZE_BUILDER_TAB,
      title: `${t("bronzeBuilder", defaultContent["bronzeBuilder"])}`,
      View: (
        <BronzeProfileTab
          tabData={bronzeBuilder[0]}
          eligibilityData={getEligibilityData(miniProfileData.bronzeBuilder, bronzeBuilder[0]?.eligibilityTag)}
          eligibilityIsLoading={isLoading}
        />
      ),
      disabled: bronzeBuilder.length === 0,
    },
  ];

  const tabsView = activeTabs.map((id) => ({ ...modalTabsView.find((item) => item.id === id) }));

  const setViewOnClick = (activeView: PROFILE_TABS) => {
    setActiveView(activeView);
    bgtProfileTabSwitchingActionTrack({ tab: selectedTab, profileTab: activeView });
  };

  const formatGraduationDate = (graduationDate: string) => {
    return getLongPeriodFormat(graduationDate, storedLocale());
  };

  const renderContactInfo = (contactType: "email" | "phone") => {
    if (contactInfoData.privacyFlag) return <></>;
    return (
      <>
        <p>
          <span>{`${t(contactType, defaultContent[contactType])}`}: </span>
          <span className="miniProfile__email">{contactInfoData[contactType]}</span>
        </p>
      </>
    );
  };

  const renderContactInfoSection = () => {
    if (contactInfoAPIisError) return <></>;

    return (
      <span className="miniProfile__contactInfo">
        <LeafRectangleLoader isLoading={contactInfoAPIisFetching} width="18rem" height="4rem">
          {renderContactInfo("phone")}
          {renderContactInfo("email")}
          <p>
            <span>{`${t("sponsor", defaultContent["sponsor"])}`}: </span>
            <span>
              {<GetABODisplayName aboName={contactInfoData.sponsorName} aboLocalName={""} aboIsoLanguage={""} />}
            </span>
            <span hidden={!contactInfoData.sponsorName}>{` (#${contactInfoData.sponsorAboNo})`}</span>
          </p>
        </LeafRectangleLoader>
      </span>
    );
  };

  return (
    <div className="miniProfileModal">
      <LeafPanel {...props}>
        <div className="miniProfile">
          <section data-testid="miniProfile__aboInfo" className="miniProfile__aboInfo">
            <p data-testid="miniProfile__aboName" className="miniProfile__aboName">
              <span>
                {<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}
              </span>
            </p>
            <p data-testid="miniProfile__aboNum" className="miniProfile__aboNum">
              #{aboNumber}
            </p>
            {renderContactInfoSection()}
            {graduationDate.length > 0 && (
              <div data-testid="miniProfile__aboGradDate" className="miniProfile__aboGradDate">{`${t(
                "graduatedIn",
                defaultContent["graduatedIn"],
                {
                  graduationDate: formatGraduationDate(graduationDate),
                },
              )}`}</div>
            )}
          </section>
          <section className="miniProfile__innerWrapper">
            <section className="miniProfile__body">
              <LeafTabViews setViewOnClick={setViewOnClick} Views={tabsView} activeView={activeView}></LeafTabViews>
            </section>
            <section className="miniProfile__footer">
              {showViewInLosButton && (
                <LeafButton
                  variant={Variant.SOLID}
                  clickAction={handleViewInLosClick}
                  id={"bgtMiniProfileViewInLOSButton"}
                >{`${t("viewInLOS", defaultContent["viewInLOS"])}`}</LeafButton>
              )}
            </section>
          </section>
        </div>
      </LeafPanel>
    </div>
  );
};
