import "./RequirementModal.scss";
import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import { GCRPGGRequirement } from "../../../../../common/interfaces/groupGrowthIncentives";
import defaultContent from "../../../../../content/annualGcrPGG";
import LeafTable, { TableRow } from "../../../../../common/leaf/LeafTable/LeafTable";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type RequirementProps = {
  requirementData: GCRPGGRequirement[];
  open: boolean;
  toggleModal: Function;
  isLoading: boolean;
};

export const RequirementModal = ({ requirementData, open, toggleModal, isLoading }: RequirementProps) => {
  const { t } = useTranslation(["annualGcrPGG"]);
  const colWidth = 100 / 3 + "%";

  const headers = [
    {
      value: `${t(`requirementsModal.spMonth`, defaultContent.requirementsModal["spMonth"])}`,
      colWidth: colWidth,
    },
    {
      value: `${t(`requirementsModal.rubyPv`, defaultContent.requirementsModal["rubyPv"])}`,
      colWidth: colWidth,
    },
    {
      value: `${t(`requirementsModal.pqMonth`, defaultContent.requirementsModal["pqMonth"])}`,
      colWidth: colWidth,
    },
  ];

  const rows = requirementData.map((value: GCRPGGRequirement, index: number): TableRow => {
    const firstCell = (
      <span className={`RequirementModal__cell--${value.HighlightSPMonth ? "activeSpMonths" : "inactive"}`}>
        {value.SPMonth}
      </span>
    );
    const secondCell = (
      <span className={`RequirementModal__cell--${value.HighlightRubyPV ? "active" : "inactive"}`}>
        {value.MinAnnualRubyPV}
      </span>
    );
    const thirdCell = (
      <span className={`RequirementModal__cell--${value.HighlightPQMonth ? "active" : "inactive"}`}>
        {value.PQMonth}
      </span>
    );

    return {
      row: [
        { value: firstCell, columnAlign: "center" },
        { value: secondCell, columnAlign: "center" },
        { value: thirdCell, columnAlign: "center" },
      ],
    };
  });

  return (
    <div className="RequirementModal">
      <LeafModal
        open={open}
        onCloseCallbackFn={toggleModal}
        title={`${t(`requirementsModal.requirements`, defaultContent.requirementsModal["requirements"])}`}
      >
        {!isLoading ? (
          <div className="RequirementModal__body">
            <LeafTable
              header={headers}
              rows={rows}
              columnAlign="center"
              headerStyle="default"
              hideHeader={false}
              rowHeight="3.25rem"
            />
          </div>
        ) : (
          <>
            <LeafRectangleLoader width="100%" height="4.3125rem" margin=".5rem 0" isLoading={true} />
            <LeafRectangleLoader width="100%" height="2.75rem" margin=".5rem 0" isLoading={true} numLines={5} />
          </>
        )}
      </LeafModal>
    </div>
  );
};
