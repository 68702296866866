import { useEffect, useState, useRef } from "react";
import "./LeafDropdown.scss";
import { IconDropdownCarrot } from "../../icons/IconDropdownCarrot/IconDropdownCarrot";
import { LeafBackdrop } from "../LeafBackdrop/LeafBackdrop";
import { lockBodyScrolling } from "../../util/cssUtils";

/**
 * Displayed items in dropdown
 */
interface LeafDropdownItem {
  icon?: JSX.Element;
  colOne: string;
  colTwo?: string;
  colThree?: string;
  idKeyOne: string;
  idKeyTwo?: string;
}

/**
 * Passed in to match keys within the InputData[]
 */
type Keys = {
  iconKey?: string;
  colOneKey: string;
  colTwoKey?: string;
  colThreeKey?: string;
  idKeyOne: string;
  idKeyTwo?: string;
};

interface InputData {
  [key: string]: any;
}

type SortMethods = "aToZ" | "zToA" | "";

export type Props = {
  inputData: InputData[];
  selectedIdentifier: any;
  options: {
    disabledOptions: string[];
    primaryOption?: string;
    sortMethod?: SortMethods;
  };
  functions: {
    handleSelectedOptionChange: Function;
    getIconFunction?: Function;
  };
  keys: Keys;
  dropdownAriaLabel: string;
};

export const getConvertedColItem = (item: InputData, key: string) => {
  if (!item[key]) return "";
  return item[key].toString();
};

const getDropdownItem = (item: string) => {
  if (!item) return "";
  return item.toUpperCase();
};

export const checkIconFunction = (iconFunction: Function | undefined, iconKey: string) => {
  if (!iconFunction || !iconKey) return "";
  return iconFunction(iconKey);
};

const checkColItem = (selectedIdentifier: any, key: string) => {
  if (!key) return "";
  return selectedIdentifier[key];
};

const renderDropdownOption = (item: LeafDropdownItem) => {
  const { colOne, icon = <></>, colTwo = "", colThree = "" } = item;
  return (
    <>
      <div>{icon}</div>
      <p>{getDropdownItem(colOne)}</p>
      <p>{getDropdownItem(colTwo)}</p>
      <p>{getDropdownItem(colThree)}</p>
    </>
  );
};

const getAriaLabel = (item: LeafDropdownItem) => {
  const { colOne, colTwo = "", colThree = "" } = item;
  return `${getDropdownItem(colOne)} ${getDropdownItem(colTwo)} ${getDropdownItem(colThree)}`;
};

export const sortListAlphabetically = (dropdownList: LeafDropdownItem[], primaryOption: string) => {
  return [...dropdownList].sort((a, b) => {
    if (a.idKeyOne === primaryOption) {
      return -1;
    } else if (b.idKeyOne === primaryOption) {
      return 1;
    }
    return a.colOne.localeCompare(b.colOne, undefined, { sensitivity: "base" });
  });
};

// Sorts data based on sortMethod or returns as is if sortMethod is ""
export const sortList = (dropdownList: LeafDropdownItem[], sortMethod: string) => {
  if (!sortMethod) return dropdownList;

  switch (sortMethod) {
    case "aToZ":
      return [...dropdownList].sort((a, b) => a.colOne.localeCompare(b.colOne, undefined, { sensitivity: "base" }));
    case "zToA":
      return [...dropdownList].sort((a, b) => b.colOne.localeCompare(a.colOne, undefined, { sensitivity: "base" }));
    default:
      return dropdownList;
  }
};

export const movePrimaryOptionToTop = (dropdownList: LeafDropdownItem[], primaryOption: string) => {
  const sortedList = [...dropdownList].sort((a, b) => {
    if (a.idKeyOne === primaryOption) {
      return -1;
    } else if (b.idKeyOne === primaryOption) {
      return 1;
    }
    return 0;
  });

  return sortedList;
};

export const convertToDropdownData = (
  inputData: InputData[],
  keys: Keys,
  primaryOption: string,
  sortMethod: SortMethods,
  getIconFunction: Function,
): LeafDropdownItem[] => {
  const { colOneKey, idKeyOne, iconKey = "", colTwoKey = "", colThreeKey = "", idKeyTwo = "" } = keys;
  const data = inputData
    ? inputData.map((item) => ({
        icon: checkIconFunction(getIconFunction, item[iconKey]),
        colOne: getConvertedColItem(item, colOneKey),
        colTwo: getConvertedColItem(item, colTwoKey),
        colThree: getConvertedColItem(item, colThreeKey),
        idKeyOne: getConvertedColItem(item, idKeyOne),
        idKeyTwo: getConvertedColItem(item, idKeyTwo),
      }))
    : [];

  const sortedData = sortList(data, sortMethod);

  const finalData = primaryOption ? movePrimaryOptionToTop(sortedData, primaryOption) : sortedData;
  return finalData;
};

const isSelected = (dropdownItem: LeafDropdownItem, selectedIdentifier: any, keys: Keys) => {
  return dropdownItem.idKeyOne === selectedIdentifier[keys.idKeyOne] ? "selected" : "";
};

export const returnListItemStyles = (
  sortedDropdownList: LeafDropdownItem[],
  dropdownItem: LeafDropdownItem,
  disabledOptions: string[],
  selectedIdentifier: any,
  keys: Keys,
  index: number,
) => {
  const firstItemCSS = index === 0 ? "leaf-dropdown__firstItem" : "";
  const lastItemCSS = index === sortedDropdownList.length - 1 ? "leaf-dropdown__lastItem" : "";
  const selected = isSelected(dropdownItem, selectedIdentifier, keys) ? "selected" : "";
  const disableOption = disabledOptions.includes(dropdownItem.idKeyOne) ? "disabled" : "";
  return `leaf-dropdown__item ${firstItemCSS} ${lastItemCSS} ${selected} ${disableOption}`;
};

const createSelectedData = (option: LeafDropdownItem, keys: Keys) => {
  const { colOneKey, idKeyOne, colTwoKey = "", colThreeKey = "", idKeyTwo = "" } = keys;
  const selectedData: any = {};

  if (option.colOne) selectedData[colOneKey] = option.colOne;
  if (option.colTwo) selectedData[colTwoKey] = option.colTwo;
  if (option.colThree) selectedData[colThreeKey] = option.colThree;
  if (option.idKeyOne) selectedData[idKeyOne] = option.idKeyOne;
  if (option.idKeyTwo) selectedData[idKeyTwo] = option.idKeyTwo;

  return selectedData;
};

const createInitialSelected = (selectedIdentifier: any, keys: Keys, getIconFunction: Function): LeafDropdownItem => {
  const { colOneKey, idKeyOne, iconKey = "", colTwoKey = "", colThreeKey = "", idKeyTwo = "" } = keys;
  return {
    icon: getIconFunction(selectedIdentifier[iconKey]),
    colOne: selectedIdentifier[colOneKey],
    colTwo: checkColItem(selectedIdentifier, colTwoKey),
    colThree: checkColItem(selectedIdentifier, colThreeKey),
    idKeyOne: selectedIdentifier[idKeyOne],
    idKeyTwo: checkColItem(selectedIdentifier, idKeyTwo),
  };
};

/**
 * Re-usable Leaf Dropdown component.
 *
 *
 * @param {Array} Props.inputData - An array of objects representing the data to be displayed in the dropdown.
 * @param {Object} Props.options - An object containing the configuration options for the dropdown.
 *   - {Array} disabledOptions - An array of strings. If matched with idKeyOne, that option will be disabled.
 *   - Optional {string} primaryOption - If primaryOption matches the idKeyOne, that option will be at the top of the dropdown.
 *   - Optional {string} primaryOption - Used to pass in diffenent sort methods "aToZ", "zToA", or no sorting "".
 * @param {Object} Props.selectedIdentifier - An object representing the initially selected option and the currently selected option.
 * @param {Object} Props.functions - An object containing callback functions.
 *   - {Function} handleSelectedOptionChange - A function to update the parent component with the currently selected option.
 *   - Optional {Function} getIconFunction - A function that should return an icon to display on the left side of each dropdown option.
 * @param {Object} Props.keys - An object specifying the keys to display from inputData.
 *   - Optional {string} iconKey - The key used to pass to getIconFunction.
 *   - {string} colOneKey - The key to display in the first column of the dropdown. - Also used to set options alphabetically.
 *   - Optional {string} colTwoKey - The key to display in the second column of the dropdown.
 *   - Optional {string} colThreeKey - The key to display in the third column of the dropdown.
 *   - {string} idKeyOne - Primary key used for identifying an option.
 *   - Optional {string} idKeyTwo - Secondary key used for identifying an option.
 * @returns {React.ReactElement} The rendered LeafDropdown component.
 * */
export const LeafDropdown = ({
  inputData,
  options,
  selectedIdentifier,
  functions,
  keys,
  dropdownAriaLabel,
}: Props): React.ReactElement => {
  const { getIconFunction, handleSelectedOptionChange } = functions;
  const { primaryOption = "", disabledOptions = [], sortMethod = "" } = options;
  const initialDropdownItem = createInitialSelected(selectedIdentifier, keys, getIconFunction || (() => {}));
  const dropdownList = inputData
    ? convertToDropdownData(inputData, keys, primaryOption, sortMethod, getIconFunction || (() => {}))
    : [initialDropdownItem];
  const firstSelected = initialDropdownItem
    ? renderDropdownOption(initialDropdownItem)
    : renderDropdownOption(dropdownList[0]);

  const [selectedOption, setSelectedOption] = useState<JSX.Element>(firstSelected);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const listRef = useRef<HTMLUListElement>(null);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  // listen for changes when the mobile business selector changes
  useEffect(() => {
    const _dropdownItem = createInitialSelected(selectedIdentifier, keys, getIconFunction ?? (() => {}));
    const _dropdownList = inputData
      ? convertToDropdownData(inputData, keys, primaryOption, sortMethod, getIconFunction ?? (() => {}))
      : [_dropdownItem];
    const _selectedOption = _dropdownItem
      ? renderDropdownOption(_dropdownItem)
      : renderDropdownOption(_dropdownList[0]);

    setSelectedOption(_selectedOption);
  }, [selectedIdentifier, getIconFunction, inputData, keys, primaryOption, sortMethod]);

  const handleDropdownClick = () => {
    if (dropdownList.length > 1) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const handleOptionClick = (option: LeafDropdownItem, index: number) => {
    if (disabledOptions.includes(option.idKeyOne)) return;
    setSelectedIndex(index);
    setFocusedIndex(index);
    handleSelectedOptionChange(createSelectedData(option, keys));
    setSelectedOption(renderDropdownOption(option));
    setIsDropdownOpen(false);
    dropdownRef.current?.focus();
    lockBodyScrolling(false);
  };

  const handleBackdropClick = () => {
    setFocusedIndex(selectedIndex);
    setIsDropdownOpen(false);
    lockBodyScrolling(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent, option: LeafDropdownItem) => {
    switch (event.key) {
      case "ArrowDown":
      case "Tab":
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex === null || prevIndex === inputData.length - 1 ? 0 : prevIndex + 1;
          const listItem = listRef.current?.children[nextIndex] as HTMLElement;
          listItem?.focus();
          return nextIndex;
        });
        event.preventDefault();
        break;
      case "ArrowUp":
        setFocusedIndex((prevIndex) => {
          const nextIndex = prevIndex === null || prevIndex === 0 ? inputData.length - 1 : prevIndex - 1;
          const listItem = listRef.current?.children[nextIndex] as HTMLElement;
          listItem?.focus();
          return nextIndex;
        });
        event.preventDefault();
        break;
      case "Enter":
      case " ":
        if (focusedIndex !== null && focusedIndex >= 0 && focusedIndex < dropdownList.length) {
          handleOptionClick(dropdownList[focusedIndex], focusedIndex);
        }
        event.preventDefault();
        break;
      case "Escape":
        setIsDropdownOpen(false);
        lockBodyScrolling(false);
        setFocusedIndex(selectedIndex);
        dropdownRef.current?.focus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isDropdownOpen && focusedIndex !== null && listRef.current) {
      const listItem = listRef.current.children[focusedIndex] as HTMLElement;
      listItem?.focus();
    }
  }, [focusedIndex, isDropdownOpen]);

  const getContainerStyles = () => {
    return dropdownList.length <= 1 ? "leaf-dropdown" : "leaf-dropdown leaf-dropdown--cursorPointer";
  };

  return (
    <div className={getContainerStyles()}>
      <button
        className="leaf-dropdown__button"
        onClick={() => handleDropdownClick()}
        data-testid="dropdown__button"
        ref={dropdownRef}
        tabIndex={0}
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
      >
        {selectedOption}
        <span hidden={dropdownList.length <= 1} data-testid="dropdown__carrot">
          <IconDropdownCarrot open={isDropdownOpen} />
        </span>
      </button>

      {isDropdownOpen && (
        <span className="leaf-dropdown__pointer-triangle">
          <ul className="leaf-dropdown__list" role="listbox" tabIndex={-1} aria-label={dropdownAriaLabel} ref={listRef}>
            {dropdownList.map((dropdownItem: LeafDropdownItem, index: number) => (
              <li
                onClick={() => handleOptionClick(dropdownItem, index)}
                onKeyDown={(event) => handleKeyDown(event, dropdownItem)}
                role="option"
                aria-label={getAriaLabel(dropdownItem)}
                aria-selected={isSelected(dropdownItem, selectedIdentifier, keys) ? "true" : "false"}
                tabIndex={0}
                key={`${dropdownItem.idKeyTwo}-${dropdownItem.idKeyOne}`}
                id={`${dropdownItem.idKeyTwo}-${dropdownItem.idKeyOne}`}
                data-testid={`leaf-dropdown-option ${dropdownItem.idKeyOne}`}
                className={returnListItemStyles(
                  dropdownList,
                  dropdownItem,
                  disabledOptions,
                  selectedIdentifier,
                  keys,
                  index,
                )}
              >
                {renderDropdownOption(dropdownItem)}
              </li>
            ))}
          </ul>
          <LeafBackdrop handleBackdropClick={() => handleBackdropClick()} variant={"invisible"} />
        </span>
      )}
    </div>
  );
};
