import { useState } from "react";
import { IconClose } from "../../icons/IconClose/IconClose";
import { IconSearch } from "../../icons/IconSearch/IconSearch";
import "./LeafSearchDropdown.scss";

type Props = {
  handleSelect: Function;
  optionsMap: { [key: string]: string };
};

/**
 * Common input with searchable dropdown.
 *
 * The optionsMap parameter is an object where each key represents a unique identifier
 * and each corresponding value represents the value displayed in the dropdown.
 *
 * If the input matches any part of the optionsMap "corresponding key value" it sets the key as the selectedKey.
 *
 * See AFF_MARKET_MAP in common/constants for an example.
 *
 * @param handleSelect Function
 * @param optionsMap Object containing key-value pairs of identifiers and displayed value
 */
export const LeafSearchDropdown = ({ handleSelect, optionsMap }: Props) => {
  const [inputText, setInputText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filteredOptions = Object.entries(optionsMap).filter(([_, item]) =>
    item.toLowerCase().includes(inputText.toLowerCase()),
  );

  const handleSelectOption = (item: string) => {
    const selectedKey = Object.keys(optionsMap).find((key) => optionsMap[key] === item);
    if (selectedKey) {
      setInputText(optionsMap[selectedKey]);
      setIsOpen(false);
      handleSelect(selectedKey);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputText(value);
    setIsOpen(value !== "");
    if (optionsMap[value]) {
      setInputText(optionsMap[value]);
      handleSelect(value);
      setIsOpen(false);
    } else {
      handleSelect(value);
    }
  };

  const handleDropdownToggle = () => {
    setInputText("");
    setIsOpen(!isOpen);
  };

  return (
    <div className="searchableDropdown">
      <div className="searchableDropdown__input-container">
        <input
          id="searchable-dropdown"
          name="searchable-dropdown"
          onChange={handleInputChange}
          value={inputText}
          placeholder="Search locale, aff, or country code"
          className="searchableDropdown__input"
        />
        <span
          className="searchableDropdown__toggle-btn"
          onClick={handleDropdownToggle}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDropdownToggle();
            }
          }}
        >
          {inputText === "" ? <IconSearch /> : <IconClose height="1.25rem" width="1rem" />}
        </span>
      </div>
      {isOpen && (
        <ul className="searchableDropdown__options-list" role="listbox" data-tooltip-hidden>
          {filteredOptions.map(([key, item]) => (
            <li
              tabIndex={0}
              key={key}
              onClick={() => handleSelectOption(item)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSelectOption(item);
                }
              }}
              className="searchableDropdown__option"
              role="option"
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
