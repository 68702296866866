/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./PeriodSelector.scss";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import { storedLocale } from "../impersonation/util";
import { getLongPeriodFormat, getPerformanceYear, getShortPeriodFormat, getYear } from "../../common/util/period";
import { IconCarrot } from "../../common/icons/IconCarrot/IconCarrot";
import TEALIUM from "../../common/enums/tealium-enums";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateNextPeriodFlag, updatePeriod } from "../../reducers/period";
import defaultContent from "../../content/periodSelector";
import commonContent from "../../content/common";
import { WheelSpinner } from "./WheelSpinner/WheelSpinner";
import { BottomDrawer } from "./BottomDrawer/BottomDrawer";

type PeriodSelectorProps = {
  locale?: string;
  isMobile?: boolean;
};

export const PeriodSelector = ({ isMobile = false }: PeriodSelectorProps) => {
  const { selectedPeriod, periods, formattedPeriods, selectorType } = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();
  const locale = storedLocale();
  const { t } = useTranslation(["periodSelector", "common"]);
  const [isDesktopPeriodDropdownVisible, setIsDesktopPeriodDropdownVisible] = useState(false);
  const [isMobileBottomDrawerOpen, setIsMobileBottomDrawerOpen] = useState(false);
  const [wheelSpinnerSelectedValue, setWheelSpinnerSelectedValues] = useState(selectedPeriod);
  const [isPerformanceYear, setIsPerformanceYear] = useState(false);
  const periodSelectorListRef = useRef<HTMLUListElement>(null);
  const periodSelectorButtonRef = useRef<HTMLButtonElement>(null);
  const [selectedFromDropdown, setSelectedFromDropdown] = useState(false);

  useEffect(() => {
    setWheelSpinnerSelectedValues(selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedFromDropdown === true) {
      handleTealiumTrackClickCategoryPeriodSelector(selectedPeriod);
      setSelectedFromDropdown(false);
    }
  }, [selectedFromDropdown]);

  useEffect(() => {
    setIsPerformanceYear(selectorType === SELECTOR_TYPE.PY);
  }, [selectorType]);

  /**
   * when a period is selected and the dropdown is open,
   * scroll to the current selected period
   */
  useEffect(() => {
    if (periodSelectorListRef && periodSelectorListRef.current) {
      // find index of current period in list
      const currentSelectedPeriod = periodSelectorListRef.current.children[
        periods.indexOf(selectedPeriod)
      ] as HTMLLIElement;

      // if currentSelectedPeriod exists, scroll to it otherwise scroll to first element as fallback
      periodSelectorListRef.current.scrollTop = (currentSelectedPeriod && currentSelectedPeriod.offsetTop) || 0;
    }
  }, [isDesktopPeriodDropdownVisible]);

  const handleWheelSpinnerValueChange = (newPeriod: string) => {
    setWheelSpinnerSelectedValues(newPeriod);
  };

  const handleBackdropClick = () => {
    setIsMobileBottomDrawerOpen(false);
    setIsDesktopPeriodDropdownVisible(false);
  };

  const handlePeriodSelectorClick = () => {
    setIsDesktopPeriodDropdownVisible(!isDesktopPeriodDropdownVisible);
    setIsMobileBottomDrawerOpen(!isMobileBottomDrawerOpen);
  };

  const capitalizeFirstLetter = (period: string) => {
    return period.charAt(0).toUpperCase() + period.slice(1);
  };

  /**
   * Formats selected period - JAN 2022 or PY22
   * @param period
   * @returns
   */
  const formatPeriodForSelector = (period: string) => {
    const PY = `${t("performanceYearShort", commonContent["performanceYearShort"], {
      ns: "common",
    })}${getPerformanceYear(period).slice(2, 4)}`;
    return isPerformanceYear ? PY : capitalizeFirstLetter(getShortPeriodFormat(period, locale));
  };
  const formatPeriodsForSelectorList = (period: string) => {
    return isPerformanceYear
      ? `${t("performanceYearShort", commonContent["performanceYearShort"], { ns: "common" })} ` +
          getYear(period, locale)
      : capitalizeFirstLetter(getLongPeriodFormat(period, locale));
  };

  const getPeriodsForSpinner = () => {
    if (isPerformanceYear) {
      const pYPeriods = formattedPeriods.map((period) => period.display);
      return pYPeriods;
    }
    return periods;
  };

  const handlePeriodSelectorFromMobile = () => {
    updateSelector(wheelSpinnerSelectedValue);
    if (periodSelectorButtonRef && periodSelectorButtonRef.current) {
      periodSelectorButtonRef.current.focus();
    }
  };

  const updateSelector = (period: string) => {
    dispatch(updateNextPeriodFlag({ isNextPeriod: true }));
    dispatch(updatePeriod({ period }));
    setWheelSpinnerSelectedValues(period);

    document.dispatchEvent(
      new CustomEvent("period-changed", {
        bubbles: true,
        composed: true,
        detail: {
          period: period,
        },
      }),
    );
    setIsDesktopPeriodDropdownVisible(false);
    setIsMobileBottomDrawerOpen(false);
  };

  const stepToNewPeriod = (event: Event) => {
    const currentPeriod = periods.indexOf(selectedPeriod);
    if (event.type === "next") {
      if (periods[currentPeriod - 1]) {
        updateSelector(periods[currentPeriod - 1]);
      }
    }

    if (event.type === "prev") {
      if (periods[currentPeriod + 1]) {
        updateSelector(periods[currentPeriod + 1]);
      }
    }
  };

  const onKeyDownEvent = (event: any) => {
    if (event.key === "Enter" || event.key === " ") {
      updateSelector(event.target.value.toString());
      if (periodSelectorButtonRef && periodSelectorButtonRef.current) {
        periodSelectorButtonRef.current.focus();
      }
    }
  };

  /**
   * Builds a dropdown list of selectable dates. Either PY or Periods
   * @returns `DateList` element
   */
  const DateList = () => {
    if (isPerformanceYear) {
      return (
        <ul
          className="period-selector__list"
          aria-label={`${t("bonusPeriodList", defaultContent["bonusPeriodList"])}`}
          role="listbox"
          id="period-selector__list"
          ref={periodSelectorListRef}
        >
          {formattedPeriods.map((p, index) => (
            <li
              key={p.display}
              id={`period-selector__list-item-${index}`}
              onClick={() => {
                updateSelector(p.year);
                setSelectedFromDropdown(true);
              }}
              className={
                selectedPeriod === p.year ? "period-selector__list-item--active" : "period-selector__list-item"
              }
              value={p.year}
              role="option"
              aria-selected={selectedPeriod === p.year}
              tabIndex={0}
              onKeyDown={onKeyDownEvent}
            >
              {formatPeriodsForSelectorList(p.display)}
            </li>
          ))}
        </ul>
      );
    }
    return (
      <ul
        className="period-selector__list"
        id="period-selector__list"
        ref={periodSelectorListRef}
        aria-label={`${t("bonusPeriodList", defaultContent["bonusPeriodList"])}`}
        role="listbox"
      >
        {periods.map((p, index) => (
          <li
            key={p}
            id={`period-selector__list-item-${index}`}
            onClick={() => {
              updateSelector(p);
              setSelectedFromDropdown(true);
            }}
            className={selectedPeriod === p ? "period-selector__list-item--active" : "period-selector__list-item"}
            value={p}
            role="option"
            aria-selected={selectedPeriod === p}
            tabIndex={0}
            onKeyDown={onKeyDownEvent}
          >
            {formatPeriodsForSelectorList(p)}
          </li>
        ))}
      </ul>
    );
  };

  /**
   *
   * @returns
   */
  const SelectedDate = () => {
    return (
      <button
        className="period-selector__period"
        id={`period-selector__period--${isMobile === true ? "mobile" : "desktop"}`}
        onClick={handlePeriodSelectorClick}
        aria-haspopup="listbox"
        aria-expanded={isDesktopPeriodDropdownVisible || isMobileBottomDrawerOpen}
        aria-label={`${t("selected", defaultContent["selected"])} ${formatPeriodsForSelectorList(selectedPeriod)}`}
        ref={periodSelectorButtonRef}
      >
        {formatPeriodForSelector(selectedPeriod)}
      </button>
    );
  };

  const handleLeftPeriodStepperClickEvent = (step: string) => {
    stepToNewPeriod(new Event(step));
    handleTealiumTrackClickActionPeriodSelectorPrev();
  };

  const handleRightPeriodStepperClickEvent = (step: string) => {
    stepToNewPeriod(new Event(step));
    handleTealiumTrackClickActionPeriodSelectorNext();
  };

  const PeriodStepper = ({ step }: { step: string }) => {
    const previousDisabled = selectedPeriod === periods[periods.length - 1];
    const nextDisabled = selectedPeriod === periods[0];
    return (
      <div className="period-selector__arrow">
        {step === "prev" ? (
          <button
            className="period-selector__arrow-button"
            aria-label={`${t("previousArrowLabel", defaultContent["previousArrowLabel"])}`}
            onClick={() => handleLeftPeriodStepperClickEvent(step)}
          >
            <IconCarrot orientation="left" color="black" disabled={previousDisabled} aria-disabled={previousDisabled} />
          </button>
        ) : (
          <button
            className="period-selector__arrow-button"
            aria-label={`${t("nextArrowLabel", defaultContent["nextArrowLabel"])}`}
            onClick={() => handleRightPeriodStepperClickEvent(step)}
          >
            <IconCarrot orientation="right" color="black" disabled={nextDisabled} aria-disabled={nextDisabled} />
          </button>
        )}
      </div>
    );
  };

  const BackDrop = ({ variant }: { variant?: "invisible" }) => {
    if (isMobileBottomDrawerOpen) {
      const baseStyle = "period-selector__backdrop";
      const variantStyle = ` ${baseStyle}--${variant}`;
      const classname = variant ? baseStyle + variantStyle : baseStyle;
      return <div className={classname} onClick={handleBackdropClick}></div>;
    } else {
      return <></>;
    }
  };

  const MobilePeriodSelector = () => {
    return (
      <>
        <BottomDrawer show={isMobileBottomDrawerOpen} setShow={setIsMobileBottomDrawerOpen}>
          <WheelSpinner
            column={getPeriodsForSpinner()}
            selectedValue={wheelSpinnerSelectedValue}
            onChange={handleWheelSpinnerValueChange}
            onSave={handlePeriodSelectorFromMobile}
            formatter={formatPeriodsForSelectorList}
            isOpen={isMobileBottomDrawerOpen}
          />
        </BottomDrawer>
        <BackDrop />
      </>
    );
  };

  const DesktopPeriodSelector = () => {
    const classname = isDesktopPeriodDropdownVisible
      ? "period-selector__list-container"
      : "period-selector__list-container--hidden";
    return (
      <>
        <div className={classname}>
          <DateList />
        </div>
        <BackDrop variant={"invisible"} />
      </>
    );
  };

  const DisplayPeriodSelector = () => {
    return isMobile ? <MobilePeriodSelector /> : <DesktopPeriodSelector />;
  };

  const handleTealiumTrackClickCategoryPeriodSelector = (selectedPeriod: string) => {
    track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_CATEGORY, "", "date_selector", "", selectedPeriod);
  };

  const handleTealiumTrackClickActionPeriodSelectorNext = () => {
    track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, "", "date_selector", "", "next_period");
  };

  const handleTealiumTrackClickActionPeriodSelectorPrev = () => {
    track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, "", "date_selector", "", "previous_period");
  };

  return (
    <div className="period-selector">
      <div className="period-selector__container">
        <div className="period-selector__nav-items">
          <PeriodStepper step="prev" />
          <div
            className="period-selector__selected-period"
            id={`period-selector__selected-period--${isMobile === true ? "mobile" : "desktop"}`}
          >
            <SelectedDate />
            <DisplayPeriodSelector />
          </div>

          <PeriodStepper step="next" />
        </div>
      </div>
    </div>
  );
};
