import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/analytics";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import LeafTileTitleLink from "../../../../common/leaf/LeafTileTitleLink/LeafTileTitleLink";
import "./GroupGrowthTrackingTile.scss";
import { ROUTE_PATHS } from "../../../../common/enums/routing-enums";
import navigationContent from "../../../../content/navigation";
import annualPerformanceContent from "../../../../content/annualPerformanceDashboard";
import comingSoonContent from "../../../../content/comingSoonModal";
import TEALIUM from "../../../../common/enums/tealium-enums";

interface GgTrackingTileProps {
  isLoading: boolean;
  baselineMonths: string;
  trackingMonths: string;
  trackingBonusAmount: string;
  isComingSoon: boolean;
  title: string;
  titleRoutePath: ROUTE_PATHS;
  countLabel: string;
  isDisabled: boolean;
}

export const GroupGrowthTrackingTile = ({
  baselineMonths,
  trackingMonths,
  trackingBonusAmount,
  isComingSoon,
  title,
  isLoading,
  titleRoutePath,
  countLabel,
  isDisabled,
}: GgTrackingTileProps) => {
  const { t } = useTranslation(["annualPerformanceDashboard", "navigation", "comingSoonModal"]);
  const qLabel = `${t(countLabel, annualPerformanceContent[countLabel])}`;

  const trackTileLinkClick = () => {
    const incentiveName: string = navigationContent["linkTitle"][title];
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      incentiveName.replace("+", ""),
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "trackingTile",
    );
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div className="ggTrackingTile__title-link">
        <LeafTileTitleLink
          title={`${t(`linkTitle.${title}`, navigationContent["linkTitle"][title], {
            ns: "navigation",
          })}`}
          link={titleRoutePath}
          disable={isDisabled}
          onClick={trackTileLinkClick}
        />
      </div>
      <LeafTileDivider />
      {!isComingSoon ? (
        <div className="ggTrackingTile__content-container">
          <div className="ggTrackingTile__content-container-row">
            <p>{t("baselineSubTitle", annualPerformanceContent["baselineSubTitle"])}</p>
            <span className="ggTrackingTile__content-container-row--bold">
              {baselineMonths !== "--" ? `${baselineMonths} ${qLabel}` : baselineMonths}
            </span>
          </div>
          <div className="ggTrackingTile__content-container-row">
            <p>{t("trackingSubtitle", annualPerformanceContent["trackingSubtitle"])}</p>
            <span className="ggTrackingTile__content-container-row--bold">
              {trackingMonths !== "--" ? `${trackingMonths} ${qLabel}` : trackingMonths}
            </span>
          </div>
          <div className="ggTrackingTile__content-container-row">
            <p>{t("trackingBonusSubtitle", annualPerformanceContent["trackingBonusSubtitle"])}</p>
            <span className="ggTrackingTile__content-container-row--bold">{trackingBonusAmount}</span>
          </div>
        </div>
      ) : (
        <div className="ggTrackingTile__comingSoon">
          <h2 className="ggTrackingTile__comingSoon">
            {t("header", comingSoonContent["header"], { ns: "comingSoonModal" })}
          </h2>
          <div>{t("body", comingSoonContent["body"], { ns: "comingSoonModal" })}</div>
        </div>
      )}
    </LeafTile>
  );
};
