import {
  MonthlyDashIntlBusinessesResponse,
  MonthlyDashIntlBusinessesData,
  IntlBusiness,
} from "../../../../common/interfaces/monthlyPerformanceDash";
import { Business } from "../../../BusinessSelector/BusinessSelectorAPIDataLayer";
import { storedLocale } from "../../../impersonation/util";

export const mergeIntlBusinessesData = (
  payloadData: MonthlyDashIntlBusinessesResponse,
  businessSelectorData: Business[],
): MonthlyDashIntlBusinessesData => {
  const countryDisplayName = new Intl.DisplayNames(storedLocale(), { type: "region" });
  const enrichedBusinessData = payloadData.Businesses.map((intlBusiness) => {
    const original = businessSelectorData.find(
      (business: Business) => business.aff === intlBusiness.Aff && business.abo.slice(1) === intlBusiness.Abo,
    );
    if (original && original.aff !== "360") {
      const countryName = original.isoCountry ? countryDisplayName.of(original.isoCountry) : "";
      return {
        aff: intlBusiness.Aff,
        abo: intlBusiness.Abo,
        aboName: original.aboName.toLowerCase(),
        aboLocalName: original.aboLocalName,
        isoCountry: original.isoCountry,
        isoLanguage: original.isoLanguage,
        privacyFlag: original.privacyFlag,
        active: original.active,
        isPrimary: original.isPrimary,
        groupPV: intlBusiness.GroupPv,
        qualifiedLegs: intlBusiness.QualifiedLegs,
        bonusPercent: intlBusiness.BonusPercent,
        countryName,
      };
    }
    return null;
  })
    .filter(Boolean)
    .sort((a: any, b: any) => a["countryName"].localeCompare(b["countryName"]));

  const primaryIndex = enrichedBusinessData.findIndex((obj: any) => obj.isPrimary);

  // If a primary object is found, move it to the first position
  if (primaryIndex !== -1) {
    const primaryObject = enrichedBusinessData[primaryIndex];
    enrichedBusinessData.splice(primaryIndex, 1);
    enrichedBusinessData.unshift(primaryObject);
  }

  return {
    Businesses: enrichedBusinessData as IntlBusiness[],
    CallResult: payloadData.CallResult,
    Error: payloadData.Error,
  };
};
