import { useTranslation } from "react-i18next";
import LeafIncentiveProgress from "../../../../common/leaf/LeafIncentiveProgress/LeafIncentiveProgress";
import defaultContent from "../../../../content/bfiIncentiveProgress";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { earningAPIResponse, requirementAPIResponse } from "./MockData";

type Props = {
  awardCode: number;
};

const BFIIncentiveProgress = (props: Props) => {
  const { t } = useTranslation(["bfiIncentiveProgress"]);
  const { awardCode } = props;

  // todo: API integration - replace with RTK query
  const { status } = earningAPIResponse;
  const { requiredCount, metCount } = requirementAPIResponse;
  const show = [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.HELD].includes(status);

  const getTrackIconAriaLabel = (index: number) => {
    const number = index + 1;

    if (number <= requiredCount) {
      return `${t("bronzeFoundationRequirement", defaultContent["bronzeFoundationRequirement"], { number })}`;
    }

    return `${t("trackingBronzeFoundationRequirement", defaultContent["trackingBronzeFoundationRequirement"], {
      number,
    })}`;
  };

  return (
    <LeafIncentiveProgress
      requiredCount={requiredCount}
      metCount={metCount}
      isLoading={false}
      getTrackIconAriaLabel={getTrackIconAriaLabel}
      show={show}
    />
  );
};
export default BFIIncentiveProgress;
