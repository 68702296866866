import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/analytics";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileTitleLink from "../../../../../common/leaf/LeafTileTitleLink/LeafTileTitleLink";
import defaultContent from "../../../../../content/annualPerformanceDashboard";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import "./FaaTrackingTile.scss";
import { AWARD_PROGRESS } from "../../../../../common/enums/award-progress-codes";
import { storedLocale } from "../../../../impersonation/util";
import { AnnualTrackingFAATile } from "../../../../../common/interfaces/annualPerformanceDashboard";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { AwardProgressBadge } from "../../../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { getLongPeriodFormat } from "../../../../../common/util/period";

type Props = {
  tileData: AnnualTrackingFAATile;
  isLoading: boolean;
};

export const FaaTrackingTile = ({ tileData, isLoading }: Props) => {
  const { DbrStatus, FoundersPlatinumLegs, FaaPoints, FaaPercent, TrackingBonus, LastMonthProcessed } = tileData;
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const locale = storedLocale();
  const dbrBadge = DbrStatus ? AWARD_PROGRESS.TRACKING : AWARD_PROGRESS.NOT_TRACKING;
  const desktopFaaTitle = t("foundersAchievementAwardTracking", defaultContent["foundersAchievementAwardTracking"]);
  const mobileFaaTitle = t("faaTracking", defaultContent["faaTracking"]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const faaTitle = windowWidth >= 620 ? desktopFaaTitle : mobileFaaTitle;

  const trackTileLinkClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "Founders Achievement Award Tracking",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "trackingTile",
    );
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div className="FaaTrackingTile">
        <LeafTileTitleLink title={faaTitle} link={ROUTE_PATHS.FAA} disable={!DbrStatus} onClick={trackTileLinkClick} />
        <p className="FaaTrackingTile__subtitle">
          {t("lastMonthProcessed", defaultContent["lastMonthProcessed"])}:{" "}
          <span>{getLongPeriodFormat(LastMonthProcessed.toString(), locale)}</span>
        </p>
        <LeafTileDivider />
        <section className="FaaTrackingTile__data--section">
          <div className="FaaTrackingTile__data--row">
            <p className="FaaTrackingTile__dbrStatus">{t("dbrStatus", defaultContent["dbrStatus"])}</p>
            <span>
              <AwardProgressBadge status={dbrBadge} />
            </span>
          </div>
          <div className="FaaTrackingTile__data--row">
            <p>{t("fPlatLegs", defaultContent["fPlatLegs"])}</p>
            <span>{DbrStatus ? FoundersPlatinumLegs : "--"}</span>
          </div>
          <div className="FaaTrackingTile__data--row">
            <p>{t("faaPoints", defaultContent["faaPoints"])}</p>
            <span>{DbrStatus ? FaaPoints : "--"}</span>
          </div>
          <div className="FaaTrackingTile__data--row">
            <p>{t("faaMultiplier", defaultContent["faaMultiplier"])}</p>
            <span>{DbrStatus ? FaaPercent : "--"}</span>
          </div>
          <div className="FaaTrackingTile__data--row">
            <p>{t("trackingBonus", defaultContent["trackingBonus"])}</p>
            <span>{DbrStatus ? TrackingBonus : "--"}</span>
          </div>
        </section>
      </div>
    </LeafTile>
  );
};
