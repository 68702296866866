import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

const cardActionMapper: { [key: string]: (sponsorType: string) => string } = {
  call: (sponsorType) => `call_${sponsorType}`,
  mobile: (sponsorType) => `copy_${sponsorType}_mobile`,
  email: (sponsorType) => `email_${sponsorType}`,
  message: (sponsorType) => `message_${sponsorType}`,
};

const sponsorTypes: { [key: string]: string } = {
  sponsor: "sponsor",
  fosterSponsor: "foster",
  internationalSponsor: "international_sponsor",
  platinumFosterSponsor: "foster_platinum",
  platinumAndSponsor: "sponsor_platinum",
  platinumSponsor: "platinum",
};

export const cardActionClickTrack = (clickAction: string, sponsorType: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.ABO_PROFILE,
    "",
    cardActionMapper[clickAction](sponsorTypes[sponsorType]),
  );
};
