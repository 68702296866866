import { useState } from "react";
import { LeafBannerToast } from "../../../common/leaf/LeafBannerToast/LeafBannerToast";
import { IconLightning } from "../../../common/icons/IconLightning/IconLightning";
import "./BronzeBannerToast.scss";

type EligibilityAPIData = {
  period: number;
  qualified: boolean;
  payment: number;
  paymentCurrency: string;
  status: string;
};

type Props = {
  isRestartProgram: boolean;
  message: string;
  months: EligibilityAPIData[];
};

const BronzeBannerToast = (props: Props) => {
  const { isRestartProgram = false, message, months } = props;

  const show = isRestartProgram === true && months.length > 1;
  const [showBanner, setShowBanner] = useState(show);

  const hideBanner = () => {
    setShowBanner(false);
  };

  return (
    <div className="bronze-banner-toast">
      <LeafBannerToast theme={"blue"} show={showBanner} closeFunction={hideBanner}>
        <p className="bronze-banner-toast__message">
          <span>
            <IconLightning />
          </span>
          <p>{message}</p>
        </p>
      </LeafBannerToast>
    </div>
  );
};

export default BronzeBannerToast;
