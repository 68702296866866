import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import { NavDropdown } from "../../../common/interfaces/routing";
import Dropdown from "../NavigationDropdown/NavigationDropdown";
import defaultContent from "../../../content/navigation";
import { LinkUtil } from "../../../common/util/LinkUtil";
import "./MenuItems.scss";
import TEALIUM from "../../../common/enums/tealium-enums";

export type ActiveMenuItems = {
  activeMenu: string;
  activeDropdownItem: string;
};

type MenuItemsProps = {
  menu: NavDropdown;
  activeItems: ActiveMenuItems;
};

const MenuItems = ({ menu, activeItems }: MenuItemsProps) => {
  const { t } = useTranslation(["navigation"]);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent | TouchEvent) => {
      if (isDropdownActive && ref.current && !ref.current.contains(event.target as Node)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [isDropdownActive]);

  const onMouseEnter = () => {
    setIsDropdownActive(true);
  };

  const closeDropdownMenu = () => {
    setIsDropdownActive(false);
  };

  const menuClass = `menu-items ${activeItems.activeMenu === menu.key ? "menu-items__active" : ""}`;

  const handleTealiumTrackClickAction = (menu: NavDropdown) => {
    track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, "", menu.key, "", menu.label);
  };

  const renderDropdown = () => (
    <>
      <button
        type="button"
        aria-haspopup="menu"
        aria-expanded={isDropdownActive ? "true" : "false"}
        onClick={() => setIsDropdownActive(!isDropdownActive)}
        className={menu.class}
      >
        {`${t(menu.key, String(defaultContent[menu.key]))}`}
      </button>

      <Dropdown
        subMenus={menu.items}
        isDropdownActive={isDropdownActive}
        closeDropdownOnSelection={closeDropdownMenu}
        activeItem={activeItems.activeDropdownItem}
      />
    </>
  );

  const renderLink = () => (
    <LinkUtil to={menu} id={menu.key}>
      <div onClick={() => handleTealiumTrackClickAction(menu)}>{`${t(
        menu.label,
        String(defaultContent[menu.label]),
      )}`}</div>
    </LinkUtil>
  );

  return (
    <li
      id={menu.key}
      className={menuClass}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={closeDropdownMenu}
      data-text={menu.label}
    >
      {menu.items.length > 0 ? renderDropdown() : renderLink()}
    </li>
  );
};

export default MenuItems;
