import "react-tooltip/dist/react-tooltip.css";
import "./LeafTooltip.scss";
import { Tooltip } from "react-tooltip";
import { ReactElement } from "react";

type Props = {
  content?: string;
  children?: ReactElement;
  id: string;
  icon: ReactElement;
};

const LeafTooltip = (props: Props) => {
  const { content, id, icon, children } = props;

  return (
    <div className="leaf-tooltip" data-testid={`leafTooltip`}>
      <div data-tooltip-id={id}>{icon}</div>
      <Tooltip
        id={id}
        content={content}
        children={children}
        className="leaf-tooltip__tooltip"
        disableStyleInjection={"core"}
      />
    </div>
  );
};

export default LeafTooltip;
