import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

const routeActionMapper: { [key: string]: string } = {
  newCustomer: "route_from_new_customers",
  totalCustomers: "route_from_total_customers",
  customerPV: "route_from_cpv",
  order: "route_from_total_orders",
};

export const routeOriginTrack = (route: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CML_OVERVIEW,
    "",
    routeActionMapper[route],
  );
};
