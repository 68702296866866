import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import defaultContent from "../../../../../content/annualPGG";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { IconPlainTable } from "../../../../../common/icons/IconPlainTable/IconPlainTable";
import { MultiplierDetail } from "../../../../../common/components/MultiplierDetail/MultiplierDetail";
import { setModalBtnFocus } from "../MultiplierDetailsUtil";
import { PQModal } from "../PQModal/PQModal";
import { FetchPQMonthData } from "../MultiplierDetailsDataLayer";

interface Props {
  currentPQs: string;
  prevOpenRef: MutableRefObject<boolean>;
  selectedPeriod: string;
}

export const CurrentPQ = ({ currentPQs, prevOpenRef, selectedPeriod }: Props) => {
  const { t } = useTranslation(["annualPGG"]);
  const [showCurrentPqModal, setShowCurrentPqModal] = useState(false);
  const currentPqModalButton = useRef<HTMLButtonElement>(null);
  const [period, setPeriod] = useState<string>("");
  const [skipRequest, setSkipRequest] = useState<boolean>(true);

  // This prevents FetchMultiplerDetailsData from making request before skipRequest is updated.
  useEffect(() => {
    setPeriod(selectedPeriod);
    setSkipRequest(!currentPQs || currentPQs === "0");
  }, [currentPQs, selectedPeriod]);

  const { pqMonthData, isLoading, errorStatus, refetch } = FetchPQMonthData(period, skipRequest);

  const currentFqModalToggle = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `current_PQ_months_${!showCurrentPqModal ? "open" : "close"}`,
      "view_details_modal",
    );
    setShowCurrentPqModal(!showCurrentPqModal);
    setModalBtnFocus(currentPqModalButton, prevOpenRef, showCurrentPqModal);
  };

  const getButtonInfo = () => {
    if (skipRequest) return null;

    return {
      callback: currentFqModalToggle,
      ref: currentPqModalButton,
      text: `${t("multiplierDetails.viewDetails", defaultContent.multiplierDetails.viewDetails)}`,
      icon: <IconPlainTable />,
    };
  };

  return (
    <>
      <MultiplierDetail
        title={t("multiplierDetails.pqMonths", defaultContent.multiplierDetails.pqMonths, { pq: currentPQs })}
        subtitle={`${t("multiplierDetails.currentYtd", defaultContent.multiplierDetails.currentYtd)}`}
        description={""}
        buttonInfo={getButtonInfo()}
      />
      <PQModal
        toggleModal={currentFqModalToggle}
        displayModal={showCurrentPqModal}
        pqMonths={pqMonthData.PQs}
        header={"currentYtd"}
        performanceYear={selectedPeriod}
        isLoading={isLoading}
        showBooster={pqMonthData.ShowBoosterRequirement}
        errorStatus={errorStatus}
        refetchCallback={refetch}
      />
    </>
  );
};
