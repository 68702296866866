import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updatePeriod } from "../../../reducers/period";
import GCRPGGLit from "./GCRPGGLit";
import GCRPGG from "./GCRPGG";

export default function GCRPGGSwitcher() {
  const { pgg, latestFiscalYearAvailable } = useAppSelector((state) => state.boot.configuration);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showOldPggFromQueryParam = queryParams.get("showOldPgg") === "true";
  const dispatch = useAppDispatch();

  const { isComingSoon } = useAppSelector((state) => state.boot.shellConfig);

  if (showOldPggFromQueryParam || pgg.showOldPgg) {
    if (isComingSoon) {
      dispatch(updatePeriod({ period: `${latestFiscalYearAvailable}08` }));
    }
    return <GCRPGGLit />;
  }

  return <GCRPGG />;
}
