export const PERSONAL_PV = "ppv";
export const GROUP_PV = "gpv";
export const RUBY_PV = "rubyPV";
export const LEADERSHIP_PV = "leadershipPV";
export const VCS_PV = "vcsPV";
export const VCS_PERCENT = "vcsPercent";
export const QCPV = "qcPV";
export const PERSONAL_ORDERS = "personalOrders";
export const GROUP_ORDERS = "groupOrders";
export const NEW_ABO = "newABOs";
export const TOTAL_ABOS = "totalABOs";
export const NEW_CUSTOMERS = "newCustomers";
export const CUSTOMER_ORDERS = "customerOrders";
export const TOTAL_CUSTOMERS = "totalCustomers";

export const PERF_HISTORY_KPIS = [
  PERSONAL_PV,
  GROUP_PV,
  RUBY_PV,
  LEADERSHIP_PV,
  VCS_PV,
  VCS_PERCENT,
  QCPV,
  PERSONAL_ORDERS,
  GROUP_ORDERS,
  NEW_ABO,
  TOTAL_ABOS,
  NEW_CUSTOMERS,
  CUSTOMER_ORDERS,
  TOTAL_CUSTOMERS,
];
