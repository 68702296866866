const BronzeBuilder = () => {
  const awardCode = 931;
  const restart1AwardCode = 949;
  const restart2AwardCode = 953;
  const fixedPaymentAwardCode = 933;

  return <>Bronze Builder</>;
};

export default BronzeBuilder;
