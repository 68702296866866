import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import defaultContent from "../../../../../content/annualPGG";
import { createMultiplierDetailsTableRows } from "../MultiplierDetailsUtil";
import { getPerformanceYearShort } from "../../../../../common/util/period";
import LeafTable from "../../../../../common/leaf/LeafTable/LeafTable";
import { PQ } from "../../../../../common/interfaces/groupGrowthIncentives";
import "./PQModal.scss";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../../../../../common/components/ErrorTile/ErrorTile";
import { IconShield, BADGE_SIZE, BADGE_TYPE } from "../../../../../common/icons/IconShield/IconShield";

interface PQModalProps {
  toggleModal: Function;
  displayModal: boolean;
  pqMonths: PQ[];
  header: "currentYtd" | "previousYtd";
  performanceYear: string;
  isLoading: boolean;
  showBooster: boolean;
  errorStatus: boolean;
  refetchCallback: Function;
}

export const PQModal = ({
  toggleModal,
  displayModal,
  pqMonths,
  header,
  performanceYear,
  isLoading,
  showBooster,
  errorStatus,
  refetchCallback,
}: PQModalProps) => {
  const { t } = useTranslation(["annualPGG"]);
  const headers = ["qualified", "rubyPv", "qualifiedLegs", "rubyPvNeeded"];
  const headersBoost = ["qualified", "boosterRequirements", "rubyPv", "qualifiedLegs", "rubyPvNeeded"];

  const colWidth = 100 / (showBooster ? headersBoost.length : headers.length) + "%";
  const rows = createMultiplierDetailsTableRows(pqMonths, showBooster);

  const generateHeaders = () => {
    const headersToUse = showBooster ? headersBoost : headers;
    return headersToUse.map((header) => ({
      value: `${t(`pqModal.${header}`, defaultContent.pqModal[header])}`,
      width: colWidth,
    }));
  };

  const renderLegend = () => {
    return (
      <div className="legend">
        <div className="container first">
          <IconShield type={BADGE_TYPE.EARNED} />
          {`${t("legend.earned", defaultContent.legend["earned"])}`}
        </div>
        <div className="container">
          <IconShield />
          {`${t("legend.potential", defaultContent.legend["potential"])}`}
        </div>
        <div className="container">
          <IconShield type={BADGE_TYPE.NOT_EARNED} />
          {`${t("legend.notEarned", defaultContent.legend["notEarned"])}`}
        </div>
        <div className="container last">
          <IconShield type={BADGE_TYPE.UNAVAILABLE} />
          {`${t("legend.unavailable", defaultContent.legend["unavailable"])}`}
        </div>
      </div>
    );
  };

  const renderModalBody = () => {
    return (
      <div className="CurrentFQModal__body">
        {!isLoading ? (
          <LeafTable
            header={generateHeaders()}
            rows={rows}
            columnAlign="center"
            hideHeader={false}
            rowHeight="3.25rem"
            headerStyle={"default"}
          />
        ) : (
          <>
            <LeafRectangleLoader width="100%" height="4.3125rem" margin="0" isLoading={true} />
            <LeafRectangleLoader width="100%" height="2.75rem" margin=".5rem 0" isLoading={true} numLines={5} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="CurrentPQModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t(`pqModal.${header}`, defaultContent.pqModal[header])}`}
        subtitle={`${t(`pqModal.performanceYear`, defaultContent.pqModal.performanceYear, {
          performanceYear: getPerformanceYearShort(performanceYear),
        })}`}
        description={renderLegend()}
      >
        {errorStatus && !isLoading ? (
          <ErrorTile clickFunction={refetchCallback} errorStatus={errorStatus} isLoading={isLoading} />
        ) : (
          renderModalBody()
        )}
      </LeafModal>
    </div>
  );
};
