import { useTranslation } from "react-i18next";
import { useState } from "react";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import { formatAboPV, formatReqVolume } from "@amway-acc/acc-mixins/l10n";
import defaultContent from "../../../content/foundersPlatinumProgressTile";
import "./FoundersPlatinumProgressTile.scss";
import { storedLocale } from "../../impersonation/util";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import IconCircleI from "../../../common/icons/IconCircleI/IconCircleI";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { useAppSelector } from "../../../store";
import TEALIUM from "../../../common/enums/tealium-enums";
import { TimeStamp } from "../../../common/components/TimeStamp/TimeStamp";

type TileSection = {
  modalKey: ModalType;
  tealiumKey: TealiumType;
};

enum TealiumType {
  SP = "SP",
  GPV = "GPV",
  VE_PV = "VE_PV",
}

enum ModalType {
  SilverProducer = "spMonths",
  GpvEquivalent = "gpvMonths",
  VePeEquivalent = "vepvMonths",
}

export type Props = {
  isLoading?: boolean;
  minSpMonth1?: number;
  minSpMonth2?: number;
  tileData?: {
    spMonthsTile?: {
      currentSpMonths?: number;
      spMonthsNeeded?: number;
      spMonthsTarget?: number;
      qualificationMet?: boolean;
      isAvailable?: boolean;
      hideToolTip?: boolean;
    };
    gpvTile?: {
      currentGpv?: number;
      gpvTarget?: number;
      gpvSpMonths?: number;
      qualificationMet?: boolean;
      isAvailable?: boolean;
      hideToolTip?: boolean;
      showDisclaimer?: boolean;
      currentYtdGpv?: number;
      ytdSpCount?: number;
      spMonthsNeeded?: number;
    };
    vePvTile?: {
      currentVePv?: number;
      vePvTarget?: number;
      vePvSpMonths?: number;
      qualificationMet?: boolean;
      isAvailable?: boolean;
      hideToolTip?: boolean;
      spMonthsNeeded?: number;
      currentSpMonths?: number;
    };
  };
};

function FoundersPlatinumProgressTile({
  isLoading = false,
  minSpMonth1 = 11,
  minSpMonth2 = 12,
  tileData = {
    spMonthsTile: {
      currentSpMonths: 0,
      spMonthsNeeded: 0,
      spMonthsTarget: 0,
      qualificationMet: false,
      isAvailable: true,
      hideToolTip: false,
    },
    gpvTile: {
      currentGpv: 0,
      gpvTarget: 0,
      gpvSpMonths: 0,
      qualificationMet: false,
      isAvailable: true,
      hideToolTip: false,
      showDisclaimer: false,
    },
    vePvTile: {
      currentVePv: 0,
      vePvSpMonths: 0,
      vePvTarget: 0,
      qualificationMet: false,
      isAvailable: true,
      hideToolTip: false,
    },
  },
}: Props) {
  const { t } = useTranslation(["foundersPlatinumProgressTile"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const ssoOrigin = user.isoCountryCode;
  const [tileIsOpen, setTileIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalType.SilverProducer);
  const getModalTitle = (modalType: ModalType) => {
    return t(`tooltips.${modalType}.tooltipTitle`, defaultContent.tooltips[modalType].tooltipTitle);
  };

  const getModalDescription = (modalType: ModalType) => {
    return t(`tooltips.${modalType}.tooltipDesc`, defaultContent.tooltips[modalType].tooltipDesc, {
      spMonthsTarget: tileData.spMonthsTile?.spMonthsTarget,
      minSpMonth1,
      minSpMonth2,
      gpvTarget: formatReqVolume(tileData.gpvTile?.gpvTarget || 0, locale, ssoOrigin),
      vePvTarget: formatReqVolume(tileData.vePvTile?.vePvTarget || 0, locale, ssoOrigin),
    });
  };

  const tealiumClickAction = (tealiumKey: TealiumType) => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "annual",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      `tooltip_${tealiumKey}_method`,
    );
  };

  const modalClickHandler = (sectionType: TileSection) => {
    setModalType(sectionType.modalKey);
    setModalIsOpen(true);
    tealiumClickAction(sectionType.tealiumKey);
  };

  const renderTimestampTile = () => {
    const template = (
      <>
        <div className="FoundersPlatinumProgressTile__details-section-title">
          <p>{t("headerLabel", defaultContent["headerLabel"])}</p>
        </div>

        <div className="FoundersPlatinumProgressTile__data">
          <span className="FoundersPlatinumProgressTile__data-secondary">
            <TimeStamp dateFormat={"long"} />
          </span>
        </div>
      </>
    );
    return renderTileWrapper(template);
  };

  const renderSilverProducerTile = () => {
    const toolTip = (
      <span
        className="FoundersPlatinumProgressTile__icon"
        onClick={() => {
          const sectionObject: TileSection = {
            modalKey: ModalType.SilverProducer,
            tealiumKey: TealiumType.SP,
          };
          modalClickHandler(sectionObject);
        }}
      >
        <IconCircleI />
      </span>
    );

    const renderNotAvailable = () => {
      return (
        <>
          <div className="FoundersPlatinumProgressTile__details-section-title">
            <p>
              {t("spTitle", defaultContent["spTitle"])}
              {tileData.spMonthsTile?.hideToolTip ? <></> : toolTip}
            </p>
          </div>
          <div className="FoundersPlatinumProgressTile__data">
            <span className="FoundersPlatinumProgressTile__data-main">
              <span className="FoundersPlatinumProgressTile__details-not-available">
                {t("notAvailableLabel", defaultContent["notAvailableLabel"])}
              </span>
            </span>
          </div>
        </>
      );
    };

    if (!tileData.spMonthsTile?.isAvailable) {
      return renderTileWrapper(renderNotAvailable(), false);
    }

    const template = (
      <>
        <div className="FoundersPlatinumProgressTile__details-section-title">
          <p>
            {t("spTitle", defaultContent["spTitle"])}
            {tileData.spMonthsTile?.hideToolTip ? <></> : toolTip}
          </p>
        </div>

        <div className="FoundersPlatinumProgressTile__data">
          <span className="FoundersPlatinumProgressTile__data-main">
            <p>
              {t("spMonthsNeededLabel", defaultContent["spMonthsNeededLabel"], {
                spMonthsNeeded: tileData.spMonthsTile?.spMonthsNeeded,
              })}
            </p>
          </span>
          <span className="FoundersPlatinumProgressTile__data-secondary">
            <p>{t("currentlyHaveLabel", defaultContent["currentlyHaveLabel"])}</p>
            <p>
              {t("currentSpMonthsLabel", defaultContent["currentSpMonthsLabel"], {
                currentSpMonths: tileData.spMonthsTile?.currentSpMonths,
              })}
            </p>
          </span>
        </div>
      </>
    );
    return renderTileWrapper(template, tileData.spMonthsTile?.qualificationMet);
  };

  const renderGpvTile = () => {
    const renderDisclaimer = () => {
      if (tileData.gpvTile?.showDisclaimer) {
        return (
          <span className="FoundersPlatinumProgressTile__details-disclaimer">
            {t("disclaimerLabel", defaultContent["disclaimerLabel"])}
          </span>
        );
      }
      return <></>;
    };

    const toolTip = (
      <span
        className="FoundersPlatinumProgressTile__icon"
        onClick={() => {
          const sectionObject: TileSection = {
            modalKey: ModalType.GpvEquivalent,
            tealiumKey: TealiumType.GPV,
          };
          modalClickHandler(sectionObject);
        }}
      >
        <IconCircleI />
      </span>
    );

    const renderNotAvailable = () => {
      return (
        <>
          <div className="FoundersPlatinumProgressTile__details-section-title">
            <p>
              {t("gpvTitle", defaultContent["gpvTitle"])}
              {tileData.gpvTile?.hideToolTip ? <></> : toolTip}
            </p>
          </div>
          {renderDisclaimer()}
          <div className="FoundersPlatinumProgressTile__data">
            <span className="FoundersPlatinumProgressTile__data-main">
              <span className="FoundersPlatinumProgressTile__details-not-available">
                {t("notAvailableLabel", defaultContent["notAvailableLabel"])}
              </span>
            </span>
          </div>
        </>
      );
    };

    if (!tileData.gpvTile?.isAvailable) {
      return renderTileWrapper(renderNotAvailable(), false);
    }

    const template = (
      <>
        <div className="FoundersPlatinumProgressTile__details-section-title">
          <p>
            {t("gpvTitle", defaultContent["gpvTitle"])}
            {tileData.gpvTile?.hideToolTip ? <></> : toolTip}
          </p>
        </div>
        {renderDisclaimer()}

        <div className="FoundersPlatinumProgressTile__data">
          <span className="FoundersPlatinumProgressTile__data-main">
            <p>
              {t("gpvNeededLabel", defaultContent["gpvNeededLabel"], {
                gpvSpMonths: formatAboPV(tileData.gpvTile?.gpvSpMonths || 0, locale, ssoOrigin),
              })}
            </p>
            <p>
              {t("spMonthsNeededLabel", defaultContent["spMonthsNeededLabel"], {
                spMonthsNeeded: tileData.gpvTile?.spMonthsNeeded,
              })}
            </p>
          </span>
          <span className="FoundersPlatinumProgressTile__data-secondary">
            <p>{t("currentlyHaveLabel", defaultContent["currentlyHaveLabel"])}</p>
            <p>
              {t("gpvSpMonthsLabel", defaultContent["gpvSpMonthsLabel"], {
                currentGpv: formatAboPV(tileData.gpvTile?.currentYtdGpv || 0, locale, ssoOrigin),
                currentSpMonths: tileData.gpvTile?.ytdSpCount,
              })}
            </p>
          </span>
        </div>
      </>
    );
    return renderTileWrapper(template, tileData.gpvTile?.qualificationMet);
  };

  const renderVePeTile = () => {
    const toolTip = (
      <span
        className="FoundersPlatinumProgressTile__icon"
        onClick={() => {
          const sectionObject: TileSection = {
            modalKey: ModalType.VePeEquivalent,
            tealiumKey: TealiumType.VE_PV,
          };
          modalClickHandler(sectionObject);
        }}
      >
        <IconCircleI />
      </span>
    );

    const renderNotAvailable = () => {
      return (
        <>
          <div className="FoundersPlatinumProgressTile__details-section-title">
            <p>
              {t("vepvTitle", defaultContent["vepvTitle"])}
              {tileData.vePvTile?.hideToolTip ? <></> : toolTip}
            </p>
          </div>
          <div className="FoundersPlatinumProgressTile__data">
            <span className="FoundersPlatinumProgressTile__data-main">
              <span className="FoundersPlatinumProgressTile__details-not-available">
                {t("notAvailableLabel", defaultContent["notAvailableLabel"])}
              </span>
            </span>
          </div>
        </>
      );
    };

    if (!tileData.vePvTile?.isAvailable) {
      return renderTileWrapper(renderNotAvailable(), false);
    }

    const template = (
      <>
        <div className="FoundersPlatinumProgressTile__details-section-title">
          <p>
            {t("vepvTitle", defaultContent["vepvTitle"])}
            {tileData.vePvTile.hideToolTip ? <></> : toolTip}
          </p>
        </div>

        <div className="FoundersPlatinumProgressTile__data">
          <span className="FoundersPlatinumProgressTile__data-main">
            <p>
              {t("vepvNeededLabel", defaultContent["vepvNeededLabel"], {
                vePvSpMonths: formatAboPV(tileData.vePvTile?.vePvSpMonths || 0, locale, ssoOrigin),
              })}
            </p>
            <p>
              {t("spMonthsNeededLabel", defaultContent["spMonthsNeededLabel"], {
                spMonthsNeeded: tileData.vePvTile.spMonthsNeeded,
              })}
            </p>
          </span>
          <span className="FoundersPlatinumProgressTile__data-secondary">
            <p>{t("currentlyHaveLabel", defaultContent["currentlyHaveLabel"])}</p>
            <p>
              {t("vePvSpMonthsLabel", defaultContent["vePvSpMonthsLabel"], {
                currentVePv: formatAboPV(tileData.vePvTile?.currentVePv || 0, locale, ssoOrigin),
                currentSpMonths: tileData.vePvTile?.currentSpMonths,
              })}
            </p>
          </span>
        </div>
      </>
    );
    return renderTileWrapper(template, tileData.vePvTile.qualificationMet);
  };

  const renderTileWrapper = (tileToRender: JSX.Element, qualificationMet = false) => {
    const renderQualificationStatus = () => {
      if (qualificationMet) {
        return (
          <section
            className={
              "FoundersPlatinumProgressTile__details-section FoundersPlatinumProgressTile__details-section--qualificationMet"
            }
          >
            {tileToRender}
          </section>
        );
      }
      return <section className={"FoundersPlatinumProgressTile__details-section"}>{tileToRender}</section>;
    };

    return renderQualificationStatus();
  };

  const renderDetails = () => {
    if (tileIsOpen)
      return (
        <div className="FoundersPlatinumProgressTile__details">
          {renderTimestampTile()}
          {renderSilverProducerTile()}
          {renderGpvTile()}
          {renderVePeTile()}
          <LeafModal
            onCloseCallbackFn={() => setModalIsOpen(false)}
            open={modalIsOpen}
            title={getModalTitle(modalType)}
            description={getModalDescription(modalType)}
          ></LeafModal>
        </div>
      );
    return <></>;
  };

  if (isLoading) {
    return (
      <div className="FoundersPlatinumProgressTile">
        <div className="FoundersPlatinumProgressTile__button-container FoundersPlatinumProgressTile__button-container--isLoading">
          <LeafRectangleLoader isLoading={true} numLines={1} width="95%" height="5.4rem" />
        </div>
      </div>
    );
  }

  const getTileIsOpenCSS = () => {
    if (tileIsOpen) {
      return "opened";
    }
    return "closed";
  };

  return (
    <div className="FoundersPlatinumProgressTile">
      <div className="FoundersPlatinumProgressTile__button-container">
        <button
          className={`FoundersPlatinumProgressTile__button--${getTileIsOpenCSS()} FoundersPlatinumProgressTile__button`}
          id="FoundersPlatinumProgressTile__button"
          data-testid="FoundersPlatinumProgressTile__button"
          onClick={() => {
            setTileIsOpen(!tileIsOpen);
          }}
        >
          <p
            className={`FoundersPlatinumProgressTile__button-label--${getTileIsOpenCSS()} FoundersPlatinumProgressTile__button-label`}
          >
            {t("foundersPlatinumProgressLabel", defaultContent["foundersPlatinumProgressLabel"])}
          </p>
        </button>
      </div>
      {renderDetails()}
    </div>
  );
}

export default FoundersPlatinumProgressTile;
