import { axe } from "jest-axe";
import { store } from "../store";

export const runActiveAccessibilityTests = async (component: HTMLElement) => {
  if (!component) {
    return;
  }

  const axeOptions = {}; // Customize axe options if needed

  try {
    // Run accessibility tests if the flag is set
    if (store.getState().loginLocalStorage.accessibilityWarningsEnabled) {
      const results = await axe(component, axeOptions);
      const { violations } = results;
      if (violations) {
        console.warn("Accessibility issues found: ", { ...violations });
      } else {
        console.log("No accessibility issues found.");
      }
    }
  } catch (error) {
    console.error(error);
  }
};
