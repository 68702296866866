import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amway-acc/acc-mixins/src/modules/l10n";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/calcDescTile";
import "./CalcDescTile.scss";
import { PathToBronzeData } from "../../../../common/interfaces/pathToBronze";

type Props = {
  isLoading: boolean;
  data: PathToBronzeData;
  locale: string;
  volumeNeeded: string;
  volumeType: string;
};

export default function CalcDescTile({ isLoading, data, locale, volumeNeeded, volumeType }: Props) {
  const { t } = useTranslation(["calcDescTile"]);
  const { bonusCurrency, legRequirements, country } = data;

  const formattedBonusAmounts = legRequirements.map((leg) =>
    formatCurrency(leg.bonusAmount, bonusCurrency, locale, country, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  );

  const descriptionContentKey =
    formattedBonusAmounts.length === 3 ? "calcDescriptionThreeLegs" : "calcDescriptionTwoLegs";

  return (
    <div className="desc-tile-container">
      <div>
        <LeafTile isLoading={isLoading} circleCheckmarkId={""}>
          <section>
            {t(descriptionContentKey, defaultContent[descriptionContentKey], {
              firstBonusAmount: formattedBonusAmounts[0],
              secondBonusAmount: formattedBonusAmounts[1],
              thirdBonusAmount: formattedBonusAmounts[2] || null,
              volumeNeeded,
              volumeType,
            })}
          </section>
        </LeafTile>
      </div>
    </div>
  );
}
