import { useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../common/enums/tealium-enums";
import defaultContent from "../../../../content/annualPGG";
import { MultiplierData } from "../../../../common/interfaces/groupGrowthIncentives";
import { getPerformanceYearShort } from "../../../../common/util/period";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import { PGGPerformanceBonus } from "../PerformanceBonus/PGGPerformanceBonus";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import { FetchPggPerformanceBonusData } from "../AnnualPGGDataLayer";

type Props = {
  multiplierData: MultiplierData[];
  isLoading: boolean;
  selectedPeriod: string;
};

export default function PGGIncentiveCalculation({ isLoading, multiplierData, selectedPeriod }: Props) {
  const { t } = useTranslation(["annualPGG"]);
  const [openQMonthModal, setOpenQMonthModal] = useState<boolean>(false);
  const PY = getPerformanceYearShort(selectedPeriod);

  const {
    isLoading: isFetching,
    pggPerformanceBonusData,
    errorStatus,
    refetch: refetchPggPerformanceBonusData,
  } = FetchPggPerformanceBonusData(selectedPeriod);

  const showQMonthModal = () => {
    setOpenQMonthModal(!openQMonthModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `performance_bonus_qualified_month_modal_${!openQMonthModal ? "open" : "close"}`,
      "performance_bonus_qualified_month_modal",
    );
  };

  return (
    <>
      <IncentiveCalculation
        isLoading={isLoading}
        base={{
          title: t(`incentiveCalc.incentive.base`, String(defaultContent.incentiveCalc.incentive.base)),
          amount: multiplierData[0].Base,
          icon: <IconPlainTable />,
          callbackFn: showQMonthModal,
        }}
        multiplier={{
          title: t(`incentiveCalc.incentive.multiplier`, String(defaultContent.incentiveCalc.incentive.multiplier)),
          amount: multiplierData[0].Multiplier,
          symbolIcon: <IconMultiply />,
        }}
        total={{
          title: t(`incentiveCalc.incentive.total`, String(defaultContent.incentiveCalc.incentive.total), {
            performanceYear: PY,
          }),
          amount: multiplierData[0].Total,
          symbolIcon: <IconEquals />,
        }}
      />
      <PGGPerformanceBonus
        modalIsOpen={openQMonthModal}
        toggleModal={showQMonthModal}
        history={pggPerformanceBonusData.History}
        isLoading={isFetching}
        errorStatus={errorStatus}
        refetch={refetchPggPerformanceBonusData}
      />
    </>
  );
}
