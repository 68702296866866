import "./LeafFilterButton.scss";

type Props = {
  text: string;
  theme?: string;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
};

const LeafFilterButton = (props: Props) => {
  const { text, onButtonClick, theme } = props;

  return (
    <button className={`leaf-filter-button${theme ? " " + theme : ""}`} onClick={onButtonClick}>
      {text}
    </button>
  );
};

export default LeafFilterButton;
