import { periodToDate } from "@amway-acc/acc-mixins/dates";
import { storedLocale } from "../../../impersonation/util";

export const getBronzeDisplayDate = (period: number | string) => {
  try {
    const locale = storedLocale();
    const date = periodToDate(`${period}`);
    const formatter = new Intl.DateTimeFormat(locale, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      year: "numeric",
      month: "long",
    });
    return formatter.format(date);
  } catch {
    return "";
  }
};
