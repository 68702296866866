import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-async";
import defaultContent from "../../content/earningsCalculator";
import { AttributeTransform, mapAttributes } from "../../common/util/config";
import { useAppSelector } from "../../store";

const loadComponent = async () => {
  return import("../Lit/acc-coreplus-income-calculator/acc-income-calculator.js");
};

export default function EarningsCalculator() {
  const { t } = useTranslation(["earningsCalculator"]);
  const { configuration } = useAppSelector((state) => state.boot);
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    if (!isPending) {
      const component = document.getElementById("acc_income_calculator_component") as HTMLElement;
      mapAttributes(component, configuration.calculator, { transform: AttributeTransform.kebab });
    }
  }, [isPending, configuration]);

  if (isPending) {
    return <></>;
  }

  return (
    <main>
      <h1 id="income_calculator_header">{t("pageTitle", defaultContent["pageTitle"])}</h1>
      <acc-income-calculator id="acc_income_calculator_component" hideHeading></acc-income-calculator>
    </main>
  );
}
