/**
 * Array of all content namespaces. The naming convention of namespaces is identical to the contentFile.ts in src/content
 * This list is used in App.tsx to load pre-fetch content files on app load to prevent content flicker
 */
export const namespaces = [
  "footer",
  "actionReports",
  "annualPGG",
  "annualPerformanceDashboard",
  "bronzeBuilder",
  "bronzeFoundation",
  "businessSelector",
  "cml",
  "customerOverview",
  "csi",
  "earningsCalculator",
  "edlos",
  "frontlineGrowth",
  "hamburgerMenu",
  "learnAboutCPP",
  "message",
  "monthlyPGG",
  "monthlyPerformanceDashboard",
  "navigation",
  "performanceBonusDetail",
  "ppe",
  "provideUsFeedback",
  "rwlos",
  "smartNextSteps",
  "ttci",
  "watchlist",
  "GCRPGG",
  "comingSoonModal",
  "faa",
  "hat",
  "regulationInfoBanner",
  "foundersPlatinumProgressTile",
  "awardProgressBadge",
  "leafAboName",
  "newAboActivation",
  "volumeRequirementTile",
  "vcsRequirementTile",
  "fggMultiplierDetails",
];
