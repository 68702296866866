import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/annualGcrPGG";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store";
import { TimeStamp } from "../../../common/components/TimeStamp/TimeStamp";
import { BonusInfoFooter } from "../../../common/components/BonusInfoFooter/BonusInfoFooter";
import "./GCRPGG.scss";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import GCRPGGIncentiveCalculation from "./IncentiveCalculation/GCRPGGIncentiveCalculation/GCRPGGIncentiveCalculation";
import { FetchGcrPggOverviewData } from "./GCRPGGDataLayer";
import { IncomeBannerTile } from "./BannerTile/IncomeBannerTile";
import { YtdTotals } from "./YtdTotals/YtdTotals";
import { GCRPGGMultiplierDetails } from "./MultiplierDetails/GCRPGGMultiplierDetails";

export default function GCRPGG() {
  const { t } = useTranslation(["annualGcrPGG"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();
  const [period, setPeriod] = useState(selectedPeriod);
  const prevOpenRef = useRef(false);
  const { isComingSoon } = useAppSelector((state) => state.boot.shellConfig);

  // checking if the selectedPeriod changes as it triggering the component.
  // To avoid multiple calls to the component and multiple rendering, added a useffect to check for change in selectedPeriod
  useEffect(() => {
    setPeriod(selectedPeriod);
  }, [selectedPeriod]);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  const { data, isLoading, errorStatus, refetch } = FetchGcrPggOverviewData(selectedPeriod);

  return (
    <main className="GcrPgg">
      <div className="GcrPgg__container">
        <div className="GcrPgg__heading">
          <div className="GcrPgg__heading--title">
            <h1 className="acc_fgg_header" id="acc_gcrpgg_header">
              {t(
                "pageTitle.personalGroupGrowthIncentive",
                defaultContent["pageTitle"]["personalGroupGrowthIncentive"],
              ).toString()}
            </h1>
          </div>
          <div className="GcrPgg__heading--timestamp">
            <TimeStamp dateFormat={"long"} />
          </div>
        </div>

        {errorStatus ? (
          <div className="GcrPgg__errorTile">
            <ErrorTile errorStatus={errorStatus} isLoading={isLoading} clickFunction={refetch} />{" "}
          </div>
        ) : (
          <>
            <div className="GcrPgg__container-split">
              <div className="GcrPgg__section-padding Pgg__container-row">
                <section className="GcrPgg__banner">
                  <IncomeBannerTile
                    incomeData={data.IncomeData}
                    isLoading={isLoading}
                    isNewPerformanceYear={isComingSoon}
                  />
                </section>
                {data.DisplayRequirementTiles && !isComingSoon && (
                  <section className="GcrPgg__yearToDate">
                    <YtdTotals
                      monthTotals={data.MonthTotals}
                      gcrRequirements={data.Requirements}
                      incentiveData={data.IncentiveData}
                      selectedPeriod={period}
                      isLoading={isLoading}
                    />
                  </section>
                )}
              </div>
              {data.DisplayRequirementTiles && !isComingSoon && (
                <div className="GcrPgg__section-padding GcrPgg__calculation">
                  <div>
                    <h2>
                      {t(
                        "incentiveCalculation.sectionHeader",
                        defaultContent.incentiveCalculation.sectionHeader,
                      ).toString()}
                    </h2>
                  </div>
                  <div className="GcrPgg__multiplication">
                    <GCRPGGIncentiveCalculation
                      isLoading={isLoading}
                      selectedPeriod={selectedPeriod}
                      prevOpenRef={prevOpenRef}
                      overview={data}
                    />
                  </div>
                  <GCRPGGMultiplierDetails
                    data={data.IncentiveData}
                    selectedPeriod={selectedPeriod}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
            <section className="GcrPgg__footer">
              <BonusInfoFooter />
            </section>
          </>
        )}
      </div>
    </main>
  );
}
