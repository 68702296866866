import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";

export const earningAPIResponse = {
  status: QUALIFICATION_STATUS.HELD,
};

export const requirementAPIResponse = {
  requiredCount: 3,
  metCount: 2,
  manualApproval: false,
};
