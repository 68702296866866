import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";

export const bgtInfoModalPageLoadActionTrack = ({ period }: { period: string }) => {
  track(
    TEALIUM.U_TAG_METHOD.VIEW,
    TEALIUM.EVENT_NAME.CLICK_CATEGORY,
    "info_drawer",
    TEALIUM.CATEGORY.BRONZE_TRACKING,
    `${TEALIUM.CATEGORY.BRONZE_TRACKING}_${period}`,
    "",
  );
};
