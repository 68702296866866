import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updatePeriod } from "../../../reducers/period";
import PGGLit from "./AnnualPGGLit/AnnualPGGLit";
import PGG from "./AnnualPGG";

export default function AnnualPGGSwitcher() {
  const { pgg, latestFiscalYearAvailable } = useAppSelector((state) => state.boot.configuration);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const queryParams = new URLSearchParams(location.search);
  const showOldPggFromQueryParam = queryParams.get("showOldPgg") === "true";

  const { isComingSoon } = useAppSelector((state) => state.boot.shellConfig);

  if (showOldPggFromQueryParam || pgg.showOldPgg) {
    if (isComingSoon) {
      dispatch(updatePeriod({ period: `${latestFiscalYearAvailable}08` }));
    }
    return <PGGLit />;
  }

  return <PGG />;
}
