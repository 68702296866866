import { Markets } from "@amway-acc/acc-es-data/model";
import { init } from "@amway-acc/acc-boot";
import { RootState, store } from "../../../store";
import { fetchConfig, storedSsoOrigin } from "../../../common/util/config";
import { BootType, setBoot, BootUser } from "../../boot";
import { updateContentPathForMarket } from "../../../i18n.config";
import { NavDropdown } from "../../../common/interfaces/routing";
import { ShellConfigApiResponse } from "../shellConfig";
import { LoginFields, setloginLocalStorage } from "../../loginLocalStorage";
export interface Item {
  label: string;
  path: string;
  class: string;
  code?: number;
  navPosition: number;
  reloadDocument?: boolean;
}

export interface MenuConfig {
  key: string;
  label: string;
  items: Item[];
}

export interface ShellConfig extends ShellConfigApiResponse {
  showLogoutLink?: boolean;
  menuConfig: NavDropdown[];
}

export const getAuthHeader = ({ boot }: Pick<RootState, "boot">) => {
  const { user } = boot;
  const { token_type = "", access_token } = user;
  const tokenType = `${token_type.charAt(0).toUpperCase()}${token_type.slice(1)}`;

  return {
    authorization: `${tokenType} ${access_token}`,
  };
};

export const getLoginAffAbo = ({ boot }: Pick<RootState, "boot">) => {
  const { user } = boot;
  const { aff, abo } = user;
  return `${aff}-${abo}`;
};

export const initAccBoot = async (bootSettings: BootType) => {
  const loc = store.getState().loginLocalStorage.loginFields.locale;
  const accBootSettings = {
    oauth: {
      parse: true,
    },
    setup: {
      globalStore: true,
      globalName: "accBoot",
    },
    api: {
      config: bootSettings.configuration,
    },
    locale: {
      languageCode: loc || bootSettings.configuration.defaultLocale,
      market: bootSettings.user.isoCountryCode,
      isoCurrencyCode: bootSettings.configuration.defaultCurrencyCode,
      path: `/content/${bootSettings.user.isoCountryCode}/text/`,
    },
    user: bootSettings.user,
    businessInView: bootSettings.user,
    tokens: bootSettings.tokens,
    externalLinks: bootSettings.configuration.dashboardLinks,
    userManual: bootSettings.configuration.userManual,
  };
  return init(accBootSettings);
};

export const SetBootForCurrentBusiness = async ({ user }: { user: Partial<BootUser> }) => {
  const storedSsoOrigin = store.getState().loginLocalStorage.loginFields.ssoOrigin;

  // we need to check if the initial market logged in is using the new NextGen configs
  // and the selected business we are switching to is from that same market.
  // If so, use the `storedSsoOrigin` as this will have the `NG` suffix i.e: `TH_NG`
  const isNextGen =
    storedSsoOrigin &&
    storedSsoOrigin.length > 2 &&
    storedSsoOrigin.includes("_NG") &&
    storedSsoOrigin.substring(0, 2) === user.isoCountryCode;

  const ssoOrigin = isNextGen ? storedSsoOrigin : user.isoCountryCode;

  const config = await fetchConfig(`/configs/${ssoOrigin}.configuration.json`);

  // eslint-disable-next-line prefer-const
  let { aff, isoCountryCode } = user;

  if (!aff && isoCountryCode) {
    aff = new Markets().getByIso(isoCountryCode).aff;
  }

  if (store.getState().boot.user.isoCountryCode !== user.isoCountryCode) {
    updateContentPathForMarket(user.isoCountryCode);
  }

  await store.dispatch(
    setBoot({
      configuration: config,
      user: { ...user, aff },
    }),
  );

  return await store.getState().boot;
};

export const SetLoginFields = async ({ fields }: { fields: Partial<LoginFields> }) => {
  await store.dispatch(
    setloginLocalStorage({
      loginFields: { ...fields },
    }),
  );

  return await store.getState().loginLocalStorage;
};
