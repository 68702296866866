export enum REQUIREMENT_NAME {
  PERSONAL = "personal",
  BASIS_PLATINUM = "basisCheckForPlatinumWithEq",
  BASIS_FOUNDERS_PLATINUM = "basisCheckForFoundersPlatinumWithEq",
  BASIS_FOUNDERS_PLATINUM_ANA = "basisCheckForFoundersPlatinumWithEqAna",
  PERCENTAGE_FGI = "ytdVcsPercentageCheckForfrontlineGrowthIncentive",
  PERCENTAGE_PGG = "ytdVcsPercentageCheckForPersonalGroupGrowth",
  RUBY = "ruby",
  CHECK_FOR_SILVER = "growthOrMaintainCheckForsilver",
  CHECK_FOR_PLATINUM = "growthOrMaintainCheckForPlatinum",
  LEADERSHIP_AND_FOSTER = "leadershipAndFoster",
  ANNUAL_PGG_TRACKING = "annualPggTracking",
  ANNUAL_PGGI_TRACKING = "annualPggiTracking",
  VCS_TO_PPV = "ratioOfVcsToPpvPersonalOts",
}

export enum REQUIREMENT_TYPE {
  AWARD = "Award",
  VOLUME = "Volume",
  PERCENT = "PERCENT",
}

export enum MULTIPLIER_DATA_TYPE {
  LEG = "Leg",
  INCENTIVE = "Incentive",
}
