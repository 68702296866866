import { formatAboBV } from "@amway-acc/acc-mixins/l10n";
import { storedLocale, marketCode } from "../../../../components/impersonation/util";

type Props = {
  bv: number;
};

export const LeafBV = (props: Props) => {
  const { bv } = props;
  const locale = storedLocale();
  const market = marketCode();

  return <>{formatAboBV(bv, locale, market)}</>;
};
