import { ReactNode } from "react";
import PathToBronze from "../../PathToBronze/PathToBronze";
import BronzeFoundation from "../../BronzeFoundation/BronzeFoundation";
import BronzeBuilder from "../../BronzeBuilder/BronzeBuilder";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActiveBulkCheck } from "../../../../common/util/checkProgramActiveStatus";

export type BronzeView = {
  id: string;
  BronzeView: ReactNode;
  selected: boolean;
  group: number[];
};

const BronzeIncentivesViews = (): BronzeView[] => {
  const eligibleIncentives = useAppSelector((store) => store?.boot?.shellConfig?.eligibleIncentives);

  const {
    BRONZE_FOUNDATION,
    RESTART_BRONZE_FOUNDATION,
    RESTART_TWO_BRONZE_FOUNDATION,
    BRONZE_BUILDER,
    RESTART_BRONZE_BUILDER,
    RESTART_TWO_BRONZE_BUILDER,
    PATH_TO_BRONZE,
  } = AWARD_CODES;

  const bronzeView = [
    {
      id: "pathtobronze",
      BronzeView: <PathToBronze showHeaderFooter={false} />,
      selected: false,
      group: [PATH_TO_BRONZE],
    },
    {
      id: "bronzefoundation",
      BronzeView: <BronzeFoundation />,
      selected: false,
      group: [BRONZE_FOUNDATION, RESTART_BRONZE_FOUNDATION, RESTART_TWO_BRONZE_FOUNDATION],
    },
    {
      id: "bronzebuilder",
      BronzeView: <BronzeBuilder />,
      selected: false,
      group: [BRONZE_BUILDER, RESTART_BRONZE_BUILDER, RESTART_TWO_BRONZE_BUILDER],
    },
  ]
    .filter(({ group }) => isProgramActiveBulkCheck(eligibleIncentives, group))
    .map((item, index) => ({
      ...item,
      selected: index === 0,
    }));

  return bronzeView as BronzeView[];
};

export default BronzeIncentivesViews;
