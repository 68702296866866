import "./DesktopHeader.scss";
import { Link, useLocation } from "react-router-dom";
import { track } from "@amway-acc/acc-mixins/src/modules/tealium/index";
import { useTranslation } from "react-i18next";
import defaultContent from "../../content/common";
import { SmartNextSteps } from "../SmartNextSteps/SmartNextSteps";
import { NavDropdown } from "../../common/interfaces/routing";
import { PeriodSelector } from "../PeriodSelector/PeriodSelector";
import { IconAmwaySmallBlack } from "../../common/icons/IconAmwaySmallBlack/IconAmwaySmallBlack";
import TEALIUM from "../../common/enums/tealium-enums";
import { TealiumClickActionMapper } from "../../common/util/tealiumClickActionMapper";
import { getNavGroupFromPath } from "../../routing/Routing";
import { BusinessSelector } from "../BusinessSelector/BusinessSelector";
import { useAppSelector } from "../../store";
import { getCountryFlag } from "../../common/util/getCountryFlag";
import { getDefaultHomePage } from "../../common/util/navigationUtil";
import MenuItems, { ActiveMenuItems } from "./MenuItems/MenuItems";

type Props = {
  displayPeriodSelector?: boolean;
};

export const DesktopHeader = ({ displayPeriodSelector }: Props) => {
  const { user, configuration, shellConfig, isBootLoading } = useAppSelector((state) => state.boot);
  const { t } = useTranslation(["common"]);
  const aboName = useAppSelector(({ aboName }) => aboName.name);
  const { headless } = useAppSelector((state) => state.loginLocalStorage);
  const { abo: aboNum, isoCountryCode } = user;
  const { showBusinessSelector = false } = configuration?.navigation || {};
  const { menuConfig = [] } = shellConfig;

  const location = useLocation();

  const updateActiveMenuItems = (): ActiveMenuItems => {
    const activeNavGroup = getNavGroupFromPath(location.pathname, menuConfig);
    return { activeMenu: activeNavGroup.key, activeDropdownItem: location.pathname };
  };

  const handleTealiumTrackClickActionAmwayIcon = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TealiumClickActionMapper[location.pathname],
      "",
      "amway-icon",
    );
  };

  const renderBusinessSelector = () => {
    return showBusinessSelector ? (
      <BusinessSelector viewState="desktop" />
    ) : (
      <span id="header__abo-info" className="header__abo-info">
        <span>{getCountryFlag(isoCountryCode)}</span>
        <p className="header__country-code">{isoCountryCode}</p>
        <p className="header__abo-number">#{aboNum}</p>
        <p>{aboName}</p>
      </span>
    );
  };

  return (
    <header id="header" className="header">
      <div id="header__heading" className="header__heading">
        {headless === false && renderBusinessSelector()}
      </div>
      <nav className={`header__nav ${headless === true ? "header__nav--headless" : ""}`}>
        {headless === false && (
          <>
            <div className="header__logo__section">
              <Link
                id="header__amway-icon"
                className="header__amway-icon"
                to={getDefaultHomePage(configuration.cppModHomePage)}
                onClick={handleTealiumTrackClickActionAmwayIcon}
              >
                <IconAmwaySmallBlack title={`${t("newSiteTitle", defaultContent["newSiteTitle"])}`} />
              </Link>
            </div>

            <div className="header__navList">
              {isBootLoading ? (
                <div className="header__loaderWrapper" style={{ marginTop: "0.4375rem" }}>
                  <div className="loading-pulse header__loader" style={{ width: "80%" }}></div>
                </div>
              ) : (
                <ul className="header__navList-ul">
                  {menuConfig.map((menu: NavDropdown, index: number) => {
                    return <MenuItems menu={menu} key={index} activeItems={updateActiveMenuItems()} />;
                  })}
                </ul>
              )}
            </div>
            <div className="header__smart-steps" id="header__smart-steps">
              {isBootLoading ? (
                <div className="header__loaderWrapper">
                  <div className="loading-pulse header__loader" style={{ width: "70%" }}></div>
                </div>
              ) : menuConfig.length ? (
                <SmartNextSteps restrictSize menuConfig={menuConfig} />
              ) : null}
            </div>
          </>
        )}

        {displayPeriodSelector && (
          <div
            id="header__period-selector"
            className={`header__period-selector${headless === true ? "--headless" : ""}`}
          >
            <PeriodSelector />
          </div>
        )}
      </nav>
    </header>
  );
};
