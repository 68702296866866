const content: { [key: string]: string } = {
  authenticationFailedLinkText: "Click here",
  authenticationFailedText: "Please contact Customer Service",
  authenticationFailedTitle: "Authentication Failed",
  btnBackToAmwayCom: "Back to Amway.com",
  btnBackToHome: "Back to Home",
  cmlLoadFailedText: "Please contact Amway Customer Service",
  cmlLoadFailedTitle: "Something went wrong",
  componentErrorText: "Please try again later",
  componentErrorTitle: "We failed to load this data",
  edLosLoadFailedText: "Please try again later",
  edLosLoadFailedTitle: "We were unable to load the LOS for this ABO",
  genericErrorText: "Please try again later",
  genericErrorTitle: "This application is temporarily unavailable",
  incentiveUnavailableForPeriodText: "Please select another date or return to the dashboard",
  incentiveUnavailableForPeriodTitle:
    "You have selected a date range that has no data or is not currently active in your market",
  incorrectAccountText:
    "Please log into your Business Center using the country-specific credentials for the business you want to view.",
  incorrectAccountTitle: "Logged in With Incorrect Account",
  invalidLocaleText: "Please try again using a supported locale code",
  invalidLocaleTitle: "The locale code requested is not supported",
  pageUnavailableText:
    "If you think you have arrived here by mistake, please contact Amway Customer Service Error Code: 404",
  pageUnavailableTitle: "Sorry, we couldn't find this page",
  systemMaintenanceText: "We will be back up and running soon",
  systemMaintenanceTitle: "The site is down for regular maintenance",
  unsupportedBrowserText:
    "CorePlus has been optimized for the following browsers:<br/><strong>Chrome, Safari, Edge, FireFox.</strong><br/></br/>To update your browser, click on one of the icons below.",
  unsupportedBrowserTitle: "We're Sorry, your browser is not supported.",
  userRestrictionText: "We are sorry. This site is not available for Amway China businesses.",
  userSessionExpiredLinkText: "Click here",
  userSessionExpiredText:
    "Please open your Amway Business Center site and click the Core Plus link to view your Core Plus incentive status.",
  userSessionExpiredTitle: "User session expired",
  dashboard: "Dashboard",
};

export default content;
